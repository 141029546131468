<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar :is-transparent="false" :is-hidden="false" :cny-navigation="true" />

    <secondary-navigation :menu-items-data="menuItems" :offset-y="70" />

    <!-- Header -->
    <section>
      <div class="tw-relative">
        <picture>
          <source media="(max-width: 375px)" :srcset="$cdn('images/cny2024/banner_375.png')" />
          <source media="(max-width: 680px)" :srcset="$cdn('images/cny2024/banner_680.png')" />
          <source media="(max-width: 800px)" :srcset="$cdn('images/cny2024/banner_800.png')" />
          <source media="(max-width: 1025px)" :srcset="$cdn('images/cny2024/banner_1025.png')" />
          <img :src="$cdn('images/cny2024/banner_1440.png')" class="tw-w-full tw-object-cover" />
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny tw-pb-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_about') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-mt-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_about_subtitle') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Content -->
    <div class="tw-flex tw-flex-col tw-w-full">
      <div
        class="tw-px-4 tw-py-5 sgTablet:tw-px-10 sgTablet:tw-py-10 sgDesktop:tw-pt-10 sgDesktop:tw-pb-[20px] tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
        :class="{
          'tw-break-keep': checkLang() == 'en',
        }"
        v-html="wordpressData.description"
      />
      <!-- Empty Space -->
      <div class="tw-h-[100px] tw-bg-white" />
      <!-- Yusheng -->
      <div id="yu-sheng" class="tw-bg-[#FFE39E] tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
        <img class="yusheng tw-w-[200px]" :src="$cdn('images/cny2024/yusheng.png')" />
        <p
          class="title tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_yusheng') }}
        </p>
        <!-- Image and Text Row -->
        <div
          class="tw-flex tw-flex-col tw-px-4 tw-py-6 sgTablet:tw-flex-row sgDesktop:tw-flex-row sgDesktop:tw-min-w-[600px] sgDesktop:tw-max-w-[600px] tw-items-end"
        >
          <img
            class="tw-pb-6 sgTablet:tw-pb-0 sgDesktop:tw-pb-0 sgTablet:tw-pr-6 sgDesktop:tw-pr-6 tw-max-w-[343px] tw-w-full sgTablet:tw-max-h-[168px] sgTablet:tw-max-w-[288px] tw-object-contain"
            :src="$cdn('images/cny2024/yusheng-group-people.png')"
          />
          <p class="text sgTablet:tw-max-w-[288px] sgDesktop:tw-max-w-[288px] tw-flex tw-items-center">
            {{ $t('cny_yusheng_desc') }}
          </p>
        </div>
        <!-- Text -->
        <div class="tw-px-4 sgTablet:tw-px-4 sgDesktop:tw-px-0 sgDesktop:tw-max-w-[600px]" v-html="formattedYushengLongText" />
        <div class="tw-pb-6" />
        <!-- Four Heavely Kings -->
        <div class="tw-flex tw-flex-row tw-px-4 sgTablet:tw-flex-row sgDesktop:tw-flex-row sgDesktop:tw-min-w-[600px]">
          <div class="tw-flex tw-flex-col">
            <p
              class="text tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-self-stretch"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_four_heavenly_king') }}
            </p>
            <div class="tw-pb-6" />
            <div v-html="formattedHeavenlyKingsLongText" />
            <p class="tw-text-[#9E1E00] tw-pt-5 tw-min-w-[219px] tw-max-w-[476px]">
              {{ $t('cny_learn_about_yusheng') }}
              <a class="tw-underline tw-cursor-pointer" :href="cny_101_guide_link" target="_blank">{{ $t('cny_101_guide') }}</a>
            </p>
            <div class="tw-pb-6" />
            <p class="text tw-italic tw-pb-1 tw-text-sg-caption-italic">
              {{ $t('image_source') }}
            </p>
            <div class="text tw-text-sg-caption-mobile sgTablet:tw-text-sg-caption-tablet sgDesktop:tw-text-sg-caption" v-html="$t('cny_now_boarding')" />
          </div>
          <!-- Padding right -->
          <div class="tw-pr-6" />
          <!-- chefs image -->
          <div class="tw-flex tw-flex-col tw-justify-center tw-align-middle tw-w-[100px]">
            <img
              v-for="index in 4"
              :key="index"
              :class="[index !== 4 ? 'tw-pb-10 sgTablet:tw-pb-6 sgDesktop:tw-pb-6' : '', 'tw-min-w-[100px] tw-min-h-[100px]']"
              :src="$cdn(`/images/cny2024/chef/chef${index}.png`)"
            />
          </div>
        </div>
        <div class="tw-pb-[100px]" />
      </div>
      <!-- Mandarin Oranges -->
      <div id="mandarin-oranges" class="tw-bg-[#339670] tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
        <img class="yusheng tw-w-[200px] tw-h-[200px]" :src="$cdn('images/cny2024/mandarin-orange.png')" />
        <p
          class="tw-pt-5 tw-pb-6 tw-text-white tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_mandarin_oranges') }}
        </p>
        <div class="tw-flex tw-flex-col tw-px-4 sgDesktop:tw-min-w-[600px]">
          <div class="tw-text-white tw-min-w-[343px] tw-max-w-[600px]" v-html="$t('cny_mandarin_oranges_desc')" />
          <div class="tw-pb-6" />
          <div class="tw-text-white tw-min-w-[343px] tw-max-w-[600px] tw-pb-[100px]" v-html="$t('cny_mandarin_oranges_desc2')" />
        </div>
      </div>
      <!-- Pineapple Tarts -->
      <div id="pineapple-tarts" class="tw-bg-white tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
        <img class="yusheng tw-w-[200px]" :src="$cdn('images/cny2024/pineapple-tart.png')" />
        <p
          class="tw-pt-5 tw-pb-6 tw-text-[#9E1E00] tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_pineapple_tarts') }}
        </p>
        <div class="tw-flex tw-flex-col tw-px-4 sgDesktop:tw-min-w-[600px] tw-pb-[100px]">
          <div
            class="tw-text-[#9E1E00] tw-min-w-[343px] tw-max-w-[600px]"
            :class="{
              'tw-break-keep': checkLang() == 'en',
            }"
            v-html="$t('cny_pineapple_tarts_desc')"
          />
        </div>
      </div>
      <!-- Bak Kwa -->
      <div id="bak-kwa" class="tw-bg-[#FF9F33] tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center">
        <img class="yusheng tw-w-[200px] tw-h-[200px]" :src="$cdn('images/cny2024/bakkwa.png')" />
        <p
          class="tw-pt-5 tw-pb-6 tw-text-sg-h2-mobile-cny sgTablet:tw-text-sg-h2-tablet-cny sgDesktop:tw-text-sg-h2-cny"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
        >
          {{ $t('cny_bak_kwa') }}
        </p>
        <div class="tw-flex tw-flex-col tw-px-4 sgDesktop:tw-min-w-[600px]">
          <div v-html="formattedBakkwaParagraph" />
          <div class="tw-pb-6" />
          <p class="tw-min-w-[343px] tw-max-w-[600px] tw-pb-[100px]">
            {{ $t('cny_bak_kwa_desc') }}
            <a class="tw-underline tw-cursor-pointer" :href="cny_bakwa_link" target="_blank">
              {{ $t('cny_bak_kwa_desc2') }}
            </a>
            {{ $t('cny_bak_kwa_desc3') }}
          </p>
        </div>
      </div>
      <!-- Source -->
      <ap-wysiwyg-viewer
        :data="wordpressData.sources"
        class="tw-py-[20px] tw-px-4 sgTablet:tw-px-10 sgTablet:tw-py-[40px] tw-grid-cols-1 sgDesktop:tw-px-20 sgDesktop:tw-py-[60px]"
      />
    </div>
    <ap-footer />
  </div>
</template>

<script lang="ts">
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import { checkLang } from '../../support/helper';
export default {
  props: {
    wordpressData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: 'global' });

    onMounted(() => {
      console.log('check wordpressData', props.wordpressData);
    });
    const aboutDescription = computed(() => t('cny_about_desc'));
    const yushengLongText = computed(() => t('cny_yusheng_long_text'));
    const fourHeavenlyKingsLongText = computed(() => t('cny_four_heavenly_king_long_text'));
    const bakkwaParagraph = computed(() => t('cny_bakwa_long_text'));
    const cny_101_guide_link = computed(() =>
      checkLang() !== 'zh-hans'
        ? `${process.env.WP_SITEURL}/festivals/chinese-new-year/yusheng-101/`
        : `${process.env.WP_SITEURL}/festivals/chinese-new-year/yusheng-101/?lang=zh-hans`
    );
    const cny_bakwa_link = computed(() =>
      checkLang() !== 'zh-hans'
        ? `${process.env.WP_SITEURL}/festivals/chinese-new-year/snack-guide/`
        : `${process.env.WP_SITEURL}/festivals/chinese-new-year/snack-guide/?lang=zh-hans`
    );

    const menuItems = [
      {
        label: 'cny_yusheng',
        id: 'yu-sheng',
      },
      {
        label: 'cny_mandarin_oranges',
        id: 'mandarin-oranges',
      },
      {
        label: 'cny_pineapple_tarts',
        id: 'pineapple-tarts',
      },
      {
        label: 'cny_bak_kwa',
        id: 'bak-kwa',
      },
    ];

    return {
      // handleMandarinOrangeTextClick,
      checkLang,

      aboutDescription,
      yushengLongText,
      fourHeavenlyKingsLongText,
      bakkwaParagraph,
      menuItems,
      cny_101_guide_link,
      cny_bakwa_link,
    };
  },
  data() {
    return {};
  },
  computed: {
    formattedText(): string {
      const paragraphs = this.aboutDescription.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-pt-6">${paragraph}</p>`;
          }
        })
        .join('');
    },
    formattedYushengLongText(): string {
      const paragraphs = this.yushengLongText.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-[#9E1E00] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-[#9E1E00] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-pt-6">${paragraph}</p>`;
          }
        })
        .join('');
    },
    formattedHeavenlyKingsLongText(): string {
      const paragraphs = this.fourHeavenlyKingsLongText.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-[#9E1E00] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-min-w-[219px] tw-max-w-[476px]">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-[#9E1E00] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-pt-6 tw-min-w-[219px] tw-max-w-[476px]">${paragraph}</p>`;
          }
        })
        .join('');
    },
    formattedBakkwaParagraph(): string {
      const paragraphs = this.bakkwaParagraph.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-min-w-[343px] tw-max-w-[600px]">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-pt-6 tw-min-w-[343px] tw-max-w-[600px]">${paragraph}</p>`;
          }
        })
        .join('');
    },
  },
};
</script>

<style scoped>
.header-image {
  width: 100%;
  object-fit: cover;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.yusheng {
  position: relative;
  display: flex;
  height: 200px;
  margin-top: -100px;
}

p.title,
p.text {
  color: #9e1e00;
}
</style>
