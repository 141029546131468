<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />
    <!-- Header -->
    <div class="slideshow-container tw-w-full tw-overflow-hidden">
      <div
        v-for="(imageUrl, index) in imageUrls"
        :key="index"
      >
        <img
          class="the-slide fade tw-absolute tw-object-cover tw-w-full tw-h-full tw-top-0 tw-left-0"
          :src="imageUrl"
        >
      </div>

      <!-- Next and previous buttons -->
      <inline-svg
        alt="Previous Slide"
        class="ce-arrow-icon tw-left-4 md:tw-left-10 lg:tw-left-20"
        :src="$cdn('icons/carousel/carousel-navigation-left.svg')"
        @click="plusSlides(-1)"
      />
      <inline-svg
        alt="Next Slide"
        class="ce-arrow-icon tw-right-4 md:tw-right-10 lg:tw-right-20"
        :src="$cdn('icons/carousel/carousel-navigation-right.svg')"
        @click="plusSlides(1)"
      />
    </div>
    <div class="tablet:tw-pb-10 tw-flex tablet:tw-hidden tw-px-4 tw-pt-5">
      <h1
        class="tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet dekstop:tw-text-sg-h1"
      >
        {{ props.wordpressData.the_title }}
      </h1>
    </div>

    <!-- Content -->
    <div
      :class="[
        'tw-flex tw-flex-col-reverse tw-justify-center tw-max-w-[1440px] tw-mx-auto',
        'tw-px-4 tw-py-5',
        'md:tw-pt-10 md:tw-pb-5 md:tw-px-10 md:tw-flex-row-reverse',
        'lg:tw-px-20',
      ]"
    >

      <div
        class="tw-flex tw-flex-col tw-flex-grow
      md:tw-max-w-[704px] md:tw-pl-5
      lg:tw-max-w-[975px] lg:tw-pl-10"
      >
        <div
          class="tw-pb-8 tablet:tw-pb-10 tw-hidden tablet:tw-flex
        md:tw-min-w-[426px] md:tw-max-w-[684px]
        lg:tw-min-w-[624px] lg:tw-max-w-[935px]"
        >
          <h1
            class="tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet dekstop:tw-text-sg-h1"
          >
            {{ props.wordpressData.the_title }}
          </h1>
        </div>
        <!-- WYSIWYG -->
        <ce-wysiwyg-viewer
          :class="[
            'tw-flex tw-flex-col tw-flex-grow tw-max-w-[-webkit-fill-available] tw-pb-[84px] md:tw-pb-[104px]',

          ]"
          :data="props.wordpressData.the_content"
        />
      </div>

      <!-- Details -->
      <div
        :class="[
          'tw-flex tw-flex-col tw-w-full',
          'md:tw-pr-5 md:tw-min-w-[134px] md:tw-max-w-[240px]',
          'lg:tw-pr-10 lg:tw-min-w-[161px] lg:tw-max-w-[305px]',
          'sgDesktop2:tw-pr-14',
        ]"
      >

        <div class="tw-flex tw-flex-col tw-w-full tw-gap-8 md:tw-gap-[48px] lg:tw-gap-[60px] sgTablet:tw-sticky sgTablet:tw-top-20 sgTablet:tw-pb-20 sgDesktop:tw-pb-0">
          <div>
            <p class="tw-text-sg-sh3-mobile md:tw-text-sg-sh3-tablet lg:tw-text-sg-sh3 tw-font-bold">
              {{ getVideoByText(0, $t('ce_video_present_by'), props.wordpressData.video_present_by) }}
            </p>
            <p class="tw-pt-2 tw-text-sg-body-1-mobile md:tw-text-sg-body-1-tablet lg:tw-text-sg-body-1">
              {{ getVideoByText(1, null , props.wordpressData.video_present_by) }}
            </p>
          </div>


          <div>
            <p class="tw-text-sg-sh3-mobile md:tw-text-sg-sh3-tablet lg:tw-text-sg-sh3 tw-font-bold">
              {{ getVideoByText(0, $t('ce_video_produced_by'), props.wordpressData.video_produce_by) }}
            </p>
            <p class="tw-pt-2 tw-text-sg-body-1-mobile md:tw-text-sg-body-1-tablet lg:tw-text-sg-body-1">
              {{ getVideoByText(1, null , props.wordpressData.video_produce_by) }}
            </p>
          </div>


          <div
            class="tw-pb-8 tw-w-full md:tw-pb-0
        md:tw-min-w-[134px] md:tw-max-w-[220px]
        lg:tw-min-w-[161px] lg:tw-max-w-[265px]"
          >
            <button
              v-show="(props.wordpressData.video_cta_url !== '')"
              class="tw-w-full tw-h-[52px] tw-pl-6 tw-pr-5 tw-py-2 tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer"
              :class="'tw-bg-sg-sccc1 tw-text-white hover:tw-bg-[#9945C1] hover:tw-text-white active:tw-bg-[#520E72] active:tw-text-white tw-rounded-[26px]'"
              :href="props.wordpressData.event_visit_page_link"
              target="_blank"
            >
              <span
                class="tw-flex tw-items-center tw-text-white tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1"
              >
                <p class="tw-text-sg-button-1 tw-uppercase">   {{ $t('ce_visit_page') }}</p>
                <inline-svg
                  class="tw-w-5 tw-h-5 tw-ml-2"
                  :src="$cdn('icons/arrow_outward_white.svg')"
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <ce-navigation-bar />
    <ce-partner-section />
    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
onMounted(() => {
  showSlides(slideIndex);
  // console.log('wordpressData:', props.wordpressData);
});


const props = defineProps({
  wordpressData: {
    type: Object,
    default: null,
  }
});

let imageUrls: string[] = [];
let slideIndex = 1;


if (props.wordpressData && props.wordpressData.video_gallery) {
  try {
    imageUrls = JSON.parse(props.wordpressData.video_gallery).map((image: any) => image.url);
  } catch (error) {
    console.error('Error parsing video_gallery:', error);
  }
}

/**
 * Retrieves the event presenter by text at the specified index.
 *
 * @param {number} index - The index of the desired presenter text.
 * @param {string | null | undefined} defaultText - The default text to return if an error occurs.
 * @returns {string} - The event presenter text at the specified index, or the default text if an error occurs.
 */
function getVideoByText(index: number, defaultText: string | null | undefined = '',wordpressText:string) {
  const have = (wordpressText.includes('|') && (wordpressText.split('|').length >= 2));
  const textSplit = wordpressText.split('|');
  if (have) {
    try {
      if (index == 0 && textSplit[index] == '') {
        return defaultText;
      }
      return textSplit[index];
    } catch (error) {
      console.error('Error parsing video_present_by:', error);
      return defaultText;
    }
  } else {
    return index == 0 ? defaultText : wordpressText;
  }
}

// Next/previous controls
function plusSlides(n: number) {
  showSlides(slideIndex += n);
}

function showSlides(n: number) {
  let i;

  let slides: HTMLElement[] = Array.from(document.getElementsByClassName('the-slide')) as HTMLElement[];

  if (n > slides.length) {slideIndex = 1;}
  if (n < 1) {slideIndex = slides.length;}
  for (let i = 0; i < slides.length; i++) {
    slides[i].style.display = 'none';
  }

  slides[slideIndex - 1].style.display = 'block';

}
</script>

<style scoped>
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}
.slideshow-container {
    position: relative;
    margin: auto;
    padding-top: 56.25%; /* 9/16 * 100 = 56.25 */
}

.the-slide {
  display: none;
}

/* Next & previous buttons */
.ce-arrow-icon {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -22px;
  transition: 0.6s ease;
}

svg.ce-arrow-icon:hover path {
  fill: white;
}

svg.ce-arrow-icon:hover rect {
  fill: #8016B2;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
</style>
