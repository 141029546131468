<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="notice-modal"
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-ap-white-smoke tw-bg-opacity-75 tw-items-center tw-justify-center tw-z-[999]"
      :class="{'!tw-flex': modelValue == true}"
    >

      <!-- Modal Content -->
      <div class="tw-max-w-[343px] tw-w-full sgTablet:tw-max-w-[640px] tw-mx-4 tw-bg-ap-white notice-modal-box-shadow">

        <div class="tw-px-6 tw-py-6 sgTablet:tw-px-8 sgTablet:tw-pt-8 sgTablet:tw-pb-6 sgDesktop:tw-px-10 sgDesktop:tw-pt-7 sgDesktop:tw-pb-7">
          <div
            id="sup-handler-container"
            class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-text-[#484848]"
            v-html="$t('visitor_information.modal_notice')"
          />
        </div>
        <hr class="tw-text-[#F4F4F4] tw-bg-[##F4F4F4]">

        <div class="tw-w-full tw-px-10 tw-min-h-[100px] sgDesktop:tw-min-h-[108px] tw-flex tw-items-center tw-justify-center">
          <div
            :class="[
              'tw-flex tw-flex-col tw-w-full', // Added 'tw-w-full' to ensure the container takes the full width
              'sgDesktop:tw-max-w-[369px] sgDesktop:tw-flex-row sgDesktop:tw-justify-end sgDesktop:tw-ml-auto',
            ]"
          >
            <button
              class="tw-flex tw-justify-center tw-flex-row tw-w-full sgDesktop:tw-w-48"
              :class="{ 'tw-hidden' : !showPrimaryButton }"
            >
              <a
                class="tw-bg-[#0366D8] hover:tw-opacity-60 tw-rounded-[26px] tw-h-[52px] tw-pl-[24px] tw-w-full tw-pr-[18px] tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer"
                :href="checkLang() === 'zh-hans' ? 'https://singaporeccc.org.sg/zh-hans/events/' : 'https://singaporeccc.org.sg/events/'"
                target="_blank"
                rel="noopener"
              >
                <span class="tw-text-sg-cny24-white tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1 tw-mr-1">{{ $t('visitor_information.modal_cta') }}</span>
                <inline-svg
                  :src="$cdn('icons/top_right_arrow_icon.svg')"
                  :view-box="'0 0 24 24'"
                  alt="Arrow Top Right"
                  class="tw-ml-[8px] tw-fill-ap-white"
                />
              </a>
            </button>

            <button
              class="tw-h-[52px] tw-w-full sgDesktop:tw-w-auto tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1 tw-text-[#767676] hover:tw-underline active:tw-opacity-70 tw-px-6 tw-py-2"
              @click="handleModalClose()"
            >
              {{ $t('modal_close') }}
            </button>
          </div>
        </div>


      </div>

    </div>
  </Teleport>

</template>

<script setup lang="ts">
import { watch } from 'vue';
import { computed } from 'vue';
import { checkLang } from '../../support/helper';

const props = defineProps<{
  modelValue: boolean,
  showPrimaryButton: boolean,
}>();

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};

//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if(!newVal) {
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
  }else{
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');
  }
});
</script>

<style style="scss" scoped>
  .notice-modal-box-shadow {
    box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.30);
    border-radius: 8px;
  }

  #sup-handler-container:deep(a) {
    cursor: pointer;
    text-decoration: underline;
    color: #0366D8;
  }
</style>
