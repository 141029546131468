<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="modal"
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-[#F4F2E3] tw-bg-opacity-90 tw-items-center tw-justify-center tw-z-[9999999]"
      :class="{'!tw-flex': modelValue == true, 'tw-font-akkurat':checkLang()=='en', 'tw-font-notosans':checkLang()=='zh-hans'}"
    >

      <!-- Modal Content -->
      <div class="tw-mx-4 sgTablet:tw-mx-10 sgDesktop:tw-mx-20">
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5">
          <div
            class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group"
            @click="handleModalClose"
          >
            <p class="tw-uppercase tw-mr-1 group-hover:tw-underline">
              {{ $t('modal_close') }}
            </p>
            <img
              :src="$cdn('icons/cancel_icon.svg')"
              alt="cancel_icon"
            >
          </div>
        </div>

        <!-- LightBox -->
        <div class="tw-w-full tw-max-h-[80vh] tw-h-full">

          <h2
            class="tw-font-hobeaux tw-font-[900] tw-text-[#C52600] tw-text-center
        tw-text-[28px] tw-leading-[36px]
        sgTablet:tw-text-[36px] sgTablet:tw-leading-[44px]
        sgDesktop:tw-text-[44px] sgDesktop:tw-leading-[56px]"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'"
          >
            {{ $t('cny_personality_quiz_result_title') }}
          </h2>

          <!-- Image Box -->
          <div class="tw-mt-10">
            <img
              class="tw-object-contain tw-w-auto tw-object-center tw-max-h-[50vh] tw-rounded-lg tw-mx-auto"
              :style="{
                boxShadow: '-4px 4px 10px 0px rgba(0, 0, 0, 0.3)'
              }"
              :src="image"
            >
          </div>
          <div
            class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 tw-text-center tw-text-[#484848] tw-mt-10"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'"
          >
            {{ $t('cny_personality_quiz_result_download_and_share') }}
          </div>

          <div class="tw-flex tw-items-center tw-justify-center">
            <a
              target="_blank"
              :href="image"
              class="tw-h-[32px] tw-py-1 tw-px-6 sgTablet2:tw-px-4 sgDesktop:tw-px-6 tw-text-[#0366D8] tw-mt-5 sgTablet:tw-mt-3"
            >
              <div class=" tw-flex tw-flex-row tw-items-center">
                {{ $t('cny_greet_them_right_save_image') }}
                <inline-svg
                  alt="Download Icon"
                  :src="$cdn(`/icons/download_icon.svg`)"
                  class="tw-fill-[#0366D8] tw-w-6 tw-h-6 tw-ml-2"
                />
              </div>
            </a>
          </div>

        </div>


      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { onMounted, computed,ref } from 'vue';
import { checkLang } from '../../../support/helper';
const props = defineProps<{
  modelValue: boolean,
  image: string
}>();

const emit = defineEmits(['update:modelValue','onShow','onHide']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};
//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if(!newVal) {
    emit('onHide');
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
  }else{
    emit('onShow');
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');

  }
});
</script>
