<template>
  <Transition name="drawer-fade">
    <div
      v-if="!modelValue"
      class="tw-fixed tw-right-0 tw-top-[calc((100vh-142px)/2)] tw-z-[9999]"
      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
      @click="toggleDrawer"
    >
      <div class="tw-relative tw-inline-block tw-w-9 tw-transition-opacity tw-duration-150">
        <img
          :src="$cdn('icons/cny/primary_navigation_handle_red.png')"
          class="tw-w-9 tw-flex tw-hidden tw-cursor-pointer tw-absolute tw-top-0 tw-left-0"
          style="filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));"
          alt="Navigation Handle Red"
        >
        <img
          :src="$cdn('icons/cny/primary_navigation_handle_orange.png')"
          class="tw-w-9 tw-flex tw-cursor-pointer tw-absolute tw-top-0 tw-left-0"
          style="filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.06));"
          alt="Navigation Handle Orange"
        >
      </div>
    </div>
    <div
      v-else
      class="tw-fixed tw-right-0 tw-top-0 tw-w-[60vw] tablet:tw-w-[45vw] desktop:tw-w-[30vw] tw-h-[100vh] tw-flex tw-flex-row"
    >
      <div
        class="tw-w-9 tw-relative"
        @click="toggleDrawer"
      >
        <img
          :src="$cdn('icons/cny/primary_navigation_handle_white.png')"
          class="tw-w-9 tw-fixed tw-top-[calc((100vh-142px)/2)] tw-cursor-pointer tw-transition-opacity tw-duration-700"
          alt="Navigation Handle"
        >
      </div>
      <div
        class="tw-bg-white tw-w-full tw-h-full tw-p-10 tw-py-20 tw-gap-y-8 tw-flex tw-flex-col tw-overflow-y-auto"
        :class="
          checkLang() == 'zh-hans'
            ? 'tw-text-sg-sh3-mobile-cny-zh sgTablet:tw-text-sg-sh3-tablet-cny-zh sgDesktop:tw-text-sg-sh3-cny-zh tw-font-notosansbold'
            : 'tw-text-sg-sh3-mobile-cny sgTablet:tw-text-sg-sh3-tablet-cny sgDesktop:tw-text-sg-sh3-cny tw-font-akkuratbold'
        "
      >
        <div
          v-for="(item, index) in menuItems"
          :key="index"
          class="tw-text-sg-cny24-red tw-text-sg-cny-button-1 tw-uppercase"
          :class="{
            '!tw-text-underline': currentRoute === item.link,
            'tw-font-notosansbold': checkLang() == 'zh-hans',
            'tw-font-akkuratbold': checkLang() == 'en',
          }"
        >
          <span v-if="item.isAccordian === false">
            <a
              :href="$langRedirect(item.link, item.isExternal ? 'external' : '')"
              :target="item.isExternal ? '_blank' : '_self'"
              :style="{ textDecoration: currentRoute === item.link ? 'underline' : 'inherit' }"
              :class="
                checkLang() == 'zh-hans'
                  ? 'tw-font-notosansbold'
                  : 'tw-font-akkuratbold'
              "
            >{{ `${$t(item.label)}` }}
            </a>
          </span>

          <!-- Accordian Menu -->
          <div
            v-else
            class=""
          >
            <div
              class="tw-flex tw-items-center tw-flex-row tw-group tw-cursor-pointer tw-pb-1"
              :class="{ 'tw-underline tw-text-sg-cny24-red': accordianActive.includes(item.label) }"
              @click="handleAccordianClick(`${item.label}`)"
            >
              <span
                :class="{
                  'tw-underline': !!item.accordian && item.accordian.map((v) => v?.link).includes(currentRoute),
                  'tw-font-notosansbold': checkLang() == 'zh-hans',
                  'tw-font-akkuratbold': checkLang() == 'en',
                }"
              >
                {{ `${$t(item.label)}` }}
              </span>
              <div
                v-if="item.isAccordian === true"
                class="tw-ml-2 tw-align-middle tw-flex tw-items-center"
                @click="handleAccordianClick(`${item.label}`)"
              >
                <inline-svg
                  :src="$cdn('icons/arrow_drop_down.svg')"
                  class="tw-fill-sg-cny24-red tw-h-6 tw-w-6 tw-cursor-pointer"
                  alt="Arrow Right"
                  :class="{ '-tw-rotate-180 tw-transition-transform': accordianActive.includes(item.label) }"
                  @click="handleAccordianClick(`${item.label}`)"
                />
              </div>
            </div>
            <div
              v-if="accordianActive.includes(item.label)"
              class="tw-flex tw-flex-col tw-pt-4 tw-text-sg-cny-bt2 tw-uppercase tw-transform tw-transition-transform tw-text-sg-cny24-red tw-gap-y-4 tw-cursor-pointer"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
            >
              <a
                v-for="(subItem, subItem_index) of item.accordian"
                :key="subItem.label + subItem_index"
                class="hover:tw-underline"
                :class="{
                  'hover:tw-no-underline tw-cursor-not-allowed tw-text-sg-cny24-red-w60 tw-text-opacity-50': subItem.isDisabled,
                  'tw-underline': currentRoute === subItem.link,
                }"
                :href="$langRedirect(subItem.link, subItem.isExternal ? 'external' : '')"
                :target="subItem.isExternal ? '_blank' : '_self'"
              >
                {{ `${$t(subItem.label)}` }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from 'vue';
import { langRedirect } from '../../support/langRedirect';
import { checkLang } from '../../support/helper';

// const isDrawerOpen = ref<boolean>(false);
const accordianActive = ref<string>('');

const props = defineProps<{
  modelValue: boolean;
}>();

onMounted(() => {
  getCurrentRoute();
});

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const currentRoute = ref<string>('');

const toggleDrawer = () => {
  modelValue.value = !modelValue.value;
};

const handleAccordianClick = (content: string) => {
  if (accordianActive.value === content) {
    accordianActive.value = '';
  } else {
    accordianActive.value = content;
  }
};

const getCurrentRoute = () => {
  const url = window.location.href;
  const parts = url.replace(/^https?:\/\//, '').split('/');

  const extractedPath =
    '/' +
    parts
      .filter((item) => item !== '' && item !== '?lang=zh-hans')
      .slice(1)
      .join('/');
  currentRoute.value = extractedPath;
  const accordianFound = menuItems.filter((item) => item.isAccordian).filter((item) => item.accordian?.map((v) => v?.link).includes(extractedPath));
  if (accordianFound.length > 0) {
    accordianActive.value = accordianFound[0].label;
  }
};

const menuItems = [
  {
    label: 'cny_home',
    link: '/festivals/chinese-new-year',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'cny_about',
    link: '/festivals/chinese-new-year/about',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'cny_origin_stories',
    link: '/festivals/chinese-new-year/origin-stories',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: 'cny_visiting_101',
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: 'cny_yusheng_101',
        link: '/festivals/chinese-new-year/yusheng-101',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_greet_them_right',
        link: '/festivals/chinese-new-year/family-tree',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_greetings_nav',
        link: '/festivals/chinese-new-year/greetings',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_good_luck_guides',
        link: '/festivals/chinese-new-year/good-luck-guide',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_snack_guides',
        link: '/festivals/chinese-new-year/snack-guide',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_conversation_starters',
        link: '/festivals/chinese-new-year/conversation-starters',
        isDisabled: false,
        isExternal: false,
      },
    ],
  },
  {
    label: 'cny_what_to_do',
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: 'cny_family_fun',
        link: '/festivals/chinese-new-year/familyfun',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_whats_open',
        link: '/festivals/chinese-new-year/whats-on',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_cny_music',
        link: '/festivals/chinese-new-year/music',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_quiz_survey',
        link: '/festivals/chinese-new-year/personality-quiz',
        isDisabled: false,
        isExternal: false,
      },
      {
        label: 'cny_red_packet',
        link: '/festivals/chinese-new-year/red-packet',
        isDisabled: false,
        isExternal: false,
      },
    ],
  },
  {
    label: 'cny_past_year',
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: '2023',
        link:
          checkLang() !== 'zh-hans'
            ? 'https://festivefever.singaporeccc.org.sg/chinese-new-year/archival/2023/'
            : 'https://festivefever.singaporeccc.org.sg/chinese-new-year/archival/2023/zh-hans/',
        isDisabled: false,
        isExternal: true,
      },
      {
        label: '2022',
        link:
          checkLang() !== 'zh-hans'
            ? 'https://festivefever.singaporeccc.org.sg/chinese-new-year/archival/2022/ '
            : 'https://festivefever.singaporeccc.org.sg/chinese-new-year/archival/2022/zh-hans/',
        isDisabled: false,
        isExternal: true,
      },
    ],
  },
];
</script>
