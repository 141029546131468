<template>
  <div
    class="tw-grid tw-py-[20px]  sgTablet:tw-py-[40px] tw-grid-cols-1  sgDesktop:tw-py-[60px] sgDesktop:tw-grid-cols-4"
    :class="{
      'tw-pl-4 sgTablet:tw-pl-10 sgDesktop:tw-pl-20': swiperType != 'games'
    }"
  >
    <div
      class="tw-mr-5 tw-flex tw-flex-wrap"
      :class="{
        'tw-pl-4 sgTablet:tw-pl-10 sgDesktop:tw-pl-20': swiperType == 'games'
      }"
    >
      <div class="tw-content-start tw-flex tw-flex-wrap tw-w-full">
        <div :class="['tw-font-normal tw-text-sg-h3-mobile sgTablet:tw-text-sg-h3-tablet sgDesktop:tw-text-sg-h3 tw-h-fit tw-uppercase tw-w-[100%]', sectionDesc==''?'tw-pb-8 sgTablet:tw-pb-10 sgDesktop:tw-pb-0':'' ]">
          {{ sectionTitle }}
        </div>
        <div
          :class="[
            'tw-text-sg-body-1 tw-pt-4 sgTablet:tw-pt-5 sgDesktop:tw-pt-10 tw-pb-8 sgTablet:tw-pb-5 sgDesktop:tw-pb-0 tw-h-fit',
            swiperType == 'poems' ? 'tw-italic' : '',
            {
              'tw-break-keep':checkLang()=='en'
            }
          ]"
        >
          {{ sectionDesc }}
        </div>
      </div>

      <div
        v-if="!hideNavigation"
        class="tw-content-end tw-hidden sgDesktop:tw-flex sgDesktop:tw-flex-wrap"
      >
        <button
          :disabled="disabledPrev"
          :class="['tw-rounded-full tw-h-[56px] tw-w-[56px] tw-mr-5 tw-text-white', btnPrevStyle]"
          @click="slidePrev()"
        >
          &lt;
        </button>
        <button
          :disabled="disabledNext"
          :class="['tw-rounded-full tw-h-[56px] tw-w-[56px] tw-mr-5 tw-text-white', btnNextStyle]"
          @click="slideNext()"
        >
          >
        </button>
      </div>
    </div>
    <!-- ARTICLES / BOOKS / SONGS AND POEMS / VIDEOS / EXHIBITION / FESTIVALS -->
    <div
      v-if="swiperType != 'games'"
      class="tw-col-span-3"
    >
      <swiper
        :space-between="16"
        :class="swiperClassName"
        :slides-per-view="'auto'"
        :slides-per-group="1"
        :initial-slide="0"
        :breakpoints="{
          768: {
            spaceBetween:20,
          },

        }"
        @swiper="onSwiper"
        @slide-change="checkSlidePosition"
        @snap-grid-length-change="handleSnapGrid"
        @touch-end="checkSlidePosition"
      >
        <swiper-slide
          v-for="(item, index) in datas"
          :key="`${[swiperType]}_${index}`"
          :class="swiperStyle"
        >
          <span v-if="swiperType == 'books' || swiperType == 'articles' || swiperType == 'songsAndPoems'">
            <a
              :href="swiperType == 'articles' || swiperType == 'songsAndPoems'?$langRedirect(item.external_url):item.external_url"
              :target="swiperType == 'articles' || swiperType == 'songsAndPoems'?'_self':'_blank'"
            >

              <div
                class="tw-p-3 sgTablet:tw-p-5 sgDesktop:tw-p-8 tw-cursor-pointer"
              >
                <div class="swiperTextWrapper">
                  <div
                    class="tw-text-sg-body-1 tw-text-right tw-pb-3 sgTablet:tw-pb-5 sgDesktop:tw-pb-8"
                  >{{ item.year ||'&nbsp;' }}</div>
                </div>

                <div
                  class="tw-group tw-h-[164px] sgTablet:tw-h-[220px] sgDesktop:tw-h-[308px] tw-bg-cover"
                  :style="{ backgroundImage: `url(${item.image_url})` }"
                >
                  <!-- Top right arrow om hover -->
                  <div class="tw-w-full tw-h-full tw-relative">
                    <inline-svg
                      :src="$cdn('icons/top_right_arrow_icon.svg')"
                      alt="Arrow Top Right"
                      class="tw-fill-[#ff9300] tw-w-1/2 tw-h-1/2 tw-absolute tw-bottom-0 tw-left-0 group-hover:tw-opacity-100 tw-transition-transform group-hover:tw-translate-x-[100%] tw-duration-200 group-hover:-tw-translate-y-[100%] tw-opacity-0"
                    />
                  </div>
                </div>

                <div class="swiperTextWrapper tw-pt-1 sgTablet:tw-pt-3 sgDesktop:tw-pt-24">
                  <div class="tw-text-ap-h4-mobile sgTablet:tw-text-ap-h4-tablet sgDesktop:tw-text-ap-h4 tw-line-clamp-2">{{ item.title }}</div>
                  <div
                    class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-2-tablet sgDesktop:tw-text-sg-body-1 tw-pt-1 tw-line-clamp-1"
                  >
                    {{ item.author ||'&nbsp;' }}
                  </div>
                  <div
                    class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-2-tablet sgDesktop:tw-text-sg-body-1 tw-pt-1 tw-line-clamp-1"
                  >
                    {{ item.publisher||'&nbsp;' }}
                  </div>
                </div>
              </div>
            </a>
          </span>
          <template v-if="swiperType == 'exhibition' || swiperType == 'festivals'">
            <a
              :href="item.external_url"
              target="_blank"
            >
              <div
                class="tw-p-3 sgTablet:tw-p-4 sgDesktop:tw-p-8 tw-relative tw-cursor-pointer tw-group tw-flex-1"
              >
                <div
                  class="tw-h-[164px] sgTablet:tw-h-[220px] sgDesktop:tw-h-[380px] tw-bg-contain"
                  :style="{ backgroundImage: `url(${item.image_url})` }"
                />

                <div class="swiperTextWrapper tw-pt-3 sgTablet:tw-pt-5 sgDesktop:tw-pt-8">
                  <div
                    :class="
                      swiperType == 'exhibition'
                        ? ['tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:!tw-leading-[72px] sgDesktop:tw-text-sg-h1 sgDesktop:!tw-leading-[84px] tw-text-[#E32726]']
                        : 'tw-text-sg-h4-mobile sgTablet:tw-text-sg-h4-tablet sgDesktop:tw-text-sg-h4 tw-text-[#E32726]'
                    "
                  >
                    {{ item.title }}
                  </div>
                </div>

                <div class="tw-w-full tw-h-full tw-absolute tw-left-0 tw-bottom-0 tw-bg-opacity-10 tw-bg-[#E32726] tw-z-10 tw-hidden group-hover:tw-flex" />
              </div>
            </a>
          </template>
          <span v-if="swiperType == 'videos' || swiperType == 'poems'">
            <!-- BUTTON PLAY & IMAGE-->
            <a
              :href="item.video_url"
              target="_blank"
            >
              <div
                :class="['tw-h-[165px] !tw-w-[300px] sgTablet:tw-h-[209px] sgTablet:!tw-w-[380px] sgDesktop:tw-h-[300px] sgDesktop:!tw-w-[534px]']"
                class="tw-border-4 tw-rounded-lg tw-border-[#00D6C6] tw-aspect-video tw-bg-cover tw-flex tw-flex-wrap tw-justify-center tw-content-center tw-group tw-cursor-pointer tw-bg-center"
                :style="{ backgroundImage: `url(${item.image_url})` }"
              >
                <div class="tw-flex tw-justify-center tw-items-center">
                  <button

                    :class="[
                      'tw-relative tw-overflow-hidden  tw-rounded-full tw-h-[56px] tw-w-[56px] tw-text-white tw-flex tw-flex-row tw-justify-center tw-flex-wrap tw-content-center tw-bg-[#00D6C6] tw-opacity-100',
                      'before:tw-content-[\'\'] before:tw-absolute before:tw-top-1/2 before:tw-left-1/2 before:tw-w-[200%] before:tw-h-[200%] before:[background-image:var(--bg-image)] before:tw-bg-center before:tw-bg-no-repeat before:-tw-translate-x-1/2 before:-tw-translate-y-1/2 before:tw-scale-0 before:tw-transition-transform before:tw-ease-linear before:tw-duration-300',
                      ,
                      'group-hover:before:tw-scale-100',
                    ]"
                    :style="{
                      '--bg-image':'radial-gradient(circle,#00897E,#00897E 50%,transparent 50%)',
                      transition: 'background-color 0.3s linear',
                    }"
                  >
                    <inline-svg
                      :src="$cdn('icons/play_icon.svg')"
                      alt="Play Icon"
                      class="tw-fill-[#00897E] group-hover:tw-fill-[#00D6C6] tw-pl-[6px] tw-z-[10]"
                      :style="{ transition: 'fill 0.3s linear' }"
                    />
                  </button>
                </div>
              </div>
            </a>
            <!-- TITLE -->
            <div class="swiperTextWrapper tw-pt-4 sgTablet:tw-pt-5">
              <div class="tw-text-ap-h4-mobile sgTablet:tw-text-ap-h4-tablet sgDesktop:tw-text-ap-h4">{{ item.title }}</div>
            </div>
          </span>
        </swiper-slide>
      </swiper>
    </div>
    <!-- GAME SWIPER -->
    <div
      v-else
      class="tw-col-span-3"
    >
      <swiper
        :effect="'coverflow'"
        :grab-cursor="true"
        :centered-slides="true"
        :slides-per-view="'auto'"
        :breakpoints="{
          680:{
            coverflowEffect:{
              rotate: 0,
              stretch: 110,
              depth: 300,
              modifier: 1, // 2,3
              slideShadows: false,
            }
          },
          800:{
            coverflowEffect:{
              rotate: 0,
              stretch: 40,
              depth: 300,
              modifier: 1, // 2,3
              slideShadows: false,
            }
          },
          1025:{
            coverflowEffect:{
              rotate: 0,
              stretch: 380,
              depth: 300,
              modifier: 1, // 2,3
              slideShadows: false,
            }
          },
          1440:{
            coverflowEffect:{
              rotate: 0,
              stretch: 80,
              depth: 400,
              modifier: 1, // 2,3
              slideShadows: false,
            }
          },
        }"

        :coverflow-effect="{
          rotate: 0,
          stretch: 220,
          depth: 200,
          modifier: 1, // 2,3
          slideShadows: false,
        }"
        :loop="true"
        :initial-slide="1"
        :class="swiperClassName"
        @slide-change="checkSlidePosition"
      >
        <swiper-slide
          v-for="(item, index) in datas"
          :key="`game_${index}`"
          :class="swiperStyle"
        >
          <a
            :href="item.external_url"
            target="_blank"
          >
            <div
              class="tw-p-8 tw-flex tw-flex-wrap tw-justify-center tw-content-center tw-h-full tw-w-full tw-bg-cover tw-bg-center tw-group tw-cursor-pointer"
              :style="{ backgroundImage: `url(${item.image_url})` }"
            >
              <button
                :style="{
                  '--bg-image':'radial-gradient(circle,#A3006F,#A3006F 50%,transparent 50%)'
                }"
                :class="[
                  'tw-relative tw-overflow-hidden tw-rounded-full tw-h-[80px] tw-w-[80px] tw-flex tw-flex-row tw-justify-center tw-flex-wrap tw-content-center tw-bg-[#FF7DD5] tw-opacity-100',
                  'before:tw-content-[\'\'] before:tw-absolute before:tw-top-1/2 before:tw-left-1/2 before:tw-w-[200%] before:tw-h-[200%] before:[background-image:var(--bg-image)] before:tw-bg-center before:tw-bg-no-repeat before:-tw-translate-x-1/2 before:-tw-translate-y-1/2 before:tw-scale-0 before:tw-transition-transform before:tw-ease-linear before:tw-duration-300',
                  ,
                  'group-hover:before:tw-scale-100',
                ]"
              >
                <inline-svg
                  :src="$cdn('icons/videogames_icon.svg')"
                  alt="Video Games"
                  class="tw-w-12 tw-h-12 tw-fill-[#A3006F] group-hover:tw-fill-[#FF7DD5] tw-z-[10]"
                  :style="{ transition: 'fill 0.3s linear' }"
                />
              </button>
            </div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <!-- MOBILE VIEW BUTTON -->
    <div
      v-if="!hideNavigation"
      :class="[
        'tw-pr-5 tw-flex tw-flex-wrap tw-justify-end tw-pt-8 sgTablet:tw-pt-10 sgDesktop:tw-hidden',
        swiperType != 'games' ? 'tw-justify-end' : 'tw-justify-center',
      ]"
    >
      <div class="tw-content-end tw-flex tw-flex-wrap">
        <button
          :disabled="disabledPrev"
          :class="['tw-rounded-full tw-h-[56px] tw-w-[56px] tw-mr-5 tw-text-white', btnPrevStyle]"
          @click="slidePrev()"
        >
          &lt;
        </button>
        <button
          :disabled="disabledNext"
          :class="['tw-rounded-full tw-h-[56px] tw-w-[56px] tw-mr-5 tw-text-white', btnNextStyle]"
          @click="slideNext()"
        >
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Import Swiper Vue.js components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { EffectCoverflow } from 'swiper/modules';
import { useI18n } from 'vue-i18n';
import SwiperCore from 'swiper';
import { langRedirect } from '../../support/langRedirect';
import { checkLang } from '../../support/helper';
SwiperCore.use([EffectCoverflow]);
import { onMounted, toRefs, defineComponent, ref, Ref } from 'vue';
import { nextTick } from 'vue';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    description: {
      type: String,
      required: true,
      default: () => '',
    },
    swiperType: {
      type: String,
      default: 'books',
    },
    articlesData: {
      type: Array,
      default: () => [],
    },
    booksData: {
      type: Array,
      default: () => [],
    },
    exhibitionData: {
      type: Array,
      default: () => [],
    },
    gamesData: {
      type: Array,
      default: () => [],
    },
    videosData: {
      type: Array,
      default: () => [],
    },
    poemsData: {
      type: Array,
      default: () => [],
    },
    songsPoemsData: {
      type: Array,
      default: () => [],
    },
    festivalsData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
      default: () => '',
    },
  },
  setup(props) {
    const {t} = useI18n();
    const { swiperType } = toRefs(props);
    let sectionTitle: Ref<string> = ref('');
    let sectionDesc: Ref<string> = ref('');
    let swiperStyle: Ref<string> = ref('');
    let btnPrevStyle: Ref<string> = ref('');
    let btnNextStyle: Ref<string> = ref('');
    const modules = [EffectCoverflow];
    let swiperClassName: Ref<string> = ref(swiperType.value + 'Swiper');
    // const exhibitionArray = ['English', 'Chinese', 'Tamil', 'Malay'];

    let swiperCollection = {};

    let disabledNext: Ref<boolean> = ref(false);
    let disabledPrev: Ref<boolean> = ref(true);
    const hideNavigation: Ref<boolean> = ref(false);

    const datas: Ref<Array<any>> = ref([]);

    onMounted(() => {

      nextTick(()=>{
        if (props.swiperType === 'videos') {
          datas.value = props.videosData;
        } else if (props.swiperType === 'books') {
          datas.value = props.booksData;
        } else if (props.swiperType === 'songsAndPoems') {
          datas.value = props.songsPoemsData;
        } else if (props.swiperType === 'articles') {
          datas.value = props.articlesData;
        } else if (props.swiperType === 'exhibition') {
          datas.value = props.exhibitionData;
        } else if (props.swiperType === 'games') {
          datas.value = props.gamesData;
        } else if (props.swiperType === 'poems') {
          datas.value = props.poemsData;
        } else if (props.swiperType === 'festivals') {
          datas.value = props.festivalsData;
        }
        getSwiperStyle();
      });
    });

    const onSwiper = () => {
      // console.log(swiper);
    };
    //use as resize listener
    function handleSnapGrid(swiper){
      //swiper.snapGrid = swiper.slidesGrid.slice(0);
      checkSlidePosition();
    }

    function getSwiperStyle() {
      swiperClassName.value = swiperType.value + 'Swiper';

      if (swiperType.value == 'books' || swiperType.value == 'articles' || swiperType.value == 'songsAndPoems') {
        if (swiperType.value == 'books') {
          swiperCollection = document.querySelector('.booksSwiper').swiper;
          sectionTitle.value = props.title ? props.title : t('swiper_title_books');
        }

        if (swiperType.value == 'articles') {
          swiperCollection = document.querySelector('.articlesSwiper').swiper;
          sectionTitle.value = props.title ? props.title : t('swiper_title_articles');
        }

        if (swiperType.value == 'songsAndPoems') {
          swiperCollection = document.querySelector('.songsAndPoemsSwiper').swiper;
          sectionTitle.value = props.title ? props.title : t('swiper_title_songs_and_poems');
        }

        sectionDesc.value = props.description;
        swiperStyle.value = 'tw-h-auto tw-w-[196px] sgTablet:tw-w-[260px] tw-max-w-[190px] sgTablet:tw-max-w-[356px] sgDesktop:tw-w-[372px] sgDesktop:tw-min-h-[560px] tw-border-4 tw-rounded-lg tw-border-[#ff9300]';
      } else if (swiperType.value == 'exhibition' || swiperType.value == 'festivals') {
        if (swiperType.value == 'exhibition') {
          swiperCollection = document.querySelector('.exhibitionSwiper').swiper;

          sectionTitle.value = props.title ? props.title : t('exhibition_sheet');
          sectionDesc.value = '';
        } else {
          swiperCollection = document.querySelector('.festivalsSwiper').swiper;

          sectionTitle.value = props.title ? props.title : t('swiper_title_festivals_2023');
          sectionDesc.value = props.description;
        }

        swiperStyle.value =
          'tw-h-[260px]  !tw-w-[196px] sgTablet:tw-h-[352px] sgTablet:!tw-w-[260px] sgDesktop:!tw-w-[372px]   sgDesktop:tw-h-full sgDesktop:tw-min-h-[560px] tw-border-4 tw-rounded-lg tw-border-[#E32726] tw-flex tw-flex-col';
      } else if (swiperType.value == 'videos' || swiperType.value == 'poems') {
        if (swiperType.value == 'videos') {
          swiperCollection = document.querySelector('.videosSwiper').swiper;
          sectionTitle.value = props.title ? props.title : t('swiper_title_videos');
          sectionDesc.value = props.description;

        }

        if (swiperType.value == 'poems') {
          swiperCollection = document.querySelector('.poemsSwiper').swiper;
          sectionTitle.value = props.title ? props.title : t('swiper_title_poems');
          sectionDesc.value = props.description;

        }
        swiperStyle.value = '!tw-w-[300px] sgTablet:!tw-w-[380px] sgDesktop:!tw-w-[534px]';
      } else if (swiperType.value == 'games') {
        swiperCollection = document.querySelector('.gamesSwiper').swiper;

        sectionTitle.value = props.title ? props.title : t('swiper_title_games');
        sectionDesc.value = props.description;
        swiperStyle.value =
          'tw-w-[280px] tw-h-[280px]  sgTablet:tw-w-[340px] sgTablet:tw-h-[340px]  sgDesktop:tw-w-[480px] sgDesktop:tw-h-[480px] tw-border-4 tw-rounded-lg tw-border-[#FF00AE]';
      }
      checkSlidePosition();
    }

    function slideNext() {

      swiperCollection.slideNext();
      checkSlidePosition();
    }

    function slidePrev() {
      swiperCollection.slidePrev();
      checkSlidePosition();
    }

    function checkSlidePosition() {
      // console.log('props.swiperType', props.swiperType);
      // console.log('checkSlidePosition begin | end', swiperCollection.isBeginning, swiperCollection.isEnd);
      let activeIndex = swiperCollection.activeIndex;
      let datasLength = datas.value.length;
      hideNavigation.value = false;
      if(swiperCollection.isBeginning && swiperCollection.isEnd){
        hideNavigation.value = true;
        disabledPrev.value = true;
        disabledNext.value = true;
        if (swiperType.value == 'books' || swiperType.value == 'articles' || swiperType.value == 'songsAndPoems') {
          btnPrevStyle.value = 'tw-bg-[#ff9300] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#ff9300] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'exhibition' || swiperType.value == 'festivals') {
          btnPrevStyle.value = 'tw-bg-[#E32726] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#E32726] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'videos' || swiperType.value == 'poems') {
          btnPrevStyle.value = 'tw-bg-[#00D6C6] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#00D6C6] tw-opacity-25 group-hover:tw-bg-[#00ab9e] tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'games') {
          btnPrevStyle.value = 'tw-bg-[#FF00AE] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#FF00AE] tw-opacity-25 group-hover:tw-bg-[#cc008b] tw-text-[35px] tw-pb-2 tw-pl-1';
        }
        return;
      }
      else if (swiperCollection.snapIndex == datasLength - 1 || swiperCollection.isEnd) { //&& swiperCollection.activeIndex === datas.value.length - 1
        disabledPrev.value = false;
        disabledNext.value = true;
        if (swiperType.value == 'books' || swiperType.value == 'articles' || swiperType.value == 'songsAndPoems') {
          btnPrevStyle.value = 'tw-bg-[#ff9300] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#ff9300] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'exhibition' || swiperType.value == 'festivals') {
          btnPrevStyle.value = 'tw-bg-[#E32726] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#E32726] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'videos' || swiperType.value == 'poems') {
          btnPrevStyle.value = 'tw-bg-[#00D6C6] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#00D6C6] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'games') {
          btnPrevStyle.value = 'tw-bg-[#FF00AE] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#FF00AE] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        }
        return;
      }
      else  if (swiperCollection.snapIndex == 0 || swiperCollection.isBeginning) { //&& swiperCollection.activeIndex === 0
        disabledPrev.value = true;
        disabledNext.value = false;
        if (swiperType.value == 'books' || swiperType.value == 'articles' || swiperType.value == 'songsAndPoems') {
          btnPrevStyle.value = 'tw-bg-[#ff9300] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#ff9300] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'exhibition' || swiperType.value == 'festivals') {
          btnPrevStyle.value = 'tw-bg-[#E32726] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#E32726] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'videos' || swiperType.value == 'poems') {
          btnPrevStyle.value = 'tw-bg-[#00D6C6] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#00D6C6] tw-opacity-100 group-hover:tw-bg-[#00ab9e] tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'games') {
          btnPrevStyle.value = 'tw-bg-[#FF00AE] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#FF00AE] tw-opacity-100 group-hover:tw-bg-[#cc008b] tw-text-[35px] tw-pb-2 tw-pl-1';
        }
        return;
      } else {
        disabledPrev.value = false;
        disabledNext.value = false;
        if (swiperType.value == 'books' || swiperType.value == 'articles' || swiperType.value == 'songsAndPoems') {
          btnPrevStyle.value = 'tw-bg-[#ff9300] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#ff9300] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'exhibition' || swiperType.value == 'festivals') {
          btnPrevStyle.value = 'tw-bg-[#E32726] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#E32726] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'videos' || swiperType.value == 'poems') {
          btnPrevStyle.value = 'tw-bg-[#00D6C6] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#00D6C6] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'games') {
          btnPrevStyle.value = 'tw-bg-[#FF00AE] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#FF00AE] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pl-1';
        }
        return;
      }
      /* if ((datasLength > 3 && window.innerWidth > 768) || (datasLength > 2 && window.innerWidth <= 768)) {
         if (activeIndex + 2 === datasLength && swiperType.value !== 'games') {
          disabledPrev.value = false;
          disabledNext.value = true;
          if (swiperType.value == 'books' || swiperType.value == 'articles' || swiperType.value == 'songsAndPoems') {
            btnPrevStyle.value = 'tw-bg-[#ff9300] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
            btnNextStyle.value = 'tw-bg-[#ff9300] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
          } else if (swiperType.value == 'exhibition' || swiperType.value == 'festivals') {
            btnPrevStyle.value = 'tw-bg-[#E32726] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
            btnNextStyle.value = 'tw-bg-[#E32726] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
          } else if (swiperType.value == 'videos' || swiperType.value == 'poems') {
            btnPrevStyle.value = 'tw-bg-[#00D6C6] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
            btnNextStyle.value = 'tw-bg-[#00D6C6] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
          } else if (swiperType.value == 'games') {
            btnPrevStyle.value = 'tw-bg-[#FF00AE] tw-opacity-100 tw-text-[35px] tw-pb-2 tw-pr-1';
            btnNextStyle.value = 'tw-bg-[#FF00AE] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
          }
          return;
        }


      } else {
        disabledPrev.value = true;
        disabledNext.value = true;
        if (swiperType.value == 'books' || swiperType.value == 'articles' || swiperType.value == 'songsAndPoems') {
          btnPrevStyle.value = 'tw-bg-[#ff9300] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#ff9300] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'exhibition' || swiperType.value == 'festivals') {
          btnPrevStyle.value = 'tw-bg-[#E32726] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#E32726] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'videos' || swiperType.value == 'poems') {
          btnPrevStyle.value = 'tw-bg-[#00D6C6] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#00D6C6] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        } else if (swiperType.value == 'games') {
          btnPrevStyle.value = 'tw-bg-[#FF00AE] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pr-1';
          btnNextStyle.value = 'tw-bg-[#FF00AE] tw-opacity-25 tw-text-[35px] tw-pb-2 tw-pl-1';
        }
        return;
      } */
    }

    return {
      onSwiper,
      swiperClassName,
      sectionTitle,
      sectionDesc,
      swiperStyle,
      btnPrevStyle,
      btnNextStyle,
      slidePrev,
      slideNext,
      modules,
      disabledNext,
      disabledPrev,
      checkSlidePosition,
      datas,
      handleSnapGrid,
      hideNavigation,
      checkLang
    };
  },
});
</script>
<style>
.gamesSwiper .swiper-slide {
  opacity: 0.4;
}
.gamesSwiper .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.gamesSwiper .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-prev):not(.swiper-slide-next){
  visibility: hidden;
}

</style>
