<template>
  <!-- Modal Overlay -->
  <Teleport to="body">
    <div
      id="modal"
      class="tw-fixed tw-hidden tw-inset-0 tw-bg-ap-white-smoke tw-bg-opacity-75 tw-items-center tw-justify-center tw-z-[9999999]"
      :class="{'!tw-flex': modelValue == true}"
    >

      <!-- Modal Content -->
      <div
        class="tw-w-full tw-max-w-[520px] tw-px-4 sgTablet:tw-px-0 tw-rounded-lg tw-overflow-hidden tw-flex tw-flex-col tw-justify-center"
      >
        <!-- Cancel  -->
        <div class="tw-w-full tw-flex tw-flex-row tw-justify-end tw-mb-5">
          <div
            class="tw-inline-flex tw-text-ap-nero tw-cursor-pointer tw-group"
            @click="handleModalClose"
          >
            <p
              class="tw-uppercase tw-mr-1 group-hover:tw-underline"
              :class="[
                'tw-text-sg-ma-bt1',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' }
              ]"
            >
              {{ $t('modal_close') }}
            </p>
            <img
              :src="$cdn('icons/cancel_icon.svg')"
              alt="cancel_icon"
            >
          </div>
        </div>

        <!-- LightBox -->
        <div class="tw-bg-white tw-rounded-xl tw-w-full tw-overflow-hidden">
          <simplebar
            :auto-hide="false"
            class="tw-rounded-xl tw-w-full tw-shadow-ap-preset-1 tw-flex tw-flex-col tw-relative  tw-max-h-[70vh] tw-h-full tw-pb-5"
          >
            <!-- Image Box -->
            <div class="tw-pt-[56.25%] tw-relative tw-rounded-t-xl tw-overflow-hidden">
              <template v-if="data?.is_video_embed === false">
                <img
                  class="tw-object-cover tw-h-full tw-w-full tw-object-center tw-absolute tw-top-0"
                  :src="data?.image"
                >
                <div
                  v-if="isPastEvent(data?.expired_datetime)"
                  class="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-bg-black/40 tw-flex tw-justify-center"
                >
                  <div class="tw-place-self-center tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1 tw-text-white">
                    {{ $t('ma_family_fun_past_event') }}
                  </div>
                </div>
              </template>
              <iframe
                v-else
                class="tw-object-cover tw-h-full tw-w-full tw-object-center tw-absolute tw-top-0"
                :src="data?.video_link"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </div>
            <div
              :class="[
                getEventStatusColor(),
                'tw-text-white tw-text-sg-body-2 !tw-leading-[20px] tw-tracking-wider',
                'tw-px-4',
                'tw-py-[2px] sgTablet:tw-py-1',
                'tw-text-center',
                'tw-w-full',
                'tw-h-6 sgTablet:tw-h-7 sgDesktop:tw-h-8',
                'tw-flex-shrink-0'
              ]"
            >
              {{ getEventStatusText() }}
            </div>
            <div class="">
              <div class="tw-p-5 ">
                <div
                  class="tw-text-sg-ma-sh1"
                  :class="[
                    { 'tw-font-akkuratbold tw-break-keep' : checkLang() == 'en' },
                    { 'tw-font-notosansbold' : checkLang() != 'en' }
                  ]"
                >
                  {{ data?.title }}
                </div>
                <table class="tw-text-sm tw-text-[#1A1A1A] tw-border-separate tw-border-spacing-y-1 tw-text-sg-body-1 tw-mt-2">
                  <tr>
                    <td class="tw-pr-2">
                      {{ $t('common.dates') }}
                    </td>
                    <td>{{ data?.date_str }}</td>
                  </tr>
                  <tr>
                    <td class="tw-pr-2">
                      {{ $t('common.times') }}
                    </td>
                    <td>{{ data?.time }}</td>
                  </tr>
                  <tr>
                    <td class="tw-pr-2 tw-align-top">
                      {{ $t('common.location') }}
                    </td>
                    <td>{{ data?.venue }}</td>
                  </tr>
                </table>


              </div>
              <div
                :class="[
                  'tw-p-5 tw-pt-0',
                  'tw-max-w-none',
                  'tw-prose',
                  'tw-font-akkurat',
                  'tw-text-sg-body-1'
                ]"
                v-html="data?.description"
              />
            </div>
          </simplebar>

        </div>

        <div
          v-if="data?.external_link"
          class="tw-w-full tw-flex tw-flex-row tw-justify-center tw-mt-5"
        >
          <a
            :href="data?.external_link"
            target="_blank"
            class="tw-flex tw-items-center tw-justify-center tw-rounded-[26px] tw-bg-sg-ma24-purple hover:tw-bg-sg-ma24-purple-w20 active:tw-bg-sg-ma24-purple-b40 tw-h-[52px] tw-w-[251px] tw-text-white tw-gap-x-2"
            :class="[
              'tw-text-sg-ma-bt1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            <div>
              <div>{{ $t('cny_family_fun_get_your_tickets_here') }}</div>
            </div>
            <inline-svg :src="$cdn('icons/arrow_outward.svg')" />
          </a>
        </div>


      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { checkLang } from '../../support/helper';
import { ComputedRef, watch } from 'vue';
import { onMounted, computed,ref } from 'vue';
import { useI18n } from 'vue-i18n';

import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';

const {t} = useI18n();
type MaFamilyFunProgrammeCategoryType = 'activities' | 'workshops' | 'performances' | 'online';
type MaFamilyFunProgrammeCategoryFilterType = 'all' | MaFamilyFunProgrammeCategoryType;
type MaFamilyFunProgrammeStatusType = 'free_event' | 'paid_event' | 'selling_fast' | 'sold_out' | 'fully_booked';
type MaFamilyFunProgrammePostType = {
  title:string,
  description:string,
  category: MaFamilyFunProgrammeCategoryType,
  dates:string[],
  date_str:string,
  time:string,
  venue:string,
  paid:boolean,
  status: MaFamilyFunProgrammeStatusType,
  image:string,
  external_link?:string,
  is_video_embed:boolean,
  video_link?:string,
  expired_datetime?:string,
}


const props = defineProps<{
  modelValue: boolean,
  data?:MaFamilyFunProgrammePostType
}>();

const emit = defineEmits(['update:modelValue']);

const modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const handleModalClose = () => {
  modelValue.value = false;
};

onMounted(() => {
  if (props.data?.title !== undefined) {
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');
  }
});
//disable body scroll when modal is open
watch(modelValue, (newVal) => {
  if(!newVal) {
    document.getElementsByTagName('body')[0].classList.remove('tw-overflow-hidden');
  }else{
    document.getElementsByTagName('body')[0].classList.add('tw-overflow-hidden');
  }
});

function getEventStatusColor() {
  if(!props.data?.status)
    return 'tw-bg-[#E4100E]';

  const statusMap:{[key:string]:string} = {
    'free_event':'tw-bg-[#36A93F]',
    'fully_booked':'tw-bg-[#E4100E]',
    'paid_event':'tw-bg-[#0366D8]',
    'selling_fast':'tw-bg-[#F6610C]',
    'sold_out':'tw-bg-[#E4100E]',
  };

  return statusMap[props.data?.status];
}

function getEventStatusText(): string {
  if(!props.data?.status)
    return '';
  return t(`ma_family_fun_status_${props.data?.status}`);
}

function isPastEvent(isoDate?: string){
  if(!isoDate) return false;
  const date = new Date(isoDate + '+08:00');
  const now = new Date();
  return date < now;
}
</script>
