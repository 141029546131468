<template>
  <div class="tw-overflow-hidden">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-navigation="true"
    />

    <secondary-navigation
      :menu-items-data="menuItems"
      :offset-y="70"
    />

    <!-- Header -->
    <section>
      <div class="tw-relative">
        <picture>
          <source
            media="(max-width: 375px)"
            :srcset="$cdn('images/cny2024/banner_375.png')"
          >
          <source
            media="(max-width: 680px)"
            :srcset="$cdn('images/cny2024/banner_680.png')"
          >
          <source
            media="(max-width: 800px)"
            :srcset="$cdn('images/cny2024/banner_800.png')"
          >
          <source
            media="(max-width: 1025px)"
            :srcset="$cdn('images/cny2024/banner_1025.png')"
          >
          <img
            :src="$cdn('images/cny2024/banner_1440.png')"
            class="tw-w-full tw-object-cover"
          >
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny tw-pb-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_visiting_101') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-mt-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_yusheng_101') }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Content -->
    <div class="tw-flex tw-flex-col tw-w-full">
      <!-- Yusheng Text -->
      <div class="tw-bg-[#FFE39E] tw-w-full tw-flex tw-justify-center tw-pb-20">
        <div
          class="tw-flex tw-flex-col tw-px-4 tw-py-5 tw-pb-20 tw-items-center tablet:tw-px-10 tablet:tw-py-10 desktop:tw-pt-10 tw-text-sg-body-1-mobile-cny tablet:tw-text-sg-body-1-tablet-cny desktop:tw-text-sg-body-1-cny tw-max-w-[800px]"
          v-html="wordpressData.description"
        />
        <!-- <div class="tw-pb-20" /> -->
      </div>
      <!-- 1-Raw Fish -->
      <div
        :id="getSectionId(1)"
        class="tw-w-full tw-bg-white sgDesktop:tw-h-[452px] sgTablet:tw-h-[460px] tw-max-h-[492px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[452px] sgTablet:tw-h-[460px]"
              :src="$cdn(yushengDataArray[0].imageGroup)"
            >
          </div>
          <!-- Column 1 -->
          <div class="tw-flex tw-flex-col tw-flex-auto">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[55%] sgDesktop:tw-w-[75%]">
                <img
                  class="recipe child sgTablet:-tw-mt-[40px]"
                  :src="$cdn(yushengDataArray[0].image)"
                >
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] sgTablet:tw-static sgTablet:tw-flex-row">
                  <p
                    class="child-text tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-text-sg-dp1-tablet sgTablet:tw-ml-[1.7rem] sgDesktop:tw-text-sg-dp1 sgDesktop:tw-ml-0"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    1
                  </p>
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-center tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet sgTablet:tw-top-[20px] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:-tw-ml-[1rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:-tw-ml-[1.8rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="90"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          {{ yushengDataArray[0].chineseWord }}
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[0].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[36px]"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(1)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col -tw-mt-[2rem]">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[0].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-min-w-[250px] tw-items-center tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] -tw-ml-[3rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      1
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="65"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            {{ yushengDataArray[0].chineseWord }}
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[0].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(1)"
                  >
                </div>
              </div>
            </div>
            <cny-24-yusheng-section-text
              :title="yushengDataArray[0].title"
              :title-chinese="yushengDataArray[0].titleChinese"
              :significance-paragraph="yushengDataArray[0].significanceParagraph"
            />
          </div>
        </div>
      </div>
      <!-- 2-Pomelo -->
      <div
        :id="getSectionId(2)"
        class="tw-w-full tw-bg-[#FFBAF6] tw-h-[348px] sgDesktop:tw-h-[356px] sgTablet:tw-h-[364px] tw-max-h-[364px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[356px] sgTablet:tw-h-[364px]"
              :src="$cdn(yushengDataArray[1].imageGroup)"
            >
          </div>
          <!-- Column 2 -->
          <div class="tw-flex tw-flex-col tw-flex-auto">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[60%] sgDesktop:tw-w-[75%]">
                <img
                  class="recipe child sgTablet:-tw-mt-[40px]"
                  :src="$cdn(yushengDataArray[1].image)"
                >
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] sgTablet:tw-static sgTablet:tw-flex-row">
                  <p
                    class="child-text tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-ml-[1rem] sgTablet:tw-text-sg-dp1-tablet sgDesktop:tw-text-sg-dp1"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    2
                  </p>
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-center tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet sgTablet:tw-top-[20px] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:-tw-ml-[1rem] sgTablet:-tw-mt-[1.5rem] sgDesktop:tw-text-sg-h1 sgDesktop:-tw-ml-[1.8rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="90"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          {{ yushengDataArray[1].chineseWord }}
                        </text>
                      </svg>
                    </p>

                    <p
                      class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:-tw-ml-[3rem] sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[1].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[36px]"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(2)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col -tw-mt-[2rem]">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[1].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-min-w-[250px] tw-items-center tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] -tw-ml-[3rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      2
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="65"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            {{ yushengDataArray[1].chineseWord }}
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[1].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(2)"
                  >
                </div>
              </div>
            </div>
            <cny-24-yusheng-section-text
              :title="yushengDataArray[1].title"
              :title-chinese="yushengDataArray[1].titleChinese"
              :significance-paragraph="yushengDataArray[1].significanceParagraph"
            />
          </div>
        </div>
      </div>
      <!-- 3-Spices -->
      <div
        :id="getSectionId(3)"
        class="tw-h-full tw-w-full tw-bg-white sgTablet:tw-h-[468px] sgDesktop:tw-h-[496px] tw-max-h-[500px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[496px] sgTablet:tw-h-[468px]"
              :src="$cdn(yushengDataArray[2].imageGroup)"
            >
          </div>

          <!-- Column 3 -->
          <div class="tw-flex tw-flex-col tw-flex-auto">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-px-4 sgTablet:tw-justify-end sgDesktop:tw-justify-start sgTablet:tw-px-0 sgTablet:tw-flex-row-reverse sgDesktop:tw-flex-row"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[55%] sgDesktop:tw-w-[75%]">
                <img
                  class="recipe child sgTablet:-tw-mt-[40px]"
                  :src="$cdn(yushengDataArray[2].image)"
                >
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] sgTablet:tw-static sgTablet:tw-flex-row">
                  <p
                    class="child-text tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-ml-[1rem] sgTablet:tw-text-sg-dp1-tablet sgDesktop:tw-text-sg-dp1"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    3
                  </p>
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-center tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet sgTablet:tw-top-[20px] sgDesktop:tw-left-[7.5rem] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:tw-ml-[1rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:-tw-ml-[1.8rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="90"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          {{ yushengDataArray[2].chineseWord }}
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[2].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[36px]"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(3)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col -tw-mt-[1rem]">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-ml-[9rem] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[2].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row-reverse tw-justify-end">
                  <div class="tw-flex tw-flex-col tw-min-w-[250px] tw-items-center tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] tw-ml-[9rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      3
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="65"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            {{ yushengDataArray[2].chineseWord }}
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[2].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(3)"
                  >
                </div>
              </div>
            </div>
            <cny-24-yusheng-section-text
              :title="yushengDataArray[2].title"
              :title-chinese="yushengDataArray[2].titleChinese"
              :significance-paragraph="yushengDataArray[2].significanceParagraph"
            />
          </div>
        </div>
      </div>
      <!-- 4-Oil -->
      <div
        :id="getSectionId(4)"
        class="tw-h-full tw-w-full tw-bg-[#FFE39E] sgTablet:tw-h-[616px] sgDesktop:tw-h-[596px] tw-max-h-[664px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[596px] sgTablet:tw-h-[616px]"
              :src="$cdn(yushengDataArray[3].imageGroup)"
            >
          </div>
          <!-- Column 4 -->
          <div class="tw-flex tw-flex-col tw-flex-auto tw-align-bottom">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0 sgTablet:tw-ml-[65px] sgDesktop:tw-ml-0"
            >
              <div class="parent tw-min-w-[240px] sgDesktop:tw-w-[55%]">
                <p
                  class="child-text-custom-left tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-text-sg-dp1-tablet sgTablet:-tw-left-[3rem] sgDesktop:tw-text-sg-dp1 sgDesktop:-tw-mt-[3rem] sgDesktop:-tw-ml-0 sgDesktop:tw-left-0"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  4
                </p>
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] tablet:tw-static tablet:tw- tablet:tw-flex-row desktop:tw-static desktop:tw-flex-row">
                  <img
                    class="recipe child sgTablet:-tw-mt-[40px] sgTablet:-tw-ml-[1rem] sgDesktop:tw-ml-[3rem]"
                    :src="$cdn(yushengDataArray[3].image)"
                  >
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-start tw-text-sg-h1-mobile tw-left-0 sgTablet:-tw-ml-[4rem] sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet sgTablet:tw-top-[20px] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:-tw-ml-[1rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:-tw-ml-[1.8rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="150"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          <tspan>{{ yushengDataArray[3].chineseWord.split(',')[0] }},</tspan>
                          <tspan
                            x="10"
                            dy="1.2em"
                          >{{ yushengDataArray[3].chineseWord.split(',')[1] }}</tspan>
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-pt-1 tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[3].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[60px] sgTablet:-tw-ml-[1rem] sgDesktop:tw-ml-0"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(4)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-ml-[9rem] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[3].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-min-w-[250px] tw-items-center tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] tw-ml-[4rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      4
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="100"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            <tspan>{{ yushengDataArray[3].chineseWord.split(',')[0] }},</tspan>
                            <tspan
                              x="10"
                              dy="1.2em"
                            >{{ yushengDataArray[3].chineseWord.split(',')[1] }}</tspan>
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[3].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(4)"
                  >
                </div>
              </div>
            </div>
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-pb-[4rem]"
            />
            <cny-24-yusheng-section-text
              :title="yushengDataArray[3].title"
              :title-chinese="yushengDataArray[3].titleChinese"
              :significance-paragraph="yushengDataArray[3].significanceParagraph"
              :pro-tips-paragraph="yushengDataArray[3].proTipsParagraph"
              :padding="true"
            />
          </div>
        </div>
      </div>
      <!-- 5-Carrot -->
      <div
        :id="getSectionId(5)"
        class="tw-h-full tw-w-full tw-bg-white sgDesktop:tw-h-[380px] tw-max-h-[412px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[380px] sgTablet:tw-h-[412px]"
              :src="$cdn(yushengDataArray[4].imageGroup)"
            >
          </div>
          <!-- Column 5 -->
          <div class="tw-flex tw-flex-col tw-flex-auto">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0 sgTablet:tw-ml-[65px] sgDesktop:tw-ml-0"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[55%]">
                <p
                  class="child-text tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-text-sg-dp1-tablet sgTablet:-tw-ml-[8rem] sgDesktop:tw-text-sg-dp1 sgDesktop:-tw-ml-[4rem]"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  5
                </p>
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] sgTablet:tw-static sgTablet:tw-flex-row">
                  <img
                    class="recipe child sgTablet:-tw-mt-[40px] sgTablet:-tw-ml-[2.5rem] sgDesktop:tw-ml-[3rem]"
                    :src="$cdn(yushengDataArray[4].image)"
                  >
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-center tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet tablet:tw-top-[20px] sgTablet:-tw-ml-[4rem] sgTablet:tw-mt-[1rem] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0 sgDesktop:tw-ml-[2rem] sgDesktop:tw-mt-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:-tw-ml-[1rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:-tw-ml-[1.8rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="90"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          {{ yushengDataArray[4].chineseWord }}
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[4].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[36px] sgTablet:-tw-ml-[2rem] sgDesktop:tw-ml-[8rem]"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(5)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col -tw-mt-[2rem]">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-ml-[2rem] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[4].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-min-w-[190px] tw-items-center tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] -tw-ml-[10rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      5
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="65"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            {{ yushengDataArray[4].chineseWord }}
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[4].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(5)"
                  >
                </div>
              </div>
            </div>
            <cny-24-yusheng-section-text
              :title="yushengDataArray[4].title"
              :title-chinese="yushengDataArray[4].titleChinese"
              :significance-paragraph="yushengDataArray[4].significanceParagraph"
            />
          </div>
        </div>
      </div>
      <!-- 6-Green Radish -->
      <div
        :id="getSectionId(6)"
        class="tw-h-full tw-w-full tw-bg-[#00E58D] sgDesktop:tw-h-[524px] sgTablet:tw-h-[496px] tw-max-h-[524px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[524px] sgTablet:tw-h-[496px]"
              :src="$cdn(yushengDataArray[5].imageGroup)"
            >
          </div>
          <!-- Column 6 -->
          <div class="tw-flex tw-flex-col tw-flex-auto -tw-mt-[2rem]">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0 sgTablet:tw-ml-[65px] sgDesktop:tw-ml-0"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[55%]">
                <p
                  class="child-text tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-text-sg-dp1-tablet sgTablet:-tw-ml-[8rem] sgDesktop:tw-text-sg-dp1 sgDesktop:-tw-ml-[4rem]"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  6
                </p>
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] sgTablet:tw-static sgTablet:tw-flex-row">
                  <img
                    class="recipe child sgTablet:-tw-mt-[40px] sgTablet:-tw-ml-[2.5rem] sgDesktop:tw-ml-[3rem]"
                    :src="$cdn(yushengDataArray[5].image)"
                  >
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-center tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet tablet:tw-top-[20px] sgTablet:-tw-ml-[5rem] sgTablet:tw-mt-[1rem] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0 sgDesktop:tw-ml-[1rem] sgDesktop:tw-mt-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:-tw-ml-[1rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:-tw-ml-[1.8rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="90"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          {{ yushengDataArray[5].chineseWord }}
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[5].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[36px] sgTablet:-tw-ml-[2rem] sgDesktop:tw-ml-[6rem]"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(6)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-ml-[2rem] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[5].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-min-w-[190px] tw-items-center tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] -tw-ml-[10rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      6
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="65"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            {{ yushengDataArray[5].chineseWord }}
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[5].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(6)"
                  >
                </div>
              </div>
            </div>
            <cny-24-yusheng-section-text
              :title="yushengDataArray[5].title"
              :title-chinese="yushengDataArray[5].titleChinese"
              :significance-paragraph="yushengDataArray[5].significanceParagraph"
              :pro-tips-paragraph="yushengDataArray[5].proTipsParagraph"
            />
          </div>
        </div>
      </div>
      <!-- 7-White Radish -->
      <div
        :id="getSectionId(7)"
        class="tw-h-full tw-w-full tw-bg-white sgDesktop:tw-h-[524px] sgTablet:tw-h-[484px] tw-max-h-[524px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[524px] sgTablet:tw-h-[484px]"
              :src="$cdn(yushengDataArray[6].imageGroup)"
            >
          </div>
          <!-- Column 7 -->
          <div class="tw-flex tw-flex-col tw-flex-auto -tw-mt-[1rem]">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0 sgTablet:tw-ml-[65px] sgDesktop:tw-ml-0"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[55%]">
                <p
                  class="child-text tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-text-sg-dp1-tablet sgTablet:-tw-ml-[8rem] sgDesktop:tw-text-sg-dp1 sgDesktop:-tw-ml-[4rem] sgDesktop:-tw-mt-[3.5rem]"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  7
                </p>

                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] sgTablet:tw-static sgTablet:tw-flex-row">
                  <img
                    class="recipe child sgTablet:-tw-mt-[40px] sgTablet:-tw-ml-[2.5rem] sgDesktop:tw-ml-[2.5rem]"
                    :src="$cdn(yushengDataArray[6].image)"
                  >
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-start tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet tablet:tw-top-[20px] sgTablet:-tw-ml-[5rem] sgTablet:tw-mt-[1rem] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0 sgDesktop:-tw-ml-[4rem] sgDesktop:tw-mt-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:-tw-ml-[1rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:-tw-ml-[1.8rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="150"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          <tspan>{{ yushengDataArray[6].chineseWord.split(',')[0] }},</tspan>
                          <tspan
                            x="10"
                            dy="1.2em"
                          >{{ yushengDataArray[6].chineseWord.split(',')[1] }}</tspan>
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-pt-1 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[6].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[36px] sgTablet:-tw-ml-[3rem] sgDesktop:tw-ml-0"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(7)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-ml-[8rem] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[6].image)"
                >
                <div class="tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-min-w-[200px] tw-items-center tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] tw-ml-[4rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      7
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem] tw-max-w-[200px]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="100"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            <tspan>{{ yushengDataArray[6].chineseWord.split(',')[0] }},</tspan>
                            <tspan
                              x="10"
                              dy="1.2em"
                            >{{ yushengDataArray[6].chineseWord.split(',')[1] }}</tspan>
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile tw-text-center"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[6].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(7)"
                  >
                </div>
              </div>
            </div>
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-pb-[5rem]"
            />
            <cny-24-yusheng-section-text
              :title="yushengDataArray[6].title"
              :title-chinese="yushengDataArray[6].titleChinese"
              :significance-paragraph="yushengDataArray[6].significanceParagraph"
            />
          </div>
        </div>
      </div>
      <!-- 8-Sesame Seed -->
      <div
        :id="getSectionId(8)"
        class="tw-h-full tw-w-full tw-bg-[#00E8D4] sgDesktop:tw-h-[464px] sgTablet:tw-h-[484px] tw-max-h-[484px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[464px] sgTablet:tw-h-[484px]"
              :src="$cdn(yushengDataArray[7].imageGroup)"
            >
          </div>
          <!-- Column 8 -->
          <div class="tw-flex tw-flex-col tw-flex-auto">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0 sgTablet:tw-ml-[65px] sgDesktop:tw-ml-0"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[55%]">
                <img
                  class="recipe child sgTablet:-tw-mt-[40px] sgTablet:-tw-ml-[4rem] sgDesktop:tw-ml-[0.2rem]"
                  :src="$cdn(yushengDataArray[7].image)"
                >
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] sgTablet:tw-static sgTablet:tw-flex-row">
                  <div
                    class="child-outlined-text tw-flex tw-flex-col sgTablet:tw-items-baseline sgDesktop:tw-items-start tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet tablet:tw-top-[20px] sgTablet:-tw-ml-[5rem] sgTablet:tw-mt-[1rem] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0 sgDesktop:-tw-ml-[4rem] sgDesktop:tw-mt-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:-tw-ml-[1rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:-tw-ml-[1.8rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="150"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          <tspan>{{ yushengDataArray[7].chineseWord.split(',')[0] }},</tspan>
                          <tspan
                            x="10"
                            dy="1.2em"
                          >{{ yushengDataArray[7].chineseWord.split(',')[1] }}</tspan>
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[7].chinesePronounce }}
                    </p>
                  </div>
                  <p
                    class="child-text tw-text-[#C52600] tw-t.5t-sg-dp1-mobile sgTablet:tw-text-sg-dp1-tablet sgTablet:-tw-ml-[2.5rem] sgDesktop:tw-text-sg-dp1 sgDesktop:tw-ml-[0.5rem] sgDesktop:-tw-mt-[3rem]"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    8
                  </p>
                </div>
              </div>
              <img
                class="tw-pt-[36px] sgTablet:-tw-ml-[3rem] sgDesktop:tw-ml-0 sgDesktop:tw-mt-[2rem]"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(8)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col -tw-mt-[1rem]">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-ml-[9rem] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[7].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-items-start tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] tw-ml-[14rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      8
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-start -tw-mt-[1rem] tw-max-w-[200px]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="100"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            <tspan>{{ yushengDataArray[7].chineseWord.split(',')[0] }},</tspan>
                            <tspan
                              x="10"
                              dy="1.2em"
                            >{{ yushengDataArray[7].chineseWord.split(',')[1] }}</tspan>
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile tw-text-center"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[7].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px] -tw-ml-[3rem]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(8)"
                  >
                </div>
              </div>
            </div>
            <div
              v-if="currentSize !== 'mobile'"
              class="sgTablet:tw-pb-[9rem] sgDesktop:tw-pb-[6rem]"
            />
            <cny-24-yusheng-section-text
              :title="yushengDataArray[7].title"
              :title-chinese="yushengDataArray[7].titleChinese"
              :significance-paragraph="yushengDataArray[7].significanceParagraph"
              :padding-top="currentSize === 'mobile' ? false : true"
            />
          </div>
        </div>
      </div>
      <!-- 9-Golden Cracker -->
      <div
        :id="getSectionId(9)"
        class="tw-h-full tw-w-full tw-bg-white sgDesktop:tw-h-[420px] sgTablet:tw-h-[412px] tw-max-h-[440px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[420px] sgTablet:tw-h-[412px]"
              :src="$cdn(yushengDataArray[8].imageGroup)"
            >
          </div>
          <!-- Column 9 -->
          <div class="tw-flex tw-flex-col tw-flex-auto">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0 sgTablet:tw-ml-[65px] sgDesktop:tw-ml-0"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[60%] sgDesktop:tw-w-[80%]">
                <img
                  class="recipe child sgTablet:-tw-mt-[40px] sgTablet:-tw-ml-[2.5rem] sgDesktop:tw-ml-[0rem]"
                  :src="$cdn(yushengDataArray[8].image)"
                >
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] sgTablet:tw-static sgTablet:tw-flex-row">
                  <p
                    class="child-text tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-text-sg-dp1-tablet sgTablet:-tw-ml-[1.5rem] sgDesktop:tw-text-sg-dp1 sgDesktop:tw-ml-[0.5rem] sgDesktop:-tw-mt-[0rem]"
                    :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                  >
                    9
                  </p>
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-center tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet tablet:tw-top-[20px] sgTablet:-tw-ml-[5rem] sgTablet:tw-mt-[1rem] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0 sgDesktop:-tw-ml-[4rem] sgDesktop:tw-mt-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:tw-ml-[1rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:tw-ml-[3rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="90"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          {{ yushengDataArray[8].chineseWord }}
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-pt-1 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1 sgDesktop:tw-ml-[3rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[8].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[36px] sgTablet:-tw-ml-[3rem] sgDesktop:tw-ml-0"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(9)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col -tw-mt-[0.5rem]">
                <img
                  class="tw-absolute -tw-mt-[40px] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[8].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-min-w-[200px] tw-items-center tw-justify-center">
                    <!-- Number -->
                    <p
                      class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem] -tw-ml-[2rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                    >
                      9
                    </p>
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="65"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            {{ yushengDataArray[8].chineseWord }}
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[8].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(9)"
                  >
                </div>
              </div>
            </div>
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-pb-[1rem]"
            />
            <cny-24-yusheng-section-text
              :title="yushengDataArray[8].title"
              :title-chinese="yushengDataArray[8].titleChinese"
              :significance-paragraph="yushengDataArray[8].significanceParagraph"
            />
          </div>
        </div>
      </div>
      <!-- 10-Plum Sauce -->
      <div
        :id="getSectionId(10)"
        class="tw-h-full tw-w-full tw-bg-[#FFB3E3] sgDesktop:tw-h-[416px] sgTablet:tw-h-[364px] tw-max-h-[416px]"
      >
        <div class="tw-flex tw-mx-auto tw-max-w-[700px] tw-flex-row tw-align-top tw-px-4 sgTablet:tw-px-0 sgTablet:tw-justify-center">
          <div
            v-if="currentSize !== 'mobile'"
            class="tw-pr-5"
          >
            <img
              class="tw-max-w-[100px] sgDesktop:tw-h-[416px] sgTablet:tw-h-[364px]"
              :src="$cdn(yushengDataArray[9].imageGroup)"
            >
          </div>
          <!-- Column 10 -->
          <div class="tw-flex tw-flex-col tw-flex-auto">
            <!-- Tablet and Desktop View -->
            <div
              v-if="currentSize !== 'mobile'"
              class="tw-flex tw-flex-row tw-px-4 tw-justify-start sgTablet:tw-px-0 sgTablet:tw-ml-[65px] sgDesktop:tw-ml-0"
            >
              <div class="parent tw-min-w-[240px] sgTablet:tw-w-[50%] sgDesktop:tw-w-[100%]">
                <p
                  class="child-text tw-text-[#C52600] tw-text-sg-dp1-mobile sgTablet:tw-text-sg-dp1-tablet sgTablet:-tw-ml-[7rem] sgDesktop:tw-text-sg-dp1 sgDesktop:-tw-ml-[4rem] sgDesktop:-tw-mt-[0rem]"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  10
                </p>
                <img
                  class="recipe child sgTablet:-tw-mt-[50px] sgTablet:tw-ml-[2.5rem] sgDesktop:tw-ml-[8rem]"
                  :src="$cdn(yushengDataArray[9].image)"
                >
                <div class="tw-flex tw-absolute tw-flex-col tw-top-[30px] tablet:tw-static tablet:tw- tablet:tw-flex-row desktop:tw-static desktop:tw-flex-row">
                  <div
                    class="child-outlined-text tw-flex tw-flex-col tw-items-center tw-text-sg-h1-mobile tw-left-0 sgTablet:tw-left-[4%] sgTablet:tw-text-sg-h1-tablet tablet:tw-top-[20px] sgTablet:-tw-ml-[5rem] sgTablet:tw-mt-[1rem] sgDesktop:tw-left-[24%] sgDesktop:tw-text-sg-h1 sgDesktop:tw-top-0 sgDesktop:-tw-ml-[1rem] sgDesktop:tw-mt-0"
                  >
                    <p
                      class="tw-font-notosansbold tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgTablet:tw-ml-[0rem] sgTablet:-tw-mt-[2rem] sgDesktop:tw-text-sg-h1 sgDesktop:tw-ml-[3rem] sgDesktop:tw-mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="350"
                        height="90"
                      >
                        <text
                          x="10"
                          y="75"
                          style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                        >
                          {{ yushengDataArray[9].chineseWord }}
                        </text>
                      </svg>
                    </p>
                    <p
                      class="tw-text-[#C52600] tw-pt-1 tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgTablet:-tw-ml-[2rem] sgDesktop:tw-text-sg-body-1 sgDesktop:tw-ml-[3rem]"
                      :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                    >
                      {{ yushengDataArray[9].chinesePronounce }}
                    </p>
                  </div>
                </div>
              </div>
              <img
                class="tw-pt-[36px]"
                :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                @click="onSpeakerClick(10)"
              >
            </div>
            <!-- Mobile View -->
            <div v-if="currentSize === 'mobile'">
              <div class="tw-relative tw-flex tw-flex-col -tw-mt-[2rem]">
                <p
                  class="tw-text-[#C52600] tw-text-sg-dp1-mobile tw-mt-[1rem]"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  10
                </p>
                <img
                  class="tw-absolute -tw-mt-[35px] tw-ml-[2.5rem] tw-w-[100px]"
                  :src="$cdn(yushengDataArray[9].image)"
                >
                <div class="tw-z-10 tw-flex tw-flex-row">
                  <div class="tw-flex tw-flex-col tw-min-w-[200px] tw-items-start tw-justify-center">
                    <div class="tw-flex tw-flex-col tw-items-center -tw-mt-[1rem]">
                      <p class="tw-font-notosansbold tw-text-sg-h1-mobile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="220"
                          height="65"
                        >
                          <text
                            x="10"
                            y="50"
                            style="fill: #f7dc33; stroke: #c52600; stroke-width: 4px; paint-order: stroke"
                          >
                            {{ yushengDataArray[9].chineseWord }}
                          </text>
                        </svg>
                      </p>
                      <!-- Pronounce Word -->
                      <p
                        class="tw-text-[#C52600] tw-text-sg-body-1-mobile"
                        :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                      >
                        {{ yushengDataArray[9].chinesePronounce }}
                      </p>
                    </div>
                  </div>
                  <img
                    class="tw-pt-[36px]"
                    :src="$cdn('images/cny2024/icon/speaker-icon.svg')"
                    @click="onSpeakerClick(10)"
                  >
                </div>
              </div>
            </div>
            <cny-24-yusheng-section-text
              :title="yushengDataArray[9].title"
              :title-chinese="yushengDataArray[9].titleChinese"
              :significance-paragraph="yushengDataArray[9].significanceParagraph"
            />
          </div>
        </div>
      </div>
      <!-- Huat Ah !! -->
      <div
        :id="getSectionId(11)"
        class="tw-h-full tw-w-full tw-bg-white tw-px-4 tw-pb-10 tablet:tw-px-20 tablet:tw-pb-10 desktop:tw-pb-10 desktop:tw-px-20"
      >
        <div class="tw-relative tw-flex tw-flex-row tw-justify-center -tw-mt-[24px]">
          <!-- Huat Ah Floating Text -->
          <p class="pink-comma tw-mr-4 tw-rotate-12 tw-text-sg-dp2-mobile tablet:tw-text-sg-dp2-tablet desktop:tw-text-sg-dp2">
            “
          </p>
          <p
            class="huat-ah tw-mr-4 -tw-rotate-[16deg] tw-text-sg-dp2-mobile tablet:tw-text-sg-dp2-tablet desktop:tw-text-sg-dp2 !tw-font-normal"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            HUAT
          </p>
          <p
            class="huat-ah tw-mr-4 tw-rotate-[16deg] tw-text-sg-dp2-mobile tablet:tw-text-sg-dp2-tablet desktop:tw-text-sg-dp2 !tw-font-normal"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            AH
          </p>
          <p class="pink-comma -tw-rotate-[8deg] tw-text-sg-dp2-mobile tablet:tw-text-sg-dp2-tablet desktop:tw-text-sg-dp2">
            ”
          </p>
        </div>
        <div class="tw-flex tw-justify-center tw-w-full">
          <img
            class="tw-max-h-[450px] tw-h-[200px] tw-object-cover tw-pb-4 tablet:tw-h-[300px] tablet:tw-pb-10 desktop:tw-pb-10 desktop:tw-min-h-[400px]"
            :src="$cdn('images/cny2024/yusheng-group-people.png')"
          >
        </div>
        <div class="tw-flex tw-flex-col tw-justify-center tablet:tw-flex-row desktop:tw-flex-row">
          <!-- Pro Tips -->
          <div class="tw-flex tw-flex-1 tw-flex-col tw-pb-4 tw-max-w-[390px]">
            <p
              class="tw-pb-4 tw-text-sg-sh3-mobile-cny tablet:tw-text-sg-sh3-tablet-cny desktop:tw-text-sg-sh3-cny"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_visiting_pro_tips') }}
            </p>
            <div
              class="tw-text-sg-body-1-mobile-cny tablet:tw-text-sg-body-1-tablet-cny desktop:tw-text-sg-body-1-cny yusheng-anchor-hover"
              :class="{
                'tw-font-akkurat': checkLang() == 'en',
                'tw-font-notosans': checkLang() == 'zh-hans',
              }"
              v-html="wordpressData.huat_ah_pro_tips"
            />
            <!-- Video on click
            <p
              v-if="checkLang()=='en'"
              class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6"
            >
              Watch <a
                class="tw-cursor-pointer tw-underline"
                href="https://www.youtube.com/watch?v=ijOfKpDnTqI&list=PLBV_6lyG3cCYcd9s81oK3DmCAR6Or3Scn&index=4"
                target="_blank"
              >this video</a> to get the full story about yusheng from Chef Benson and see if two youths can create their very own delicious yusheng from everyday ingredients!
            </p> -->
          </div>
          <div :class="[currentSize === 'mobile' ? '' : 'tw-w-[20px]']" />
          <!-- Significance -->
          <div class="tw-flex tw-flex-1 tw-flex-col tw-max-w-[390px]">
            <p
              class="tw-pb-4 tw-text-sg-sh3-mobile-cny tablet:tw-text-sg-sh3-tablet-cny desktop:tw-text-sg-sh3-cny"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_visiting_significance') }}
            </p>
            <div
              class="tw-text-sg-body-1-mobile-cny tablet:tw-text-sg-body-1-tablet-cny desktop:tw-text-sg-body-1-cny yusheng-anchor-hover"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
              v-html="wordpressData.huat_ah_significance"
            />
            <!-- <p
              v-if="checkLang()=='zh-hans'"
              class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6"
            >
              观看 <a
                class="tw-cursor-pointer tw-underline"
               href="https://www.youtube.com/watch?v=ijOfKpDnTqI&list=PLBV_6lyG3cCYcd9s81oK3DmCAR6Or3Scn&index=4"
               target="_blank"
              >此视频</a> 来听听厨师唐本陞和你分享鱼生的来源以及各个食材的含义，以及看看两位年轻人如何使用日常食材制作鱼生！
            </p> -->
            <!-- <p class="tw-text-sg-caption tw-pt-4 tablet:tw-pt-5 desktop:tw-pt-5">
              {{ $t('cny_visiting_disclaimer') }}
            </p> -->
          </div>
        </div>
      </div>
      <ap-footer />
    </div>
  </div>
</template>

<script lang="ts">
import { getScreenSize } from '../../support/helper';
import { onMounted, onBeforeUnmount, Ref, ref, computed, ComputedRef } from 'vue';
import { checkLang } from '../../support/helper';
import { useI18n } from 'vue-i18n';

interface YushengData {
  title: string;
  titleChinese: string;
  significanceParagraph: string;
  proTipsParagraph: string | null;
  chineseWord: string;
  chinesePronounce: string;
  image: string;
  imageGroup: string;
}

export default {
  props: {
    wordpressData: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const currentSize: Ref<string | null> = ref(getScreenSize());
    const audioPlayer = new Audio();

    const sectionId: string[] = [
      'raw-fish',
      'pomelo',
      'pepper',
      'oil',
      'carrot',
      'green-radish',
      'white-radish',
      'sesame-seed',
      'golden-cracker',
      'plum-sauce',
      'huat-ah',
    ];

    function handleResize() {
      currentSize.value = getScreenSize();
    }

    function getImageSrc(index: number): string {
      const imagePath = '/images/cny2024/yusheng/';
      switch (index) {
        case 1:
          return imagePath + 'raw-fish.png';
        case 2:
          return imagePath + 'pomelo.png';
        case 3:
          return imagePath + 'spices.png';
        case 4:
          return imagePath + 'oil.png';
        case 5:
          return imagePath + 'carrot.png';
        case 6:
          return imagePath + 'green-radish.png';
        case 7:
          return imagePath + 'white-radish.png';
        case 8:
          return imagePath + 'sesame-seed.png';
        case 9:
          return imagePath + 'cracker.png';
        case 10:
          return imagePath + 'plum-sauce.png';
        default:
          return '';
      }
    }

    function getImageGroupSrc(index: number): string {
      const imagePath = '/images/cny2024/yusheng/';
      switch (index) {
        case 1:
          return imagePath + 'raw-fish-group.png';
        case 2:
          return imagePath + 'pomelo-group.png';
        case 3:
          return imagePath + 'spices-group.png';
        case 4:
          return imagePath + 'oil-group.png';
        case 5:
          return imagePath + 'carrot-group.png';
        case 6:
          return imagePath + 'green-radish-group.png';
        case 7:
          return imagePath + 'white-radish-group.png';
        case 8:
          return imagePath + 'sesame-seed-group.png';
        case 9:
          return imagePath + 'cracker-group.png';
        case 10:
          return imagePath + 'plum-sauce-group.png';
        default:
          return '';
      }
    }

    function getSectionId(index: number): string {
      return sectionId[index - 1];
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      console.log('wordpressData', props.wordpressData);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    const visitingLongText = computed(() => t('cny_visiting_long_text'));
    const huatAhProTips = computed(() => t('cny_visiting_huat_ah_pro_tips'));
    const huatAhSignificance = computed(() => t('cny_visiting_huat_ah_significance'));

    const yushengDataArray: ComputedRef<YushengData[]> = computed(() => [
      {
        title: 'Raw Fish',
        titleChinese: '生鱼 (shēng yú)',
        significanceParagraph: t('cny_visiting_raw_fish_paragraph'),
        proTipsParagraph: null,
        chineseWord: '“年年有余”',
        chinesePronounce: '(nián nián yǒu yú)',
        image: getImageSrc(1),
        imageGroup: getImageGroupSrc(1),
      },
      {
        title: 'Pomelo',
        titleChinese: '柚子 (yòu zi)',
        significanceParagraph: t('cny_visiting_pomelo_paragraph'),
        proTipsParagraph: null,
        chineseWord: '“大吉大利”',
        chinesePronounce: '(dà jí dà lì)',
        image: getImageSrc(2),
        imageGroup: getImageGroupSrc(2),
      },
      {
        title: 'Pepper and \nFive Spice Powder',
        titleChinese: '胡椒粉和五香粉 (hú jiāo fěn hé wǔ xiāng fěn)',
        significanceParagraph: t('cny_visiting_pepper_paragraph'),
        proTipsParagraph: null,
        chineseWord: '“招财进宝”',
        chinesePronounce: '(zhāo cái jìn bǎo)',
        image: getImageSrc(3),
        imageGroup: getImageGroupSrc(3),
      },
      {
        title: 'Oil',
        titleChinese: '油 (yóu)',
        significanceParagraph: t('cny_visiting_oil_paragraph'),
        proTipsParagraph: t('cny_visiting_oil_pro_tips'),
        chineseWord: '“财源广进, 一本万利”',
        chinesePronounce: '(cái yuán) guǎng jìn, yī běn wàn lì)',
        image: getImageSrc(4),
        imageGroup: getImageGroupSrc(4),
      },
      {
        title: 'Carrots',
        titleChinese: '红萝卜 (hóng luó bo)',
        significanceParagraph: t('cny_visiting_carrots_paragraph'),
        proTipsParagraph: null,
        chineseWord: '“鸿运当头”',
        chinesePronounce: '(hóng yùn dāng tóu)',
        image: getImageSrc(5),
        imageGroup: getImageGroupSrc(5),
      },
      {
        title: 'Green Radish',
        titleChinese: '青萝卜 (qīng luó bo)',
        significanceParagraph: t('cny_visiting_green_radish_paragraph'),
        proTipsParagraph: t('cny_visiting_green_radish_pro_tips'),
        chineseWord: '“青春常驻”',
        chinesePronounce: '(qīng chūn cháng zhù)',
        image: getImageSrc(6),
        imageGroup: getImageGroupSrc(6),
      },
      {
        title: 'White Radish',
        titleChinese: '白萝卜(bái luó bo)',
        significanceParagraph: t('cny_visiting_white_radish_paragraph'),
        proTipsParagraph: null,
        chineseWord: '“步步高升, 风生水起”',
        chinesePronounce: '(bù bù gāo shēng, fēng shēng shuǐ qǐ)',
        image: getImageSrc(7),
        imageGroup: getImageGroupSrc(7),
      },
      {
        title: 'Sesame Seeds and Crushed Peanuts',
        titleChinese: '(jīn yín mǎn wū, shēng yì xīng lóng)',
        significanceParagraph: t('cny_visiting_sesame_seed_paragraph'),
        proTipsParagraph: null,
        chineseWord: '“金银满屋, 生意兴隆”',
        chinesePronounce: '(jīn yín mǎn wū, shēng yì xīng lóng)',
        image: getImageSrc(8),
        imageGroup: getImageGroupSrc(8),
      },
      {
        title: 'Golden Crackers',
        titleChinese: '薄脆饼干(báo cuì bǐng gān)',
        significanceParagraph: t('cny_visiting_golden_crackers_paragraph'),
        proTipsParagraph: null,
        chineseWord: '“满地黄金”',
        chinesePronounce: '(mǎn dì huáng jīn)',
        image: getImageSrc(9),
        imageGroup: getImageGroupSrc(9),
      },
      {
        title: 'Plum Sauce',
        titleChinese: '酸梅酱 (suān méi jiàng)',
        significanceParagraph: t('cny_visiting_plum_sauce_paragraph'),
        proTipsParagraph: null,
        chineseWord: '“甜甜蜜蜜”',
        chinesePronounce: '(tián tián mì mì)',
        image: getImageSrc(10),
        imageGroup: getImageGroupSrc(10),
      },
    ]);

    const menuItems = [
      {
        label: 'cny_visiting_raw_fish',
        id: 'raw-fish',
      },
      {
        label: 'cny_visiting_pomelo',
        id: 'pomelo',
      },
      {
        label: 'cny_visiting_pepper',
        id: 'pepper',
      },
      {
        label: 'cny_visiting_oil',
        id: 'oil',
      },
      {
        label: 'cny_visiting_carrots',
        id: 'carrot',
      },
      {
        label: 'cny_visiting_green_radish',
        id: 'green-radish',
      },
      {
        label: 'cny_visiting_white_radish',
        id: 'white-radish',
      },
      {
        label: 'cny_visiting_sesame_seed',
        id: 'sesame-seed',
      },
      {
        label: 'cny_visiting_golden_crackers',
        id: 'golden-cracker',
      },
      {
        label: 'cny_visiting_plum_sauce',
        id: 'plum-sauce',
      },
      {
        label: 'cny_visiting_huat_ah',
        id: 'huat-ah',
      },
    ];

    return {
      currentSize,
      audioPlayer,
      getSectionId,
      checkLang,

      yushengDataArray,

      visitingLongText,
      huatAhProTips,
      huatAhSignificance,
      menuItems,
    };
  },
  computed: {
    formattedText(): string {
      const className = checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold';
      const paragraphs = this.visitingLongText.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-max-w-[800px] ${className}">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6 tw-max-w-[800px] ${className}">${paragraph}</p>`;
          }
        })
        .join('');
    },
    formattedHuatAhText(): string {
      const paragraphs: string[] = this.huatAhProTips.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6">${paragraph}</p>`;
          }
        })
        .join('');
    },
    formattedHuatAhSignificanceText(): string {
      const paragraphs: string[] = this.huatAhSignificance.split('\n\n');
      return paragraphs
        .map((paragraph, index) => {
          if (index === 0) {
            return `<p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1">${paragraph}</p>`;
          } else {
            return `<p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-6">${paragraph}</p>`;
          }
        })
        .join('');
    },
  },
  methods: {
    onSpeakerClick(index: number) {
      this.audioPlayer.pause();
      this.audioPlayer.src = this.$cdn(`/sound/cny/lohei${index}.m4a`);
      this.audioPlayer.play();
    },
  },
};
</script>

<style scoped>
.header-image {
  width: 100%;
  object-fit: cover;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

img {
  cursor: pointer;
}

img.recipe {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
}

.parent {
  position: relative;
  height: 100%;
}

.child {
  position: absolute;
  top: 0;
}

.child-text {
  position: absolute;
  top: -60%;
  left: 3.5rem;
}

.child-text-custom-left {
  position: absolute;
  top: -60%;
}

.child-outlined-text {
  position: absolute;
}

.outlined-text {
  color: #f7dc33; /* Set the fill color of the text */
  -webkit-text-stroke: 2px #c52600; /* Set the outline color and width for WebKit browsers */
  stroke: 2px #c52600; /* Set the outline color and width for other browsers */
  min-width: 240px;
}

.outlined-text-mobile {
  color: #f7dc33; /* Set the fill color of the text */
  -webkit-text-stroke: 2px #c52600; /* Set the outline color and width for WebKit browsers */
  stroke: 2px #c52600; /* Set the outline color and width for other browsers */
}

.pink-comma {
  color: #ff00a2;
}

.huat-ah {
  color: #c52600;
}
</style>
