<template>
  <!-- TODO: Fix the reverse thing in mobile view -->
  <div>
    <div
      :id="customId"
      class="tw-flex tw-flex-col sgTablet2:tw-flex-row sgTablet2:tw-h-[632px] sgDesktop:tw-h-[800px]"
    >
      <!-- Image -->
      <div :class="['tw-max-h-[100%] sgTablet2:tw-w-[60%]', isImageLeft ? 'sgTablet2:tw-order-1' : 'sgTablet2:tw-order-2']">
        <img
          class="tw-w-full tw-h-[332px] sgTablet:tw-h-[440px] sgTablet2:tw-h-full tw-object-cover"
          :src="imageAsset"
        >
      </div>
      <!-- Title, Description, Button -->
      <div
        :class="[
          'tw-flex tw-flex-col tw-py-10 tw-px-10 sgTablet:tw-py-10 sgDesktop:tw-py-10 sgTablet2:tw-max-w-[40%] sgTablet2:tw-justify-center sgTablet2:tw-items-start',
          isImageLeft
            ? 'sgTablet:tw-px-10 sgTablet:tw-py-10  sgTablet2:tw-px-4 sgDesktop:tw-pl-10 sgDesktop:tw-pr-20'
            : 'sgTablet:tw-px-10 sgTablet:tw-py-10 sgTablet2:tw-px-4 sgDesktop:tw-pr-10 sgDesktop:tw-pl-20',
          isImageLeft ? 'sgTablet2:tw-order-2' : 'sgTablet2:tw-order-1',
        ]"
      >
        <p
          class="tw-text-sg-h3-mobile-cny-title sgTablet:tw-text-sg-h3-tablet-cny-title sgDesktop:tw-text-sg-h3-cny-title sgDesktop:tw-pb-10"
          :class="{
            'tw-break-keep tw-font-akkuratbold': checkLang() == 'en',
            'tw-break-keep tw-font-notosansbold': checkLang() == 'zh-hans',
          }"
        >
          {{ title }}
        </p>
        <div
          class="tw-text-sg-body-1-mobile-cny tw-pb-10 sgTablet:tw-text-sg-body-1-tablet-cny sgDesktop:tw-text-sg-body-1-cny tw-pt-5"
          :class="{
            'tw-break-keep tw-font-akkurat': checkLang() == 'en',
            'tw-break-keep tw-font-notosans': checkLang() == 'zh-hans',
          }"
          v-html="formattedText"
        />
        <!-- <p
          class="tw-text-sg-body-1-mobile tw-pb-10
      sgTablet:tw-text-sg-body-1-tablet
      sgDesktop:tw-text-sg-body-1"
        >
          {{ description }}
        </p> -->
        <a
          class="tw-text-sm tw-py-2 tw-px-6 tw-leading-[0.7px] tw-bg-[#c52600] tw-text-white tw-inline-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-[26px] tw-h-[52px] tw-w-[180px] tw-text-center tw-no-underline"
          :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          :href="externalLink"
          :target="external ? '_blank' : '_self'"
        >
          {{ $t('cny_discover_more') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { checkLang } from '../../support/helper';
export default {
  props: {
    isImageLeft: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Origin Stories',
    },
    description: {
      type: String,
      default:
        'You’ll find that everything we do during Chinese New Year has certain significance. Here are the cool origin stories and cultural contexts behind the CNY traditions we’re all so familiar with!',
    },
    imageAsset: {
      type: String,
      default: '',
    },
    customId: {
      type: String,
      default: '',
    },
    externalLink: {
      type: String,
      default: '',
    },
    external: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    return {
      checkLang,
    };
  },
  computed: {
    formattedText(): string {
      const paragraphs = this.description.split('\n\n');
      return paragraphs.map((paragraph) => `<p class="">${paragraph}</p>`).join('');
    },
  },
};
</script>
