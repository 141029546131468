<template>
  <div>
    <ap-top-nav-bar
      class="tw-z-50"
      :is-transparent="true"
      :is-hidden="false"
    />
    <div class="tw-relative">

      <div class="tw-h-full">

        <!-- Header -->
        <div
          ref="header"
          class="headerCustom"
        >
          <!-- Inner -->
          <div
            ref="inner"
            class="inner"
          />
        </div>
      </div>
      <div
        class="tw-absolute tw-flex tw-flex-col tw-inset-0 tw-justify-center tw-items-start tw-max-h-[100vh] tw-max-w-[100vw] tw-px-4 tablet:tw-px-10 desktop:tw-px-20"
      >
        <div
          id="remixMainTitle"
          :class="[
            'tw-max-w-[300px] tw-w-full md:tw-max-w-[640px]',
            'tw-text-white tw-text-sg-remix-mobile-header sgTablet:tw-text-sg-remix-tablet-header sgDesktop:tw-text-sg-remix-desktop-header',
            'tw-flex tw-flex-row tw-justify-start tw-mb-2 tw-whitesparemix-pre-line',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
          v-html="$t('remix_main_title')"
        />

      </div>
      <div
        id="section-id2"
        ref="section2"
      >
        <div>

          <remix-videos
            v-show="props.wordpressData.remix_about_video_url !== ''"
            id="video-section"
            class="tw-h-[100vh]"
            :video-url="props.wordpressData.remix_about_video_url"
            :poster-url="getImageURL(props.wordpressData.remix_about_video_thumbnail)"
          />

          <!-- Section 1 -->
          <remix-text-layout
            :title="wordpressData.remix_about_paragraph_title_1"
            :text="wordpressData.remix_about_paragraph_1"
            div-id="div-section-1"
          />
          <!-- Section 2 -->
          <remix-text-layout
            :title="wordpressData.remix_about_paragraph_title_2"
            :text="wordpressData.remix_about_paragraph_2"
            div-id="div-section-2"
          />

        </div>

      </div>
      <!-- Events Swiper -->
      <div class=" tw-bg-white tw-relative">
        <section
          :class="[
            'tw-py-5 sgTablet:tw-py-10',
          ]"
        >
          <h2
            :class="[
              'tw-text-center tw-uppercase',
              'tw-text-sg-remix-mobile-h2 tablet:tw-text-sg-remix-tablet-h2 desktop:tw-text-sg-remix-desktop-h2',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('remix_about_subheader_1') }}
          </h2>
          <remix-swiper />
        </section>
        <section
          :class="[
            'tw-flex tw-flex-col tw-gap-5 sgTablet:tw-gap-10',
            'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20',
            'tw-pt-5 sgTablet:tw-pt-10',
            'tw-pb-[104px] sgTablet2:tw-pb-[144px] ',
          ]"
        >
          <h2
            v-show="props.wordpressData.remix_about_merchandise_giveaway_image !== 'false' && props.wordpressData.remix_about_merchandise_giveaway_image !== 'null' "
            :class="[
              'tw-text-center tw-uppercase',
              'tw-text-sg-remix-mobile-h2 tablet:tw-text-sg-remix-tablet-h2 desktop:tw-text-sg-remix-desktop-h2',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('remix_about_subheader_2') }}
          </h2>
          <div
            v-show="props.wordpressData.remix_about_merchandise_giveaway_image !== 'false' && props.wordpressData.remix_about_merchandise_giveaway_image !== 'null' "
          >
            <div class="tw-flex tw-justify-center">
              <div
                :class="[
                  'merchandiseImage tw-aspect-video tw-bg-cover tw-relative',
                  'tw-w-full'
                ]"
                :style="{backgroundImage: `url(${getImageURL(wordpressData.remix_about_merchandise_giveaway_image)})`}"
              >
                <!-- enlarge button -->
                <div
                  class="enlargeBtn tw-w-11 tw-h-11 tw-rounded-lg tw-bg-[#48484880] tw-absolute tw-right-0 tw-mr-5 tw-mt-5 tw-p-2 tw-cursor-pointer tw-z-10"
                  @click="openImageModal()"
                >
                  <img
                    class="tw-w-7 tw-h-7 "
                    :src="$cdn('/images/experience/ce/open_in_full_icon.png')"
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Merchandice Giveaway Description -->
          <div class="tw-flex tw-justify-center">
            <div class="tablet:tw-max-w-[800px] tw-text-center">
              <p
                :class="[
                  'tw-text-sg-remix-body tw-text-[#484848]'
                ]"
              >
                {{ wordpressData.remix_about_merchandise_giveaway_description }}
              </p>
            </div>
          </div>
          <!-- Social Buttons -->
          <div class="tw-flex tw-justify-center">
            <remix-social-btn />
          </div>
        </section>

      </div>
      <remix-navigation-bar :can-view="wordpressData.canView" />

    </div>
    <remix-partner-section class="tw-hidden" />
    <ap-footer
      id="exhibition-footer"
      class=" tw-bg-white tw-relative"
    />
    <remix-image-modal
      :open-modal="openModal"
      :image-src="getImageURL(wordpressData.remix_about_merchandise_giveaway_image)"
      @close-modal="closeModal"
    />

    <ap-exhibition-notice-modal
      v-model="exhibitionNoticeModalOpen"
      :show-primary-button="false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, onBeforeUnmount } from 'vue';
import { checkLang } from '../../../support/helper';


let openModal = ref(false);

const props = defineProps({
  wordpressData: {
    type: Object,
    default: null,
  },
  reinitGsap: {
    type: Function,
    default: undefined,
  }
});

const headerImage = ref({});
const headerGradient = ref('linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%)');
const inner: Ref<HTMLElement | null> = ref(null);

const exhibitionNoticeModalOpen = ref(false);

const handleScroll = () => {
  if (inner.value) {
    const delayedOffsetY = Math.max(0, window.pageYOffset);
    let value = delayedOffsetY / 8000 + 1;
    inner.value.style.transform = `scale(${value})`;
    let newGradient = ((value - 1) / (1.2 - 1)) * (0.75 - 0.5) + 0.5;
    let newGradient2 = ((value - 1) / 0.2) * 0.75;

    if (newGradient > 0.75) {
      newGradient = 0.75;
    }

    if (newGradient2 > 0.75) {
      newGradient2 = 0.75;
    }

    const gradient = 'linear-gradient(180deg, rgba(0, 0, 0, ' + newGradient2 + ') 0%, rgba(0, 0, 0, ' + newGradient + ') 100%)';
    inner.value.style.backgroundImage = `${gradient}, url(${headerImage.value.url})`;
  }
};

function handleOnResize() {
  if (window.innerWidth <= 1024) {
    headerImage.value = JSON.parse(props.wordpressData.remix_about_header_image_mobile);
  }else{
    headerImage.value = JSON.parse(props.wordpressData.remix_about_header_image);
  }
  inner.value.style.backgroundImage = `${headerGradient.value}, url(${headerImage.value.url})`;
}


onMounted(async () => {
  if (inner.value) {
    handleOnResize();
  }
  window.addEventListener('resize', handleOnResize);
  window.addEventListener('scroll', handleScroll);

  //workaround for ios tablet issue on rendering text shadow
  if(window.innerWidth >= 728 && window.innerWidth < 1280) {
    const remixMainTitle = document.getElementById('remixMainTitle');
    if (remixMainTitle) {
      remixMainTitle.style.textShadow = '-4px 4px 50px rgba(0, 0, 0, 0.3)';
    }
  }

  const canView = !props.wordpressData?.canView?.includes('dialog.notice.exhibition');
  exhibitionNoticeModalOpen.value = canView;
});


onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', handleOnResize);
});

function getImageURL(obj: string) {

  if (obj === 'false' || obj === 'null') {
    return '';
  }

  try {
    return JSON.parse(obj).url;
  } catch (e) {
    return '';
  }

  const imgObj = JSON.parse(obj);
  return imgObj.url;
}

function openImageModal(){
  openModal.value = true;
}

function closeModal(){
  openModal.value = false;
}
</script>

<style scoped>
#remixMainTitle{
  text-shadow:  -4px 4px 10px rgba(0, 0, 0, 0.3);

}
.headerCustom {
  position: relative;
  width: 100%;
  height: 100lvh;
  overflow: hidden;
}
.inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.merchandiseImage{
  box-shadow: 0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A;

}

.enlargeBtn:hover{
  background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 768px) {
  .inner {
    max-height: 100lvh;
  }
}

@media screen and (max-width: 500px) {
  .inner {
    background-position: center center;
  }
}
</style>
