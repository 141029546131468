<template>
  <div
    class="tw-w-full"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <swiper
      id="ugc-section-swiper"
      :slides-per-view="6"
      :space-between="20"
      :modules="[FreeMode,Mousewheel,Autoplay]"
      :free-mode="{
        enabled:true,
        momentum:false,
      }"
      :autoplay="{
        disableOnInteraction:false,
        pauseOnMouseEnter:false,
        delay:1,
        waitForTransition:true,
      }"
      :speed="5000"
      loop
      @swiper="onSwiper"
    >

      <swiper-slide
        v-for="(i, index) in data"
        :key="index"
        class="tw-flex tw-flex-col first:tw-ml-4 sgTablet:first:tw-ml-10 sgDesktop:first:tw-ml-20 last:tw-mr-4 sgTablet:last:tw-mr-10 sgDesktop:last:tw-mr-20 tablet:tw-min-w-[260px] tw-min-w-[168px]  tw-my-8"
      >
        <img
          :key="index+'i'"
          :src="i.image"
          class="tw-h-[168px] tablet:tw-h-[260px] tw-w-[168px] tablet:tw-w-[260px] tw-object-cover"
        >
        <a
          v-if="!!i?.username && i?.username_link"
          :key="index+'a'"
          class="tw-mt-2 tablet:tw-max-w-[260px]  tw-w-fit tw-line-clamp-1 tw-max-w-[168px] tw-text-sg-body-mobile tablet:tw-text-sg-body-tablet desktop:tw-text-sg-body"
          :class="{'tw-cursor-pointer hover:tw-underline': !!i?.username_link}"
          :href="i?.username_link"
          target="_blank"
        >
          {{ `@${i?.username}` }}
        </a>
        <p
          v-else-if="!!i?.username"
          class="tw-mt-2 tablet:tw-max-w-[260px]  tw-w-fit tw-line-clamp-1 tw-max-w-[168px] tw-text-sg-body-mobile tablet:tw-text-sg-body-tablet desktop:tw-text-sg-body"
        >
          {{ `@${i?.username}` }}
        </p>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup lang="ts">
// Import Swiper Vue.js components
import type {Swiper as SwiperInstance} from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import {FreeMode,Mousewheel,Autoplay} from 'swiper/modules';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { onMounted, toRefs, ref, Ref } from 'vue';
const {t} = useI18n();

const props = withDefaults(defineProps<{
  social_media_images: any| null
}>(), {
  social_media_images: null
});

onMounted(() => {
  console.log('check abc', props.social_media_images);
});

const swiperInstance = ref<SwiperInstance>();
const onSwiper = (swiper:any) => {
  swiperInstance.value = swiper;
};
const onMouseEnter = () => {
  swiperInstance.value?.autoplay.stop();
};
const onMouseLeave = () => {
  swiperInstance.value?.autoplay.start();
};

const data = computed(() => {
  // Loop to 12 slides
  if(!props.social_media_images?.length > 0) return [];

  const neededCount = props.social_media_images.length > 12 ?  props.social_media_images.length : 12;

  const copyCount = Math.floor(neededCount / props.social_media_images.length);
  return Array.from({length:copyCount}).flatMap(()=> props.social_media_images);

});
</script>
<style lang="scss">
 #ugc-section-swiper .swiper-wrapper .swiper-slide{
  @apply first:tw-ml-4 last:tw-mr-4 sgTablet:first:tw-ml-8 sgTablet:last:tw-mr-8 sgDesktop:first:tw-ml-20 sgDesktop:last:tw-mr-20;
}

#ugc-section-swiper .swiper-wrapper{
    @apply tw-ease-linear;
  }
</style>
