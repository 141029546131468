<script lang="ts" setup>
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();
type Post={
  title:string,
  description:string,
  image:string,
  external_link:string,
  is_past?: boolean
}

const festive_post = ref<Post[]>([]);
const other_programme_post = ref<Post[]>([]);
const festive_description = ref('');
const other_programme_description = ref('');

const modalKey = ref(Date.now());

const exhibitionNoticeModalOpen = ref(false);

onMounted(() => {
  if(props.wordpressData?.festive_post){
    festive_post.value = props.wordpressData.festive_post.map((item:any)=>{
      return {
        ...item,
        image:item.image ? item.image : cdn('images/cny2024/whats-open/card_placeholder.png'),
      };
    });
  }
  if(props.wordpressData?.other_programme_post){
    other_programme_post.value = props.wordpressData.other_programme_post.map((item:any)=>{
      return {
        ...item,
        image:item.image ? item.image : cdn('images/cny2024/whats-open/card_placeholder.png'),
      };
    });
  }
  if(props.wordpressData?.festive_description){
    festive_description.value = props.wordpressData.festive_description;
  }
  if(props.wordpressData?.other_programme_description){
    other_programme_description.value = props.wordpressData.other_programme_description;
  }

  const canView = !props.wordpressData?.canView?.includes('dialog.notice.exhibition');
  exhibitionNoticeModalOpen.value = canView;
});

const modal = ref<{show:boolean,data:Post}>({
  show:false,
  data:{} as Post
});
const handleOpenModal = (item:Post)=>{
  modal.value.show = true;
  modal.value.data = item;
  modalKey.value = Date.now();
};

const menuItems = [
  {
    label: 'ma_whats_open_menu_back_in_the_festive_mood',
    id: 'ma_whats_open_menu_back_in_the_festive_mood',
  },
  {
    label: 'ma_whats_open_menu_other_exciting_programmes',
    id: 'ma_whats_open_menu_other_exciting_programmes',
  },
];


</script>

<template>
  <div :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ma-navigation="true"
      :ma2-navigation="wordpressData?.canView?.includes('sidebar.ma2')"
    />
    <ma-secondary-navigation
      :menu-items-data="menuItems"
    />


    <section>
      <div class="tw-relative">
        <picture>
          <img
            :src="$cdn('images/ma2024/ma_sub_banner.jpg')"
            :class="[
              'tw-w-full',
              'tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]',
              'tw-object-cover'
            ]"
          >
        </picture>
        <div class="tw-bg-black/20 tw-absolute tw-w-full tw-h-full tw-top-0" />
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-ma-mobile-h1 sgTablet:tw-text-sg-ma-tablet-h1 sgDesktop:tw-text-sg-ma-desktop-h1 tw-pb-1"
              :class="[
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              {{ $t('ma_whats_open_title') }}
            </div>
            <div
              :class="[
                'tw-text-sg-ma-sh1 tw-mt-1',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              {{ $t('ma_whats_open_subtitle') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Bask in the festive mood -->
    <section
      :class="[
        'tw-relative tw-flex tw-flex-col tw-items-center',
        'tw-pt-10',
        'tw-pb-10 sgTablet:tw-pb-[60px]',
        'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20',
        'tw-gap-8'
      ]"
    >

      <div class="tw-flex tw-items-center tw-justify-center tw-gap-3">
        <img
          class="tw-w-[120px] tw-hidden sgTablet:tw-block"
          :src="$cdn('images/ma2024/whats-open/ma_whats_open_icon_01.png')"
        >
        <div
          id="ma_whats_open_menu_back_in_the_festive_mood"
          :class="[
            'tw-w-[calc(100vw-32px-120px)] sgTablet:tw-w-[calc(100vw-80px-160px)] sgDesktop:tw-w-[calc(100vw-160px-160px)] sgDesktop2:tw-w-[800px] tw-text-center',
          ]"
        >
          <h1
            :class="[
              'tw-text-sg-ma24-purple-b40 tw-text-sg-ma-mobile-h1 sgTablet:tw-text-sg-ma-tablet-h1 sgDesktop:tw-text-sg-ma-desktop-h1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_whats_open_section_1_title') }}
          </h1>
          <div
            :class="[
              'tw-text-sg-ma24-purple-b40 tw-mt-5 sgTablet:tw-mt-5 tw-break-words',
              'tw-text-sg-ma-sh3',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
            v-html="festive_description"
          />
        </div>
        <img
          class="tw-w-[120px] tw-hidden sgTablet:tw-block"
          :src="$cdn('images/ma2024/whats-open/ma_whats_open_icon_01.png')"
        >
      </div>
      <div
        :class="[
          'tw-flex tw-justify-center sgTablet2:tw-max-w-[800px] tw-max-w-[600px] tw-w-full',
          'tw-flex-wrap tw-gap-3 sgTablet:tw-gap-4 sgDesktop:tw-gap-5',
        ]"
      >
        <div
          v-for="(item,index) in festive_post"
          :key="index"
        >
          <ma-program-card
            :image="item.image"
            :label="item.title"
            :past-event="item.is_past"
            @open-modal="handleOpenModal(item)"
          />
        </div>
      </div>


    </section>

    <!-- Other exciting programmes -->
    <section
      :class="[
        'tw-relative tw-bg-sg-ma24-orange-w60',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        'sgDesktop2:tw-pt-[84px] sgDesktop:tw-pt-[72px] sgTablet2:tw-pt-[74px] sgTablet:tw-pt-[62px] tw-pt-[52px]',
        'tw-pb-10 sgTablet:tw-pb-[60px]',
        'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-20',
        'tw-gap-8',
      ]"
    >
      <div class="tw-flex tw-items-center tw-justify-center tw-gap-3">
        <img
          class="tw-w-[120px] tw-hidden sgTablet:tw-block"
          :src="$cdn('images/ma2024/whats-open/ma_whats_open_icon_02.png')"
        >
        <div
          id="ma_whats_open_menu_other_exciting_programmes"
          :class="[
            'tw-max-w-[340px] sgTablet2:tw-max-w-[800px] tw-w-full tw-text-center tw-text-white'
          ]"
        >
          <h1
            :class="[
              'tw-text-sg-ma24-purple-b40',
              'tw-text-sg-ma-mobile-h1 sgTablet:tw-text-sg-ma-tablet-h1 sgDesktop:tw-text-sg-ma-desktop-h1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_whats_open_section_2_title') }}
          </h1>
          <div
            :class="[
              'tw-text-sg-ma24-purple-b40 tw-mt-5 tw-break-words',
              'tw-text-sg-ma-sh3',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
            v-html="other_programme_description"
          />
        </div>
        <img
          class="tw-w-[120px] tw-hidden sgTablet:tw-block"
          :src="$cdn('images/ma2024/whats-open/ma_whats_open_icon_02.png')"
        >
      </div>
      <div
        :class="[
          'tw-flex tw-justify-center sgTablet2:tw-max-w-[800px] tw-max-w-[600px] tw-w-full',
          'tw-flex-wrap tw-gap-3 sgTablet:tw-gap-4 sgDesktop:tw-gap-5',
        ]"
      >
        <div
          v-for="(item,index) in other_programme_post"
          :key="index"
        >
          <ma-program-card
            :image="item.image"
            :label="item.title"
            :past-event="item.is_past"
            @open-modal="handleOpenModal(item)"
          />

        </div>
      </div>

      <div class="tw-absolute tw-top-0 tw-w-full">
        <ma-wave-break-line
          image="images/ma2024/whats-open/ma_line_break.svg"
          direction=""
        />
      </div>

    </section>

    <ma-whats-open-modal
      :key="modalKey"
      v-model="modal.show"
      :data="modal.data"
    />

    <ap-exhibition-notice-modal
      v-model="exhibitionNoticeModalOpen"
      :show-primary-button="false"
    />
    <ap-footer />
  </div>
</template>
