import he from 'he';

export const getScreenSize = () => {
  const width = window.innerWidth;
  if (width < 480) {
    return 'mobile';
  } else if (width >= 480 && width <= 768) {
    return 'tablet';
  } else if (width > 768) {
    return 'desktop';
  }

  return null;
};


export const getSgScreenSize = () => {
  const width = window.innerWidth;
  if (width < 680) {
    return 'mobile';
  } else if (width >= 680 && width <= 1024) {
    return 'tablet';
  } else {
    return 'desktop';
  }

  return null;
};

export const removeHtmlString = (htmlString: string) => {
  const unescapedHtml = he.decode(htmlString);
  const plainText = unescapedHtml.replace(/<[^>]*>/g, '');

  return plainText;
};

export const arraysAreEqual = (array1: Array<any>, array2: Array<any>) =>  {
  return JSON.stringify(array1) === JSON.stringify(array2);
};

//https://stackoverflow.com/a/49448231
 type  BROWSER_ENUM =
  'EDGE' |
  'INTERNET_EXPLORER' |
  'FIRE_FOX' |
  'OPERA' |
  'UC_BROWSER' |
  'SAMSUNG_BROWSER' |
  'CHROME' |
  'SAFARI' |
  'UNKNOWN' ;


const testUserAgent = (regexp: RegExp): boolean => regexp.test(window.navigator.userAgent);

export function detectBrowser(): BROWSER_ENUM {
  switch (true) {
    case testUserAgent(/edg/i): return 'EDGE';
    case testUserAgent(/trident/i): return 'INTERNET_EXPLORER';
    case testUserAgent(/firefox|fxios/i): return 'FIRE_FOX';
    case testUserAgent(/opr\//i): return 'OPERA';
    case testUserAgent(/ucbrowser/i): return 'UC_BROWSER';
    case testUserAgent(/samsungbrowser/i): return 'SAMSUNG_BROWSER';
    case testUserAgent(/chrome|chromium|crios/i): return 'CHROME';
    case testUserAgent(/safari/i): return 'SAFARI';
    default: return 'UNKNOWN';
  }
}

export const checkLang = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  return langParam == 'zh-hans' ? 'zh-hans' : 'en';
};
