<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { ref } from 'vue';
import {useI18n} from 'vue-i18n';
import { checkLang } from '../../support/helper';

const props = defineProps<{
  wordpressData:any
}>();

const {t} = useI18n();
const imageSourceOpen     = ref<boolean>(false);
const imageSourcesData    = ref('');
const paperOfferingsItem  = ref([]);
const taboosDescription   = ref('');
const description         = ref('');
const openFireSafetyModal = ref(false);

const taboosImages = ref([
  { image: '/images/zy2024/zhongyuan-101/taboos/zy24_taboos_slider_01.v2.jpg' },
  { image: '/images/zy2024/zhongyuan-101/taboos/zy24_taboos_slider_02.v2.jpg' },
  { image: '/images/zy2024/zhongyuan-101/taboos/zy24_taboos_slider_03.v2.jpg' },
  { image: '/images/zy2024/zhongyuan-101/taboos/zy24_taboos_slider_04.v2.jpg' },
  { image: '/images/zy2024/zhongyuan-101/taboos/zy24_taboos_slider_05.v2.jpg' },
]);


const menuItems = [
  {
    label: 'zy_zhongyuan_101_menu_observation',
    id   : 'observation-section'
  },
  {
    label: 'zy_zhongyuan_101_menu_activities',
    id   : 'activities-section'
  },
  {
    label: 'zy_zhongyuan_101_menu_getai',
    id   : 'getai-section'
  },
  {
    label: 'zy_zhongyuan_101_menu_paper_offerings',
    id   : 'paper-offerings-section'
  },
  {
    label: 'zy_zhongyuan_101_menu_fire_safety_tips',
    id   : 'fire-safety-tips-section'
  },
  {
    label: 'zy_zhongyuan_101_menu_taboos',
    id   : 'taboos-section'
  },
];

// const handleOpenImageSourceModal = () => {
//   imageSourceOpen.value = !imageSourceOpen.value;
// };

onBeforeMount(()=>{
  if (props.wordpressData?.description) {
    description.value = props.wordpressData.description;
  }
  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
  if(props.wordpressData?.paper_offerings?.length > 0) {
    paperOfferingsItem.value = props.wordpressData.paper_offerings;
  }
  if(props.wordpressData?.taboos_description) {
    taboosDescription.value = props.wordpressData.taboos_description;
  }else{
    taboosDescription.value = t('zy_zhongyuan_101_taboo_description');
  }
});

onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };

      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');
  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});
</script>

<template>
  <div
    class="tw-overflow-hidden"
    :class="{
      'tw-font-notosans': checkLang() != 'en',
    }"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :zy-navigation="true"
    />
    <zy-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- header -->
    <section class="tw-relative tw-tracking-wider">
      <picture>
        <img
          :src="$cdn('images/zy2024/banner.scaled.jpg')"
          class="tw-w-full tw-object-cover tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
        <div class="tw-text-white tw-text-center">
          <div
            class="tw-text-sg-h1-mobile sgTablet:tw-text-sg-h1-tablet sgDesktop:tw-text-sg-h1 tw-pb-1"
          >
            {{ $t('zy_zhongyuan_101_main_title') }}
          </div>
        </div>
      </div>
      <zy-paper-tear />
    </section>
    <!-- Observation section -->
    <section
      id="observation-section"
      class="
          tw-flex
          tw-gap-8
          tw-justify-center

          tw-bg-sg-zy24-orange

          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10

          tw-pt-5
          tw-pb-10
          sgTablet:tw-pt-[80px]
          sgTablet:tw-pb-[60px]

          tw-tracking-wider"
    >
      <!-- left side -->
      <div
        class="tw-relative tw-w-[140px] tw-h-auto tw-hidden sgTablet:tw-flex tw-flex-col tw-justify-between"
      >
        <img
          :src="$cdn('images/zy2024/orange.png')"
        >
        <img
          :src="$cdn('images/zy2024/brick.png')"
        >
      </div>

      <!-- center -->
      <div
        class="tw-flex tw-w-[600px] tw-flex-col tw-items-start"
      >
        <!-- title -->
        <div
          class="tw-w-full tw-text-center tw-text-sg-ref-mobile-h2 sgTablet:tw-text-sg-ref-desktop-h2 tw-pb-[24px]"
        >
          {{ $t('zy_zhongyuan_101_observation_title') }}
        </div>
        <div
          id="sup-handler-container"
          v-html="description"
        />
        <!-- description -->
        <!-- <div class="tw-flex-col">
          <div class="tw-text-sg-ref-desktop-body tw-pb-[24px]">
            {{ $t('zy_zhongyuan_101_observation_description_1') }}
          </div>
          <div class="tw-text-sg-ref-desktop-body tw-pb-[24px]">
            {{ $t('zy_zhongyuan_101_observation_description_2') }}
          </div>
          <div class="tw-text-sg-ref-desktop-body tw-pb-[24px]">
            {{ $t('zy_zhongyuan_101_observation_description_3') }}
          </div>
          <div class="tw-text-sg-ref-desktop-body">
            {{ $t('zy_zhongyuan_101_observation_description_4') }}
          </div>
        </div> -->
      </div>

      <!-- right side -->
      <div class="tw-relative tw-w-[140px] tw-h-auto tw-hidden sgTablet:tw-flex tw-flex-col tw-justify-center">
        <img
          :src="$cdn('images/zy2024/candle.png')"
        >
      </div>
    </section>

    <section class="tw-relative">
      <!-- bottom image  -->
      <img
        class="tw-h-[200px] sgTablet:tw-h-[400px] sgDesktop:tw-h-[600px] tw-object-cover tw-w-full"
        :src="$cdn('images/zy2024/zhongyuan-101/intro/dragon-candle-v2.jpeg')"
      >
      <img
        class="tw-absolute tw-w-full tw-object-cover tw-z-10 tw-h-[80px] "
        :style="{ bottom: '0', transform: 'translateY(40px)', filter: 'drop-shadow(4px 4px 2px rgba(0, 0, 0, 0.3))' }"
        :src="$cdn('images/zy2024/paper-tear.png')"
      >
    </section>

    <!-- activities section -->
    <section
      id="activities-section"
      class="
          tw-flex
          tw-flex-col
          tw-items-center
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          tw-py-10
          sgDesktop:tw-py-[80px]
          sgTablet:tw-py-[60px]
          tw-relative
          tw-tracking-wider
        "
    >

      <!-- header -->
      <div class="tw-relative">
        <div class="tw-flex tw-gap-5 tw-justify-center tw-items-center">
          <!--  -->
          <div class="tw-flex-col tw-text-[#FF7748] tw-text-sg-ref-mobile-h2 sgTablet:tw-text-sg-ref-desktop-h2">
            <div
              class="tw-w-full tw-text-right"
            >
              {{ $t('zy_zhongyuan_101_activities_observing') }}
            </div>
            <div
              class="tw-w-full tw-text-right"
            >
              {{ $t('zy_zhongyuan_101_activities_zhongyuan') }}
            </div>
          </div>
          <img
            v-if="checkLang() == 'en'"
            class="tw-w-[100px] tw-h-[100px] sgTablet:tw-w-auto sgTablet:tw-h-auto"
            :src="$cdn('images/zy2024/zhongyuan-101/activities/the-singaporen-way.svg')"
          >
          <img
            v-if="checkLang() == 'zh-hans'"
            class="tw-w-[100px] tw-h-[100px] sgTablet:tw-w-auto sgTablet:tw-h-auto"
            :src="$cdn('images/zy2024/zhongyuan-101/activities/the-singaporen-way-cn.svg')"
          >
        </div>
      </div>

      <!-- activities -->
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-6">
        <!-- title -->
        <div
          class="
              tw-w-full
              tw-text-center
              tw-px-6 sgTablet:tw-px-0
              tw-text-sg-zy-h5-mobile-bold
              sgTablet:tw-text-sg-zy-h5-tablet-bold
              sgDesktop:tw-text-sg-zy-h5-desktop-bold
              tw-pt-[24px]"
        >
          {{ $t('zy_zhongyuan_101_activities_subtitle_1') }}
        </div>
        <!-- image -->
        <div class="tw-flex tw-flex-col tw-justify-center">
          <div class="tw-flex tw-justify-center tw-h-[400px]">
            <zy-activities-swiper v-model:image-source-open="imageSourceOpen" />
          </div>
        </div>

        <!-- description -->
        <div
          id="sup-handler-container"
          class="tw-w-full tw-text-sg-ref-desktop-body tw-max-w-[600px] tw-px-4 sgTablet:tw-px-0"
          v-html="$t('zy_zhongyuan_101_activities_description')"
        />

      </div>

      <!-- popular auction -->
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-10">
        <!-- title -->
        <div
          class="
            tw-w-full
            tw-text-center
            tw-text-sg-zy-h5-mobile-bold
            tw-px-6 sgTablet:tw-px-0
            sgTablet:tw-text-sg-zy-h5-tablet-bold
            tw-pt-[24px]"
        >
          {{ $t('zy_zhongyuan_101_activities_auction_title') }}
        </div>
        <div
          class="tw-flex tw-flex-row tw-justify-center tw-gap-5"
        >
          <zy-auction-swiper v-model:image-source-open="imageSourceOpen" />
          <!--  -->
        </div>
        <div
          id="sup-handler-container"
          class="tw-w-full tw-text-sg-ref-desktop-body tw-max-w-[600px] tw-px-4 sgTablet:tw-px-0"
          v-html="$t('zy_zhongyuan_101_activities_auction_description')"
        />
      </div>
      <zy-paper-tear />
    </section>

    <!-- Getai section -->
    <section
      id="getai-section"
      class="
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          tw-relative
          tw-flex
          tw-flex-col
          tw-items-center
          tw-bg-sg-zy24-peach
          tw-py-10
          sgTablet:tw-py-[60px]
          sgDesktop:tw-pb-[80px]
        "
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-6 tw-w-full">
        <!-- title -->
        <div
          class="
            tw-w-full
            tw-text-center
            tw-text-sg-zy-h5-mobile-bold
            sgTablet:tw-text-sg-zy-h5-tablet-bold
            sgDesktop:tw-text-sg-zy-h5-desktop-bold
            "
        >
          {{ $t('zy_zhongyuan_101_getai_title') }}
        </div>
        <!-- image -->
        <div class="tw-flex tw-flex-col tw-justify-center tw-overflow-x-auto tw-scrollbar-hide">
          <div class="tw-flex tw-justify-center tw-h-[410px]">
            <!-- left image -->
            <zy-getai-swiper v-model:image-source-open="imageSourceOpen" />
          </div>
        </div>
        <!-- description -->
        <div class="tw-flex tw-flex-col tw-max-w-[600px] tw-gap-6">
          <div
            id="sup-handler-container"
            class="tw-text-sg-ref-desktop-body"
            v-html="$t('zy_zhongyuan_101_getai_description_1')"
          />
          <div
            id="sup-handler-container"
            class="tw-text-sg-ref-desktop-body"
            v-html="$t('zy_zhongyuan_101_getai_description_2')"
          />
        </div>
      </div>
      <zy-paper-tear />
    </section>

    <!-- Paper Offering section -->
    <section
      id="paper-offerings-section"
      class="
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          tw-relative
          tw-flex tw-flex-col
          tw-items-center
          tw-bg-sg-zy24-yellow
          tw-gap-6
          tw-py-10
          sgTablet:tw-py-[60px]
          sgDesktop:tw-pb-[80px]
        "
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-6">
        <!-- title -->
        <div
          class="tw-w-full tw-text-center
            tw-text-sg-zy-h5-mobile-bold
            sgTablet:tw-text-sg-zy-h5-tablet-bold
            sgDesktop:tw-text-sg-zy-h5-desktop-bold
            "
        >
          {{ $t('zy_zhongyuan_101_paper_offerings_title') }}
        </div>
        <!-- image -->
        <zy-paper-offering-swiper
          v-model:image-source-open="imageSourceOpen"
          :data="paperOfferingsItem"
        />
        <!-- description -->
        <div class="tw-flex-col tw-max-w-[632px] tw-px-4 sgTablet:tw-px-0 sgTablet:tw-max-w-[600px]">
          <div class="tw-text-sg-ref-desktop-body tw-pb-[24px] tw-text-center">
            {{ $t('zy_zhongyuan_101_paper_offerings_description_1') }}
          </div>
          <div class="tw-text-sg-ref-desktop-body tw-text-center">
            {{ $t('zy_zhongyuan_101_paper_offerings_description_2') }}
          </div>
        </div>
      </div>
      <zy-paper-tear />
    </section>

    <!-- Fire safety tips section -->
    <section
      id="fire-safety-tips-section"
      class="
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          tw-relative
          tw-flex tw-flex-col
          tw-items-center
          tw-py-10
          sgTablet:tw-py-[60px]
          sgDesktop:tw-pb-[80px]"
    >

      <div
        class="
          tw-flex tw-flex-col
          tw-items-center
          tw-gap-6
          sgTablet:tw-gap-10
          tw-w-full
          tw-max-w-full
          sgTablet:tw-max-w-[520px]
          sgTablet2:tw-max-w-[600px]
        "
      >
        <!-- title -->
        <div
          class="tw-w-full tw-text-center
          tw-text-sg-zy-h5-mobile-bold
          sgTablet:tw-text-sg-zy-h5-tablet-bold
          sgDesktop:tw-text-sg-zy-h5-desktop-bold
        "
        >
          {{ $t('zy_zhongyuan_101_fire_safety_title') }}
        </div>
        <!-- content -->
        <div class="tw-flex tw-flex-col sgTablet:tw-flex-row tw-gap-6 tw-w-full">
          <!-- left side -->
          <div
            class="
              tw-flex
              sgTablet:tw-h-[432px]
              sgTablet:tw-w-[248px]
              sgTablet2:tw-w-[288px]
              tw-justify-center
            "
          >
            <img

              :src="$cdn('images/zy2024/zhongyuan-101/fire-safety/fire-safety-tips.scaled.v3.jpg')"
            >
          </div>
          <!-- right side -->
          <div class="tw-flex tw-flex-col tw-items-center">
            <!--  -->
            <div class="tw-flex-col sgTablet:tw-max-w-[248px] sgTablet2:tw-max-w-[288px]">
              <div
                id="fire-safety-tips"
                class="tw-text-sg-ref-desktop-body tw-pb-[24px]"
                v-html="$t('zy_zhongyuan_101_description_1')"
              />

            </div>
            <!-- button 1 -->
            <div class="tw-flex tw-pt-10 tw-pb-1">
              <button
                :class="[
                  'tw-rounded-[26px] tw-w-full tw-text-ap-white tw-bg-[#BB4C31] tw-px-[24px] tw-py-[8px] tw-text-sg-zy-button hover:tw-bg-[#C9705A] active:tw-bg-[#963D27]',
                  { 'tw-font-akkuratbold' : checkLang() == 'en' },
                  { 'tw-font-notosansbold' : checkLang() != 'en' },
                ]"
                @click="openFireSafetyModal = true"
              >
                <div class="tw-w-[112px] tw-h-[36px] tw-flex tw-justify-center tw-items-center">
                  <p class="tw-text-center">
                    {{ $t('zy_zhongyuan_101_button_title_1') }}
                  </p>
                </div>
              </button>
            </div>
            <!-- button 2 -->
            <div class="tw-flex tw-h-[32px] tw-items-center tw-px-6 sgTablet2:tw-px-4 sgDesktop:tw-px-6 tw-rounded-[26px] tw-mt-5 active:tw-bg-[#ECF5FF]">
              <a
                :href="$cdn('images/zy2024/zhongyuan-101/fire-safety/fire-safety-tips.scaled.v3.jpg')"
                target="_blank"
                class=" tw-text-[#0366D8] tw-block tw-w-fit tw-mx-auto"
              >
                <div
                  class="
                  tw-flex
                  tw-flex-row
                  tw-items-center
                  tw-justify-center
                  tw-text-sg-zy-button
                  hover:tw-underline
                "
                  :class="[
                    { 'tw-font-akkuratbold' : checkLang() == 'en' },
                    { 'tw-font-notosansbold' : checkLang() != 'en' },
                  ]"
                >
                  {{ $t('cny_greet_them_right_save_image') }}
                  <inline-svg
                    alt="Download Icon"
                    :src="$cdn(`/icons/download_icon.svg`)"
                    class="tw-fill-[#0366D8] tw-w-6 tw-h-6 tw-ml-2"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <zy-paper-tear />
    </section>

    <!-- Taboos section -->
    <section
      id="taboos-section"
      class="
          tw-relative
          tw-flex
          tw-flex-col
          tw-items-center
          tw-bg-sg-zy24-peach
          tw-py-10
          tw-px-4
          sgTablet:tw-px-5
          sgDesktop:tw-px-10
          sgTablet:tw-pt-[60px]
          sgTablet:tw-pb-[80px]"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-gap-6">
        <!-- title -->
        <div
          class="tw-w-full tw-text-center
          tw-text-sg-zy-h5-mobile-bold
          sgTablet:tw-text-sg-zy-h5-tablet-bold
          sgDesktop:tw-text-sg-zy-h5-desktop-bold
          "
        >
          {{ $t('zy_zhongyuan_101_taboo_title') }}
        </div>
        <div class="tw-flex-col tw-max-w-[600px]">
          <div
            id="sup-handler-container"
            class="taboos-description tw-text-sg-ref-desktop-body tw-pb-[24px]"
            v-html="taboosDescription"
          />
        </div>
        <zy-taboos-swiper :data="taboosImages" />
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />
    <ap-footer />

    <zy-fire-safety-modal v-model="openFireSafetyModal" />
  </div>
</template>

<style scoped>
#sup-handler-container:deep(sup) {
  cursor: pointer;
  color: #0366D8;
}

#sup-handler-container:deep(a) {
  cursor: pointer;
  text-underline-offset: 2px;
  text-decoration: underline;
}

.taboos-description:deep(sup){
  cursor: pointer;
  color: #0366D8; /* Adjust this color as needed */
}

#fire-safety-tips:deep(a){
  text-decoration: underline;
}
</style>
