<template>
  <div>
    <div>
      <ap-lottie-viewer
        class="lottie-viewer tw-z-50 tw-bg-white tw-absolute"
        @lottie-finish-load="handleLottieFinishLoad"
      />
      <div class="tw-flex tw-flex-col">
        <ap-top-nav-bar
          class="tw-z-50"
          :is-transparent="true"
          :is-hidden="isNavHidden"
        />
        <div class="headerCustom">
          <!-- Background image -->
          <div
            ref="inner"
            class="inner"
          />
          <!-- Stacked Text and image -->
          <div class="tw-absolute tw-flex tw-flex-col tw-justify-center tw-px-4 tw-py-5 tw-h-[100vh] tw-z-20 tablet:tw-px-[10vw] desktop:tw-px-[20vw]">
            <ap-textra
              :data="words"
              :timer="2"
              :infinite="true"
            />
            <img
              class="min-w-[300px] min-h-[300px] w-[500px] h-[500px] object-contain tw-pt-[6px] sgTablet2:tw-pt-3 big-big-logo"
              :src="$cdn('images/singaporen-home.png')"
              alt="Singaporen Home"
            >
            <p class="shadow-text tw-italic tw-text-white tw-pt-[12px] sgTablet:tw-pt-[28px] sgDesktop:tw-pt-[28px] sgDesktop2:tw-pt-[32px] tw-text-sg-body-italic-1">
              {{ $t('ren_is_the_chinese') }}
            </p>
          </div>
        </div>
      </div>
      <ap-main-layout
        :component-text-first="wordpressData.description_1"
        :component-text-second="wordpressData.description_2"
      />
      <ap-highlights
        v-if="highlights.length>0"
        :data="highlights"
      />
      <!-- Section/Corporate Redirect -->
      <div
        class="tw-relative tw-bg-white"
      >
        <div
          class="tw-max-w-[1440px] tw-mx-auto tw-flex tw-flex-col tw-z-20 tw-px-4 tw-py-5 tw-justify-center
          tablet:tw-p-10 tablet:tw-flex-row
        desktop:tw-flex-row desktop:tw-px-20 desktop:tw-pt-[60px] desktop:tw-pb-[60px]"
        >
          <img
            class="tw-max-w-[200px] tw-max-h-[180px]
            tablet:tw-max-w-none tablet:tw-max-h-none tablet:tw-pr-[60px]
            desktop:tw-pr-20 "
            :src="$cdn('images/sccc.svg')"
            alt="SCCC"
          >
          <!-- Text and Visit Us Button -->
          <div class="tw-flex tw-flex-col">
            <p
              class="tw-text-sg-sh1-mobile tablet:tw-text-sg-sh1-tablet desktop:tw-text-sg-sh1 tw-text-sg-sccc1 tw-py-10 tablet:tw-pb-10 tw-text-left"
              :class="{
                'tw-break-keep':checkLang()=='en'
              }"
            >
              {{ $t('singaporen_brought_you_by') }}
              <br v-if="checkLang() === 'zh-hans'">
              <span v-if="checkLang() === 'zh-hans'">
                {{ $t('singaporen_brought_you_by_2') }}
              </span>
            </p>
            <div class="tw-flex tw-justify-center desktop:tw-justify-end tw-flex-row">
              <a
                class=" tw-bg-sg-sccc1 hover:tw-opacity-60 tw-rounded-[26px] tw-h-[52px] tw-pl-[24px] tw-pr-4 tw-flex tw-flex-row tw-justify-center tw-items-center tw-cursor-pointer "
                href="https://singaporeccc.org.sg/"
                target="_blank"
              >
                <span class="tw-text-sg-cny24-white tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1 tw-mr-1">{{ $t('visit_us') }}</span>
                <inline-svg
                  :src="$cdn('icons/top_right_arrow_icon.svg')"
                  :view-box="'0 0 24 24'"
                  alt="Arrow Top Right"
                  class="tw-ml-2 tw-fill-ap-white
               "
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer -->
      <ap-footer class="tw-z-20 tw-bg-white tw-relative" />
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, onBeforeUnmount, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { checkLang } from '../support/helper';
export default {
  props: {
    wordpressData: {
      type: Object,
      default: () => null,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const { wordpressData } = toRefs(props);
    const isNavHidden = ref(true);
    const inner = ref(null);
    const words = ref(['Think Local, Think', 'Distinctively', 'Our Local Chinese Culture']);
    let lastKnownScrollPosition = ref(0);
    let ticking = ref(false);
    const highlights = ref([]);

    function handleLottieFinishLoad(val) {
      if (val) {
        isNavHidden.value = false;
      }
    }

    const handleScroll = () => {
      lastKnownScrollPosition.value = window.visualViewport ? window.visualViewport.pageTop : window.scrollY;

      if (!ticking.value) {
        window.requestAnimationFrame(() => {
          let value = lastKnownScrollPosition.value / 10000 + 1;

          if (inner.value) {
            inner.value.style.transform = `scale(${value})`;
          }

          ticking.value = false;
        });
        ticking.value = true;
      }
    };

    onMounted(() => {
      if (inner.value && wordpressData.value.header_image) {
        inner.value.style.backgroundImage =
        `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${wordpressData.value.header_image})`;
      }
      window.addEventListener('scroll', handleScroll);
      // console.log('wordpressData', wordpressData.value);
      if(wordpressData.value.carousel){
        highlights.value = wordpressData.value.carousel;
      }
      setWordsCN();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const setWordsCN = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const langParam = urlParams.get('lang');
      // console.log('check urlParams', langParam);

      if(langParam == 'zh-hans'){
        words.value = ['本土文化，本地', '⁠独一无二的', '本土华族文化'];
        // Client request to remove chinese words
      }
    };

    return {
      words,
      isNavHidden,
      handleLottieFinishLoad,
      inner,
      t,
      checkLang,
      highlights,
    };
  },
};
</script>

<style scoped>
.headerCustom {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .headerCustom {
    max-height: 100svh;
  }
  .inner {
    max-height: 100svh;
  }
}

.inner {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url(https://picsum.photos/1200?random=2);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

p.shadow-text {
  text-shadow: -4px 4px 15px rgba(0,0,0,0.30)
}
</style>
