<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { h, ref} from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');

const menuItems = [
  {
    label:'ma_about_menu_celebrations',
    id:'ma_about_menu_celebrations'
  },
  {
    label:'ma_about_menu_mooncakes',
    id:'ma_about_menu_mooncakes'
  },
  {
    label:'ma_about_menu_typical_mooncakes',
    id:'ma_about_menu_typical_mooncakes'
  },
];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onMounted(() => {
  nextTick(() => {
    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };
      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };
    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }
});

const sliderData = ref<any[]>([]);


onBeforeMount(()=>{
  if(props.wordpressData?.image_sources?.length > 0) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }

  sliderData.value = [
    {
      image: cdn('images/ma2024/about/typical-mooncakes/ma_typical_mooncakes_slider_01.v2.jpg'),
      description_en: '<p>Apart from mooncakes, you may have seen the piglet biscuits for sale during Mid-Autumn Festival. Shaped like pigs and sold in colourful plastic woven “cages”, they are made with the same dough as the outer layer of mooncakes and usually baked without filling. It is said that bakers in the olden days would bake these cakes to test the texture of their dough and the heat of the oven. These biscuits were often given to children as they were cheaper compared to mooncakes. <sup>[7]</sup></p>',
      description_cn: '<p>除了琳琅满目的月饼，你可能还见过饼店在节日期间售卖的猪笼饼。这些饼一般做成小猪的形状，装进塑料“猪笼”里。猪笼饼一般没有馅料只有饼皮。据说在过去，制饼的商家会先将不包馅的面团，放入烤炉中测试烤炉温度，确保月饼饼皮的口感适中。饼店也会在中秋节送这些猪笼饼给小朋友们，让他们过个欢乐的中秋节。<sup>[7]</sup></p>'
    },
    {
      image: cdn('images/ma2024/about/typical-mooncakes/ma_typical_mooncakes_slider_02.jpg'),
      description_en: '<p>These soft, no-bake mooncakes have been available in Singapore since the 1960s! It is said they were invented in Hong Kong in the 1960s as a healthier alternative. When first brought to Singapore, snowskin mooncakes were mainly white and not as exquisite as they are today. One of the first shops to offer snowskin mooncakes, <a href="https://www.facebook.com/people/Poh-Guan-Cake-House/100063467473029/" target="_blank">Poh Guan Cake House</a>, subsequently developed new flavours such as pandan and chocolate. Since then, these delicate mooncakes have become a must-eat Mid-Autumn treat for locals! <sup>[8]</sup></p>',
      description_cn: '<p>不需烘烤，外皮软糯的冰皮月饼经常被视为现代版的中秋节食品，但你知道吗？冰皮月饼早在1960年代就已经在新加坡出现了！据说冰皮月饼于1960年代在香港首创，是一种比传统烘焙月饼健康的选择。刚开始传入新加坡时，冰皮月饼主要是白色的，外观和口味也没有现在这么精致。据报道，<a href="https://www.facebook.com/people/Poh-Guan-Cake-House/100063467473029/" target="_blank">宝源饼家</a>是最早在新加坡推出冰皮月饼的饼家之一，如斑兰和巧克力口味。从那时起，松软可口的饼皮月饼便成了本地人必吃的中秋节点心！ <sup>[8]</sup></p>'
    }
  ];
  // if(props.wordpressData?.timeline?.length > 0){
  //   sliderData.value = props.wordpressData.timeline;
  // }
});


const section2TextRef = ref();
const section2ImgRef = ref();
onMounted(() => {
  recalculateHeight();
  window.addEventListener('resize', recalculateHeight);
});
onBeforeUnmount(()=>{
  window.removeEventListener('resize', recalculateHeight);
});

function recalculateHeight(){
  requestAnimationFrame(()=>{
    if(section2TextRef.value){
      let height = section2TextRef.value.getBoundingClientRect().height;
      height = height > 300 ? height : 300;
      section2ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }
  });
}
</script>

<template>
  <div
    :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}"
  >
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ma-navigation="true"
      :ma2-navigation="wordpressData?.canView?.includes('sidebar.ma2')"
    />
    <ma-secondary-navigation
      :menu-items-data="menuItems"
    />

    <!-- header -->
    <section class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/ma2024/ma_sub_banner.jpg')"
          :class="[
            'tw-w-full',
            'tw-h-[160px] sgTablet:tw-h-[240px] sgDesktop:tw-h-[280px]',
            'tw-object-cover'
          ]"
        >
      </picture>
      <div class="tw-bg-black/20 tw-absolute tw-w-full tw-h-full tw-top-0" />
      <div class="tw-absolute tw-top-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full">
        <div class="tw-text-white tw-text-center">
          <div
            :class="[
              'tw-text-sg-ma-mobile-h1 sgTablet:tw-text-sg-ma-tablet-h1 sgDesktop:tw-text-sg-ma-desktop-h1',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_about_title') }}
          </div>
        </div>
      </div>
    </section>

    <!-- description -->
    <section
      :class="[
        'tw-pt-5 sgTablet:tw-pt-10',
        'tw-pb-5',
        'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-[80px]',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
      ]"
    >
      <div
        id="sup-handler-container"
        :class="[
          'tw-max-w-[800px] tw-text-center',
          'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1'
        ]"
        v-html="wordpressData.description"
      />
    </section>

    <!-- celebration -->
    <section
      id="ma_about_menu_celebrations"
      :class="[
        'tw-pt-5',
        'tw-pb-10 sgDesktop:tw-pb-[60px]',
        'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-[80px]',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
      ]"
      style="overflow-x: hidden;"
    >
      <div
        :class="[
          'tw-w-full sgTablet2:tw-max-w-[800px]',
          'tw-grid tw-grid-cols-1 sgTablet2:tw-grid-cols-2',
        ]"
      >
        <ma-about-celebration-swiper
          v-model:image-source-open="imageSourceOpen"
        />

        <div
          :class="[
            'tw-flex tw-flex-col',
            'tw-gap-5',
            'tw-pt-10 sgTablet2:tw-pt-0',
            'sgTablet2:tw-pl-10'
          ]"
        >
          <!-- title -->
          <div
            :class="[
              'tw-text-sg-ma24-purple-b40',
              'tw-text-sg-ma-mobile-h3 sgDesktop:tw-text-sg-ma-desktop-h3',
              'tw-text-center sgTablet2:tw-text-left',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_about_celebrations_title') }}
          </div>
          <!-- description -->
          <div
            id="sup-handler-container"
            :class="[
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1',
              'tw-text-center sgTablet2:tw-text-left'
            ]"
          >
            {{ $t('ma_about_celebrations_desc') }}
            <sup
              class="tw-text-sg-zy24-blue tw-text-tw-text-sg-body-1-mobile sgTablet:tw-text-tw-text-sg-body-1-tablet desktop:tw-text-tw-text-sg-body-1"
            >[4]</sup>
          </div>
        </div>

      </div>
    </section>

    <!-- Mooncakes -->
    <section
      id="ma_about_menu_mooncakes"
      :class="[
        'tw-relative',
        'tw-px-4 sgTablet:tw-px-10 sgDesktop:tw-px-[80px]',
        'sgDesktop2:tw-py-[104px] sgDesktop:tw-py-[92px] sgTablet2:tw-py-[90px] sgTablet:tw-py-[80px] tw-py-[52px]',
        'tw-relative tw-w-full tw-h-full',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
      ]"
      :style="{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${ $cdn('images/ma2024/about/mooncakes/ma_about_mooncakes_bg.jpg') })`,
        backgroundPosition: '50%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'lightgray'
      }"
    >
      <div
        :class="[
          'tw-w-full tw-h-full',
          'tw-max-w-[800px]',
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
        ]"
      >
        <!-- mooncake image -->
        <div
          class="tw-flex tw-flex-shrink-0 tw-self-center tw-h-[300px] sgTablet:tw-h-[400px] sgTablet2:tw-h-auto sgTablet2:tw-w-[400px]"
        >
          <img
            :src="$cdn('images/ma2024/about/mooncakes/ma_about_mooncakes_feature.png')"
            class="tw-w-full tw-object-contain sgTablet2:tw-max-h-[--text-height]"
          >
        </div>
        <!-- mooncake title -->
        <div
          :class="[
            'tw-text-sg-ma24-yellow tw-text-sg-ma-mobile-h3 sgTablet:tw-text-sg-ma-desktop-h3 tw-pb-5 tw-pt-10',
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
          ]"
        >
          {{ $t('ma_about_mooncakes_title') }}
        </div>
        <!-- mooncake description -->
        <div
          :class="[
            'tw-flex tw-flex-col tw-gap-5',
            'tw-text-sg-ma-body tw-text-white tw-text-center',
          ]"
        >
          <div>
            {{ $t('ma_about_mooncakes_description_1') }}
          </div>
          <div>
            {{ $t('ma_about_mooncakes_description_2') }}
          </div>
          <div
            id="sup-handler-container"
            class="ma-mooncake-description"
            v-html="$t('ma_about_mooncakes_description_3')"
          />
        </div>
      </div>
      <div class="tw-absolute tw-top-0 tw-w-full">
        <ma-wave-break-line
          image="images/ma2024/about/celebrations/ma-line-break.svg"
          direction=""
        />
      </div>
      <div class="tw-absolute tw-bottom-0 tw-w-full">
        <ma-wave-break-line
          image="images/ma2024/about/mooncakes/ma-line-break.svg"
          direction=""
        />
      </div>
    </section>

    <!-- Typical mooncakes -->
    <section
      id="ma_about_menu_typical_mooncakes"
      :class="[
        'sgTablet:tw-px-10 sgDesktop:tw-px-[80px]',
        'sgDesktop:tw-pt-10 sgTablet2:tw-pt-[60px] tw-pt-10',
        'sgDesktop2:tw-pb-[104px] sgTablet2:tw-pb-[92px] tw-pb-[52px]',
        'tw-relative tw-w-full tw-h-full',
        'tw-flex tw-flex-col tw-items-center tw-justify-center',
        'tw-bg-sg-ma24-orange-w60',
      ]"
    >
      <div
        :class="[
          'tw-max-w-[800px]',
          'tw-flex tw-flex-col tw-items-center tw-justify-center',
          'tw-w-full tw-h-full',
        ]"
      >
        <div class="tw-flex tw-flex-col sgTablet:tw-flex-row tw-items-center tw-gap-2 tw-pb-5">
          <div
            :class="[
              'tw-text-sg-ma24-purple-b40 tw-text-sg-ma-mobile-h3 sgTablet:tw-text-sg-ma-desktop-h3',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
          >
            {{ $t('ma_about_typical_mooncakes_title') }}
          </div>
          <img
            class="tw-w-[100px] tw-h-[67px]"
            :src="checkLang() === 'en' ? $cdn('images/ma2024/ma_stamp_EN.svg') : $cdn('images/ma2024/ma_stamp_CN.svg')"
          >
        </div>
        <!-- mooncake description -->
        <div
          :class="[
            'tw-flex tw-flex-col tw-gap-5 tw-text-sg-body-1 tw-text-center sgTablet:tw-pb-10 tw-pb-8',
            'tw-px-4 sgTablet:tw-px-0',
          ]"
        >
          <div>
            {{ $t('ma_about_typical_mooncakes_description') }}
          </div>
        </div>
        <!-- slider -->
        <ma-about-typical-mooncake-swiper
          v-model:image-source-open="imageSourceOpen"
          :data="sliderData"
        />
      </div>
      <div class="tw-absolute tw-bottom-0 tw-w-full">
        <ma-wave-break-line image="images/ma2024/ma-cta-line-break.svg" />
      </div>
    </section>

    <ma-bottom-cta-section
      :title="$t('ma_about_read_more_title')"
      :cta-button="$t('ma_about_discover_more')"
      :cta-link="$langRedirect('/festivals/mid-autumn/mid-autumn-101', checkLang())"
      :image="$cdn('images/ma2024/about/ma_about_cta.png')"
    />

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>

<style scoped>

#sup-handler-container:deep(sup){
  cursor: pointer;
  color: #0366D8;
}

.ma-mooncake-description:deep(sup){
  cursor: pointer;
  color: #fff !important;
}

</style>
