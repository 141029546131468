<template>
  <div class="tw-max-w-[1440px]">
    <swiper
      id="preserving_qing_ming"
      ref="preservingQingMingSwiper"
      :pagination="{
        clickable:true,
        el:'.preserving-qing-ming-swiper-pagination'
      }"
      :slides-per-view="'auto'"
      :slides-per-group="1"
      :center-insufficient-slides="true"
      class="preserving_qing_ming_swiper tw-w-full"
      centered-slides
      centered-slides-bounds
      :initial-slide="0"
      :space-between="20"
      :allow-touch-move="false"
      :breakpoints="{
        680:{
          // centeredSlides:false,
          // centeredSlidesBounds:false,
          // slidesOffsetAfter:32,
          // slidesOffsetBefore:32
        },
        800:{
          centeredSlides:false,
          centeredSlidesBounds:false,
          slidesOffsetAfter:40,
          slidesOffsetBefore:40
        },
        1025:{
          centeredSlides:true,
          centeredSlidesBounds:true,
          // slidesOffsetAfter:235,
          // slidesOffsetBefore:235
        },
        1440: {
          // slidesOffsetAfter:315,
          // slidesOffsetBefore:315
        }
      }"
      :slides-offset-after="16"
      :slides-offset-before="16"
      @swiper="onSwiper"
      @resize="checkSlidePosition"
      @slide-change="onSlideChange"
    >

      <swiper-slide
        v-for="(item, index) in props.data"
        :key="index"
        class="tw-w-[320px] tw-relative"
      >
        <!-- <div class="tw-bg-ap-white tw-flex tw-flex-col sgDesktop:tw-flex-row tw-items-center">
          <blockquote
            class="tiktok-embed"
            cite="https://www.tiktok.com/@singaporeccc/video/7216165373224193281"
            data-video-id="7216165373224193281"
            data-embed-from="embed_page"
            style="max-width: 605px;min-width: 325px;"
          >
            <section>
              <a
                target="_blank"
                title="@singaporeccc"
                href="https://www.tiktok.com/@singaporeccc?refer=embed"
              >@singaporeccc</a>
              <p>Passed down through the generations, Qingming is one of the most widely celebrated festivals by the local Chinese community. 👪 Apart from commemorating our ancestors, it’s also an opportunity to impart and reinforce values like filial piety to the younger generation. 🧡 Youths share what they think should be done to make the festival remains relevant to their generation. Share your thoughts with us too!Discover more interesting facts about the festival at 👉 https://festivefever.singaporeccc.org.sg/qingming.</p> <a
                target="_blank"
                title="♬ original sound - SCCC - SCCC"
                href="https://www.tiktok.com/music/original-sound-SCCC-7216926304967412481?refer=embed"
              >♬ original sound - SCCC - SCCC</a>
            </section>
          </blockquote>
        </div> -->
        <div
          class="ttc tw-bg-ap-white tw-flex sgDesktop:tw-flex-row tw-items-center tw-pt-[22px]"
          v-html="item.tiktok_embed_code"
        />
      </swiper-slide>
    </swiper>

    <div class="tw-flex tw-pb-10 tw-pt-8 tw-px-20 tw-justify-center">
      <button
        class="tw-rounded-full tw-h-[56px] tw-min-w-[56px] tw-text-white tw-bg-sg-qm24-green tw-text-[35px] tw-pb-2 tw-pr-1 disabled:tw-opacity-25 sgDesktop:tw-hidden"
        :disabled="disabledPrev"
        @click="slidePrev()"
      >
        &lt;
      </button>
      <div class="tw-px-[40px] tw-items-center tw-flex">
        <p
          class="tw-text-sg-qm24-green-b60 tw-text-sg-caption tw-flex-nowrap"
          :style="{minWidth: '26px'}"
        >
          <!--  {{ `${realIndex} / ${swiperLength}` }} -->
        </p>
      </div>
      <button
        class="tw-rounded-full tw-h-[56px] tw-min-w-[56px] tw-text-white tw-bg-sg-qm24-green tw-text-[35px] tw-pb-2 tw-pl-1 disabled:tw-opacity-25 sgDesktop:tw-hidden"
        :disabled="disabledNext"
        @click="slideNext()"
      >
        >
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {Swiper as SwiperInstance} from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/vue';
import { Pagination, Mousewheel} from 'swiper/modules';
import SwiperCore from 'swiper';
import { onMounted, ref, Ref, computed } from 'vue';
import { nextTick } from 'vue';

SwiperCore.use([Pagination, Mousewheel]);

const datas: Ref<Array<any>> = ref([]);
const preservingQingMingSwiper = ref(null);
const swiperInstance = ref<SwiperInstance>();
const disabledPrev = ref(true);
const disabledNext = ref(true);

interface PreserveQingming {
  tiktok_embed_code: string;
}

const props = defineProps<{
  data: PreserveQingming[]
}>();

const onSwiper = (swiper:any) => {
  swiperInstance.value = swiper;
  console.log('im here');
  checkSlidePosition();
};

const realIndex = computed(() => {
  return swiperInstance.value?.snapIndex + 1;
});

const swiperLength = computed(() => {
  // Cater for large screem with > 2014
  if(!!swiperInstance?.value){
    if( (swiperInstance.value?.isBeginning && swiperInstance.value?.isEnd)   || (!swiperInstance.value.allowSlideNext && !swiperInstance.value.allowSlidePrev)) {
      return 1;
    }
  }
  // Use length by swipe
  if(window.innerWidth > 800 && window.innerWidth < 1024) {
    return  Math.ceil(swiperInstance.value?.virtualSize / swiperInstance.value?.width) ?? 0;
  }

  return swiperInstance.value?.slides.length ?? 0;
});

const onSlideChange = (swiper:any) => {
  //console.log('on slide change', swiper);
};


onMounted(() => {
  datas.value = [
    {
      content: 'When visiting the cemetery to clean a tomb, this could involve removing weeds or debris, wiping down the headstone, and ensuring the area around the grave is clean. It’s also customary to bring food offerings and/or flowers as a way of paying respects to the deceased. The act of visiting a tomb to clean and present offerings is to honour the memory of the deceased and demonstrate a sense of care and respect for their final resting place.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/1-Burning of Joss.png',
      isSingaporenWay: false,
    },
    {
      content: 'Remembrance of loved ones is also observed at home on Qingming day by those who practise ancestor worship.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/2-Burning of Joss.png',
      isSingaporenWay: false,
    },
    {
      content: 'As cremation becomes more widespread due to land-scarcity, visiting the cemetery will increasingly be replaced by visitation to the columbarium.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/3-Burning of Joss.png',
      isSingaporenWay: true,
    },
    {
      content: 'When visiting the cemetery to clean a tomb, this could involve removing weeds or debris, wiping down the headstone, and ensuring the area around the grave is clean. It’s also customary to bring food offerings and/or flowers as a way of paying respects to the deceased. The act of visiting a tomb to clean and present offerings is to honour the memory of the deceased and demonstrate a sense of care and respect for their final resting place.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/1-Burning of Joss.png',
      isSingaporenWay: false,
    },
    {
      content: 'Remembrance of loved ones is also observed at home on Qingming day by those who practise ancestor worship.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/2-Burning of Joss.png',
      isSingaporenWay: false,
    },
    {
      content: 'As cremation becomes more widespread due to land-scarcity, visiting the cemetery will increasingly be replaced by visitation to the columbarium.',
      imageUrl: 'images/qm2024/qingming-101/3-Burning_of_Joss/2-Carousel/3-Burning of Joss.png',
      isSingaporenWay: true,
    },
  ];

  nextTick(() => {
    checkSlidePosition();

  });

  //console.log('check swiper', swiperInstance.value);

});

function slideNext() {
  swiperInstance.value?.slideNext();

  checkSlidePosition();
}

function slidePrev() {
  swiperInstance.value?.slidePrev();
  checkSlidePosition();
}


function checkSlidePosition() {
  //console.log('check ins', swiperInstance.value, swiperInstance.value?.snapIndex, props.data.length, swiperInstance.value?.isEnd);
  //
  if( (swiperInstance.value?.isBeginning && swiperInstance.value?.isEnd)   || (!swiperInstance.value.allowSlideNext && !swiperInstance.value.allowSlidePrev)) {
    disabledPrev.value = true;
    disabledNext.value = true;
  }
  else
    if ((swiperInstance.value?.snapIndex == ( props.data.length - 1)) || swiperInstance.value?.isEnd) {
      disabledPrev.value = false;
      disabledNext.value = true;
    }
    else if (swiperInstance.value?.snapIndex == 0 || swiperInstance.value?.isBeginning) {
      disabledPrev.value = true;
      disabledNext.value = false;
    }else {
      disabledPrev.value = false;
      disabledNext.value = false;
    }

}

</script>

<style lang="scss">
.preserving-qing-ming-swiper-pagination {
  padding-top: 12px;
  justify-content: center;
  display: flex;
}

.preserving-qing-ming-swiper-pagination .swiper-pagination-bullet {
  @apply tw-w-3 tw-h-3;
}

.preserving-qing-ming-swiper-pagination .swiper-pagination-bullet-active {
  @apply tw-bg-ap-dim-grey #{!important};
  @apply tw-w-3 tw-h-3;
}

#preserving_qing_ming .swiper-wrapper .swiper-slide {
  // @apply first:tw-ml-4 last:tw-mr-4 sgTablet:first:tw-ml-8 sgTablet:last:tw-mr-8  sgDesktop:first:tw-ml-[235px] sgDesktop:last:tw-mr-[235px] sgDesktop2:first:tw-ml-[315px] sgDesktop2:last:tw-mr-[315px];
}

#preserving_qing_ming .swiper-wrapper {

}



</style>
