<script lang="ts" setup>
import { checkLang } from '../../support/helper';
import { onMounted } from 'vue';
import { ref } from 'vue';
import { cdn } from '../../support/cdn';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
const props = defineProps<{
  wordpressData: any;
}>();
const { t } = useI18n();
const card_list = ref<
  {
    title: string;
    image: string;
    datetime: string;
    venue: string;
  }[]
>([]);

const social_media_list = ref<{ id: number; name: string; icon: string; href: string }[]>([]);

const programme_filter_list = computed(() => [
  {
    key: 'all',
    label: t('cny_family_fun_all'),
  },
  {
    key: 'activities',
    label: t('cny_family_fun_activities'),
  },
  {
    key: 'workshops',
    label: t('cny_family_fun_workshops'),
  },
  {
    key: 'performances',
    label: t('cny_family_fun_performances'),
  },
]);

type Post = {
  title: string;
  description: string;
  category: {
    label: string;
    value: string;
  };
  date: string;
  time: string;
  paid: boolean;
  image: string;
  external_link: string;
};
const post_filter = ref('all');
const programme_post = ref<Post[]>([]);
const filtered_programme_post = computed<Post[]>(() => {
  if (post_filter.value === 'all') {
    return programme_post.value;
  } else {
    return programme_post.value.filter((item: Post) => item.category.value === post_filter.value);
  }
});
const downloadables = ref({});

const festivalMap = ref('');
const programmeSchedule = ref('');
const taglineHeader = ref('');
const taglineDescription = ref('');
const showSocial = ref(false);

const modalKey = ref(Date.now());

onMounted(() => {
  if (props.wordpressData?.programme_post) {
    programme_post.value = props.wordpressData.programme_post.map((item: any) => {
      return {
        ...item,
        image: item.image ? item.image : cdn('images/cny2024/family-fun/programme_placeholder.png'),
      };
    });
  }

  if (props.wordpressData?.downloadables) {
    downloadables.value = props.wordpressData.downloadables;
  }

  if (props.wordpressData?.social_link) {
    const links = Object.values(props.wordpressData.social_link);
    showSocial.value = links.filter((item) => !item).length != links.length;
    social_media_list.value = [
      {
        id: 1,
        name: 'Facebook',
        icon: '/images/cny2024/family-fun/social_facebook.svg',
        href: props.wordpressData.social_link?.facebook_link ?? '',
      },
      {
        id: 2,
        name: 'Instagram',
        icon: '/images/cny2024/family-fun/social_instagram.svg',
        href: props.wordpressData.social_link?.instagram_link ?? '',
      },
      {
        id: 3,
        name: 'Youtube',
        icon: '/images/cny2024/family-fun/social_youtube.svg',
        href: props.wordpressData.social_link?.youtube_link ?? '',
      },
      {
        id: 4,
        name: 'Wechat',
        icon: '/images/cny2024/family-fun/social_wechat.svg',
        href: props.wordpressData.social_link?.wechat_link ?? '',
      },
      {
        id: 5,
        name: 'Tiktok',
        icon: '/images/cny2024/family-fun/social_tiktok.svg',
        href: props.wordpressData.social_link?.tiktok_link ?? '',
      },
      {
        id: 6,
        name: 'XiaoHongShu',
        icon: '/images/cny2024/family-fun/social_xhs.svg',
        href: props.wordpressData.social_link?.xiaohongshu_link ?? '',
      },
      {
        id: 7,
        name: 'Linkedin',
        icon: '/images/cny2024/family-fun/social_linkedin.svg',
        href: props.wordpressData.social_link?.linkedin_link ?? '',
      },
    ].filter((item) => !!item.href);
  }

  if (props.wordpressData?.festival_map_image) {
    festivalMap.value = props.wordpressData.festival_map_image;
  }
  if (props.wordpressData?.programme_image) {
    programmeSchedule.value = props.wordpressData.programme_image;
  }
  if (props.wordpressData?.tagline_header) {
    taglineHeader.value = props.wordpressData.tagline_header;
  }
  if (props.wordpressData?.tagline_description) {
    taglineDescription.value = props.wordpressData.tagline_description;
  }

  if (props.wordpressData?.freebies) {
    card_list.value = props.wordpressData.freebies;
  }
});

const programmeModal = ref<{ show: boolean; data: Post }>({
  show: false,
  data: {} as Post,
});
const handleOpenProgrammeModal = (item: Post) => {
  programmeModal.value.data = item;
  programmeModal.value.show = true;

  modalKey.value = Date.now();
  console.log('modalKey', modalKey.value);
};
const programmeScheduleModal = ref(false);
const handleOpenProgrammeScheduleModal = () => {
  programmeScheduleModal.value = true;
};
const menuItems = computed(() => [
  {
    label: 'cny_family_fun_menu_about',
    id: 'about',
  },
  {
    label: 'cny_family_fun_menu_festival_map',
    id: 'festival-map',
  },
  {
    label: 'cny_family_fun_menu_programmes',
    id: 'programmes',
  },
  {
    label: 'cny_family_fun_menu_dragons_in_singapore',
    id: 'dragons-in-singapore',
  },
]);
</script>

<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :cny-navigation="true"
    />

    <secondary-navigation :menu-items-data="menuItems" />

    <section id="about">
      <div class="tw-relative">
        <picture>
          <source
            media="(max-width: 375px)"
            :srcset="$cdn('images/cny2024/family-fun/banner_375.png')"
          >
          <source
            media="(max-width: 680px)"
            :srcset="$cdn('images/cny2024/family-fun/banner_680.png')"
          >
          <source
            media="(max-width: 800px)"
            :srcset="$cdn('images/cny2024/family-fun/banner_800.png')"
          >
          <source
            media="(max-width: 1025px)"
            :srcset="$cdn('images/cny2024/family-fun/banner_1025.png')"
          >
          <img
            :src="$cdn('images/cny2024/family-fun/banner_1440.png')"
            class="tw-w-full tw-object-cover"
          >
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div
            class="tw-text-[#EB2743] tw-font-hobeaux tw-font-[900] tw-tracking-[4%] tw-text-[44px] tw-leading-[56px] sgDesktop:tw-text-[56px] sgDesktop:tw-leading-[68px]"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('cny_family_fun_title') }}
          </div>
        </div>
      </div>
    </section>

    <section v-if="!!festivalMap">
      <div class="tw-mt-5 tw-mx-4 sgTablet:tw-mt-10 sgTablet:tw-mx-10 sgDesktop:tw-mt-[60px] sgDesktop:tw-mx-20">
        <img
          :src="festivalMap"
          class="tw-h-full tw-w-full"
        >
      </div>
    </section>
    <section
      v-if="!!taglineHeader || !!taglineDescription"
      class="tw-mt-10 sgTablet:tw-mt-[60px] sgDesktop:tw-mt-20"
    >
      <div class="tw-w-full tw-text-center tw-px-4">
        <div
          class="tw-mx-auto tw-max-w-[600px] tw-w-full tw-font-hobeaux tw-font-[900] tw-text-[#EB2743] tw-text-[28px] tw-leading-[36px] sgTablet:tw-text-[36px] sgTablet:tw-leading-[44px] sgDesktop:tw-text-[44px] sgDesktop:tw-leading-[56px]"
          :class="{
            'tw-font-akkuratbold': checkLang() == 'en',
            'tw-font-notosansbold': checkLang() == 'zh-hans',
          }"
          v-html="taglineHeader"
        />

        <div
          class="tw-mx-auto tw-max-w-[600px] tw-w-full tw-mt-10 tw-text-[#1A1A1A] tw-break-words"
          v-html="taglineDescription"
        />
      </div>
    </section>
    <section
      v-if="card_list.length > 0"
      class="tw-mt-10 sgTablet:tw-mt-[60px] sgDesktop:tw-mt-20 tw-px-2"
    >
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-5 tw-max-w-[1280px] tw-w-full tw-mx-auto">
        <div
          v-for="item in card_list"
          :key="item.title"
          class="!tw-bg-no-repeat !tw-bg-contain tw-relative tw-text-center tw-max-h-[min(320px,calc(100vw-32px))] tw-w-[320px] tw-h-full sgTablet:tw-h-[380px] sgTablet:tw-w-[380px] sgTablet:tw-max-h-full sgDesktop:tw-h-[380px] sgDesktop:tw-w-[380px]"
          :style="{
            boxShadow: '-4px 4px 10px 0px rgba(0, 0, 0, 0.3)',
          }"
        >
          <img :src="$cdn('images/cny2024/family-fun/card.png')">
          <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
            <div class="tw-max-w-[280px] tw-mx-auto tw-w-full sgDesktop:tw-max-w-7xl tw-px-4 tw-space-y-5 sgTablet:tw-space-y-6">
              <div
                class="tw-text-[#EB2743] tw-font-hobeaux tw-font-[900] tw-text-xl tw-max-h-[84px] sgTablet:tw-text-2xl sgTablet:tw-max-h-[96px] tw-overflow-hidden"
                v-html="item.title"
              />
              <img
                :src="item.image"
                class="tw-mx-auto tw-h-[80px] tw-w-[80px] sgTablet:tw-h-[120px] sgTablet:tw-w-[120px]"
              >
              <div>
                <div
                  class="tw-text-sg-sh3-mobile-cny tablet:tw-text-sg-sh3-tablet-cny desktop:tw-text-sg-sh3-cny"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
                >
                  {{ item.datetime }}
                </div>
                <div
                  class="tw-pt-1 tw-text-[#1A1A1A] tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-max-h-[72px] tw-overflow-hidden"
                  :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'"
                >
                  {{ item.venue }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="tw-text-xs tw-text-center tw-mt-10 sgTablet:tw-mt-[60px] sgDesktop:tw-mt-20 tw-font-[400] tw-text-[#1A1A1A]"
        v-html="$t('cny_family_fun_redeem_section_tnc')"
      />
    </section>

    <section
      v-if="showSocial"
      class="tw-text-center tw-px-4 tw-mt-10 sgTablet:tw-mt-[60px] sgDesktop:tw-mt-20"
    >
      <div class="tw-text-[#EB2743] tw-font-hobeaux tw-font-[900] tw-text-xl sgTablet:tw-text-2xl">
        <div>
          {{ $t('cny_family_fun_stay_tuned_social_media') }}
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-flex-wrap tw-mt-10 tw-gap-10 sgTablet:tw-gap-[60px] sgDesktop:tw-gap-20">
        <div
          v-for="item in social_media_list"
          :key="item.id"
        >
          <a
            :href="item.href"
            target="_blank"
            class="tw-cursor-pointer hover:tw-opacity-70"
          >
            <img :src="$cdn(item.icon)">
          </a>
        </div>
      </div>
    </section>

    <section
      v-if="!!programmeSchedule"
      id="festival-map"
      class="tw-mt-10 sgTablet:tw-mt-[60px] sgDesktop:tw-mt-20"
    >
      <div class="tw-mt-5 tw-mx-4 sgTablet:tw-mt-10 sgTablet:tw-mx-10 sgDesktop:tw-mt-[60px] sgDesktop:tw-mx-20">
        <div class="tw-w-full tw-max-w-[1066px] tw-mx-auto tw-relative">
          <img
            :src="programmeSchedule"
            class="tw-h-full tw-w-full"
          >
          <div
            class="tw-absolute tw-top-2 tw-right-2 tablet:tw-top-4 tablet:tw-right-4 desktop:tw-top-5 desktop:tw-right-5 tw-cursor-pointer tw-rounded-lg tw-bg-[#767676]/50 hover:tw-bg-[#767676] tw-p-2"
            @click="handleOpenProgrammeScheduleModal"
          >
            <img
              :src="$cdn('icons/expand_icon.svg')"
              alt="Expand icon"
              class="tw-w-5 tw-h-5 desktop:tw-w-7 desktop:tw-h-7"
            >
          </div>
        </div>
      </div>
    </section>

    <section
      id="programmes"
      class="tw-mt-5 sgTablet:tw-mt-20 sgDesktop:tw-mt-30 tw-bg-[#ED522A] tw-relative tw-pb-5 sgTablet:tw-pb-[100px] sgDesktop:tw-pb-30"
    >
      <img
        :src="$cdn('images/cny2024/family-fun/programme_left.png')"
        class="tw-absolute tw-left-0 tw-h-[80px] tw-w-[80px] tw-top-[-20px] sgTablet:tw-h-[160px] sgTablet:tw-w-[160px] sgTablet:tw-top-[-80px] sgDesktop:tw-h-[200px] sgDesktop:tw-w-[200px] sgDesktop:tw-top-[-100px]"
      >
      <img
        :src="$cdn('images/cny2024/family-fun/programme_right.png')"
        class="tw-absolute tw-right-0 tw-h-[80px] tw-w-[80px] tw-top-[-20px] sgTablet:tw-h-[160px] sgTablet:tw-w-[160px] sgTablet:tw-top-[-80px] sgDesktop:tw-h-[200px] sgDesktop:tw-w-[200px] sgDesktop:tw-top-[-100px]"
      >
      <div
        class="tw-font-[900] tw-text-white tw-font-hobeaux tw-text-center tw-pt-[24px] sgTablet:tw-pt-[36px] sgDesktop:tw-pt-[48px] tw-text-[28px] tw-leading-[36px] sgTablet:tw-text-[36px] sgTablet:tw-leading-[44px] sgDesktop:tw-text-[44px] sgDesktop:tw-leading-[56px]"
      >
        {{ $t('cny_family_fun_programmes') }}
      </div>

      <div
        class="tw-mt-10 sgDesktop:tw-mt-[60px] tw-flex tw-overflow-x-auto tw-gap-x-2 sgTablet:tw-justify-between scrollbar-hide sgTablet:tw-max-w-[800px] sgTablet:tw-w-full sgTablet:tw-mx-auto"
      >
        <div
          v-for="item in programme_filter_list"
          :key="item.key"
          class="tw-rounded-full tw-w-36 tw-h-8 tw-text-center tw-text-sm tw-text-white tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-cursor-pointer"
          :class="{
            'tw-bg-[#FF8700]': item.key == post_filter,
            'hover:tw-border-2 hover:tw-border-white': item.key != post_filter,
          }"
          @click="post_filter = item.key"
        >
          <span> {{ item.label }}</span>
        </div>
      </div>

      <div class="tw-mt-8 sgTablet:tw-mt-9 tw-mx-4">
        <div class="tw-flex tw-justify-center">
          <div class="tw-max-w-[252px] familyFunTablet:tw-max-w-[530px] familyFunDesktop:tw-max-w-[800px] tw-w-full tw-mx-auto">
            <div class="tw-flex tw-flex-wrap tw-gap-4">
              <template
                v-for="(item, index) in filtered_programme_post"
                :key="item"
              >
                <div
                  class="tw-flex tw-flex-col tw-w-[252px] tw-bg-white tw-relative tw-rounded"
                  :style="{
                    boxShadow: ', 0px 4px 8px 0px #0000000F,0px 0px 4px 0px #0000000A',
                  }"
                >
                  <img
                    :src="item.image"
                    class="tw-w-full tw-h-[141.75px] tw-object-center tw-object-cover tw-rounded-t"
                  >
                  <div class="tw-my-5 tw-mx-4 tw-rounded-b tw-flex-1 tw-flex tw-flex-col">
                    <div class="tw-text-xs tw-text-[#1A1A1A]">
                      {{ programme_filter_list?.find((x) => x.key === item.category.value)?.label }}
                    </div>
                    <div
                      class="tw-font-[900] tw-font-hobeaux tw-mt-2"
                      :class="{
                        'tw-break-keep': checkLang() == 'en',
                      }"
                    >
                      {{ item.title }}
                    </div>
                    <div class="tw-flex-1">
                      <div class="tw-mt-5 tw-text-xs tw-text-[#1A1A1A]">
                        {{ $t('common.dates') }}<span>{{ item.date }}</span>
                      </div>
                      <div class="tw-text-xs tw-text-[#1A1A1A]">
                        {{ $t('common.time') }}<span>{{ item.time }}</span>
                      </div>
                    </div>
                    <div
                      class="tw-flex tw-justify-end tw-text-[#8C12D4] tw-text-xs tw-mt-4 tw-cursor-pointer tw-group"
                      @click="handleOpenProgrammeModal(item)"
                    >
                      <div class="tw-flex tw-gap-x-2 tw-items-center">
                        <span class="group-hover:tw-underline">{{ $t('cny_family_fun_find_out_more') }}</span><inline-svg :src="$cdn('images/cny2024/family-fun/arrow_forward_ios.svg')" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="item.paid"
                    class="tw-absolute tw-top-3 -tw-left-2 tw-w-[111px] tw-h-[36px] tw-bg-[#D15133] tw-text-sm tw-text-white tw-font-[900] tw-flex tw-items-center tw-justify-center"
                    :style="{
                      boxShadow: '0px 4px 8px 0px #0000000F, 0px 0px 4px 0px #0000000A',
                    }"
                  >
                    <div>{{ $t('cny_family_fun_paid_event') }}</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <img
          :src="$cdn('images/cny2024/family-fun/programme_union.png')"
          class="tw-mx-auto"
          :class="{
            'familyFunTablet:tw-mb-[-375px] familyFunTablet:tw-translate-x-[120px] familyFunTablet:tw-translate-y-[-375px] familyFunTablet:tw-scale-50':
              filtered_programme_post.length % 2 == 1,
            'familyFunDesktop:tw-mb-0 familyFunDesktop:tw-translate-x-0 familyFunDesktop:tw-translate-y-0 familyFunDesktop:tw-scale-100':
              filtered_programme_post.length % 3 == 0,
            'familyFunDesktop:tw-mb-[-352px] familyFunDesktop:tw-translate-x-[150px] familyFunDesktop:tw-translate-y-[-352px] familyFunDesktop:tw-scale-100':
              filtered_programme_post.length % 3 == 1,
            'familyFunDesktop:tw-mb-[-375px] familyFunDesktop:tw-translate-x-[250px] familyFunDesktop:tw-translate-y-[-375px] familyFunDesktop:tw-scale-50':
              filtered_programme_post.length % 3 == 2,
          }"
        >
      </div>

      <family-fun-programme-modal
        :key="modalKey"
        v-model="programmeModal.show"
        :data="programmeModal.data"
      />
    </section>

    <section
      id="dragons-in-singapore"
      class="tw-bg-[#EDF5F9] tw-relative"
    >
      <img
        :src="$cdn('images/cny2024/family-fun/dragon_left.png')"
        class="tw-absolute tw-left-0 tw-hidden md:tw-block tw-h-[80px] tw-w-[80px] tw-top-[-20px] md:tw-h-[220px] md:tw-w-[220px] md:tw-top-[-100px] xl:tw-h-[260px] xl:tw-w-[260px] xl:tw-top-[-118px]"
      >
      <img
        :src="$cdn('images/cny2024/family-fun/dragon_right.png')"
        class="tw-absolute tw-right-0 tw-hidden md:tw-block tw-h-[80px] tw-w-[80px] tw-top-[-20px] md:tw-h-[220px] md:tw-w-[220px] md:tw-top-[-100px] xl:tw-h-[260px] xl:tw-w-[260px] xl:tw-top-[-118px]"
      >
      <div
        class="tw-font-[900] tw-text-[#007C4C] tw-font-hobeaux tw-max-w-[343px] md:tw-max-w-[400px] xl:tw-max-w-[600px] tw-w-full tw-mx-auto tw-text-center tw-pt-[24px] md:tw-pt-[36px] xl:tw-pt-[48px] tw-text-[28px] tw-leading-[36px] md:tw-text-[36px] md:tw-leading-[44px] xl:tw-text-[44px] xl:tw-leading-[56px]"
      >
        {{ $t('cny_family_fun_dragons_section_title') }}
      </div>
      <family-fun-swiper class="tw-mt-5 sgTablet:tw-mt-8 sgDesktop:tw-mt-[60px]" />
      <div
        class="tw-font-[900] tw-text-[#007C4C] tw-font-hobeaux tw-max-w-[600px] tw-w-full tw-mx-auto tw-text-center tw-px-4 sgTablet:tw-px-8 tw-pt-5 sgTablet:tw-pt-[60px] tw-pb-8 sgDesktop:tw-pb-[60px] tw-text-xl sgTablet:tw-text-2xl"
      >
        {{ $t('cny_family_fun_dragons_section_visit') }}
      </div>
    </section>
    <family-fun-programme-schedule-modal

      v-model="programmeScheduleModal"
      :src="programmeSchedule"
    />
    <cny-sticker-section :data="downloadables" />

    <section
      id="acknowledgment-section"
      class="tw-px-4 tw-py-5 sgTablet:tw-py-10 sgTablet:tw-px-10 sgDesktop:tw-px-20 tw-bg-[#EDF5F9]"
    >
      <div class="tw-grid tw-grid-cols-2 tw-gap-x-5 tw-gap-y-10 tw-max-w-[800px] tw-mx-auto tw-w-full">
        <div class="tw-col-span-2 sgDesktop:tw-col-span-2">
          <div class="tw-text-sg-sh2-mobile sgTablet:tw-text-sg-sh2-tablet sgDesktop:tw-text-sg-sh2 tw-text-center tw-text-[#484848]">
            {{ $t('cny_family_fun_official_community_partners') }}
          </div>

          <div class="tw-mt-5 tw-flex tw-justify-evenly tw-flex-wrap">
            <a
              href="https://www.ntuc.org.sg/uportal/"
              target="_blank"
            >
              <img
                :src="$cdn('images/cny2024/family-fun/acknowledgement/ntuc.png')"
                class="tw-w-25"
              >
            </a>
            <a
              href="https://www.safra.sg/"
              target="_blank"
            >
              <img
                :src="$cdn('images/cny2024/family-fun/acknowledgement/safra.png')"
                class="tw-w-25"
              >
            </a>
            <a
              href="https://www.hometeamns.sg/"
              target="_blank"
            >
              <img
                :src="$cdn('images/cny2024/family-fun/acknowledgement/hometeamns.png')"
                class="tw-w-25"
              >
            </a>
            <a
              href="https://merewards.sg/"
              target="_blank"
            >
              <img
                :src="$cdn('images/cny2024/family-fun/acknowledgement/merewards.png')"
                class="tw-w-25"
              >
            </a>
          </div>
        </div>
      </div>
    </section>

    <ap-footer />
  </div>
</template>
