<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { checkLang } from '../../support/helper';
import { h, ref} from 'vue';
const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');
const menuItems = [
  {
    label:'ma_home_menu_through_the_years',
    id:'ma_home_menu_through_the_years'
  },
  {
    label:'ma_home_menu_family_fun',
    id:'ma_home_menu_family_fun'
  },
  {
    label:'ma_home_menu_origin_stories',
    id:'ma_home_menu_origin_stories'
  },
];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};

onMounted(() => {

  nextTick(() => {

    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };


      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });

  console.log(props.wordpressData);
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});

const sliderData = ref<any[]>([]);

const sliderPattern = [
  {
    positionFromTop:'!tw-translate-y-[0.26px] sgTablet:!tw-translate-y-[0.26px] sgDesktop:!tw-translate-y-[0.26px]',
    rotation:'!tw-rotate-[-4deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[90.53px] sgTablet:!tw-translate-y-[63px] sgDesktop:!tw-translate-y-[39px]',
    rotation:'!tw-rotate-[4deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[102px] sgTablet:!tw-translate-y-[58px] sgDesktop:!tw-translate-y-[0px]',
    rotation:'!tw-rotate-[0deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[71.26px] sgTablet:!tw-translate-y-[53.7px] sgDesktop:!tw-translate-y-[39.92px]',
    rotation:'!tw-rotate-[-4deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[0px] sgTablet:!tw-translate-y-[0px] sgDesktop:!tw-translate-y-[0px]',
    rotation:'!tw-rotate-[4deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[91.26px] sgTablet:!tw-translate-y-[74.7px] sgDesktop:!tw-translate-y-[50.94px]',
    rotation:'!tw-rotate-[-4deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[141px] sgTablet:!tw-translate-y-[105.89px] sgDesktop:!tw-translate-y-[58.37px]',
    rotation:'!tw-rotate-[4deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[89px] sgTablet:!tw-translate-y-[63.5px] sgDesktop:!tw-translate-y-[51.5px]',
    rotation:'!tw-rotate-[0deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[0.26px] sgTablet:!tw-translate-y-[0.26px] sgDesktop:!tw-translate-y-[0.26px]',
    rotation:'!tw-rotate-[-4deg]',
  },
  {
    positionFromTop:'!tw-translate-y-[93.53px] sgTablet:!tw-translate-y-[53.47px] sgDesktop:!tw-translate-y-[28.71px]',
    rotation:'!tw-rotate-[4deg]',
  },

];
onBeforeMount(()=>{
  if(props.wordpressData?.image_sources?.length > 0) {
    imageSourcesData.value = props.wordpressData.image_sources;

  }


  if(props.wordpressData?.timeline?.length > 0){
    sliderData.value = props.wordpressData.timeline.map((data, index) => {
      return {...data, ...sliderPattern[(index + sliderPattern.length) % sliderPattern.length] };
    });
  }
});


const section2TextRef = ref();
const section2ImgRef = ref();
onMounted(() => {

  recalculateHeight();
  window.addEventListener('resize', recalculateHeight);
});
onBeforeUnmount(()=>{
  window.removeEventListener('resize', recalculateHeight);
});

function recalculateHeight(){
  requestAnimationFrame(()=>{

    if(section2TextRef.value){
      let height = section2TextRef.value.getBoundingClientRect().height;
      height = height > 300 ? height : 300;
      section2ImgRef.value?.style.setProperty('--text-height', `${height}px`);
    }
  });
}
</script>

<template>
  <div :class="{'tw-font-notosans': checkLang() != 'en', 'tw-font-akkurat': checkLang() == 'en'}">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :ma-navigation="true"
      :ma2-navigation="wordpressData?.canView?.includes('sidebar.ma2')"
    />
    <ma-secondary-navigation
      :menu-items-data="menuItems"
    />

    <div class="tw-relative">
      <img
        :src="$cdn('/images/ma2024/home/line-break_2.png')"
        :class="[
          'tw-w-screen tw-absolute -tw-bottom-[1px]',
          'tw-h-[12px] sgTablet:tw-h-[22px] sgTablet2:tw-h-[33px] sgDesktop:tw-h-[32px] sgDesktop2:tw-h-[44px]',
          'tw-z-[1]'
        ]"
      >
      <picture>
        <img
          :src="$cdn('images/ma2024/banner_2.png')"
          class="tw-w-full tw-h-[93vh] tw-object-cover"
        >
      </picture>
      <div class="tw-bg-black/20 tw-absolute tw-w-full tw-h-full tw-top-0" />
      <div class="tw-absolute tw-inset-0 tw-top-[80px] sgTablet:tw-top-[100px] sgDesktop:tw-top-[120px] tw-z-[1]">
        <div class="tw-text-white tw-text-center">
          <img
            class="tw-pb-[10px] tw-h-[40px] sgTablet:tw-h-[60px] tw-mx-auto"
            :src="$cdn('images/zy2024/header.png')"
            :style="{
              filter:'drop-shadow(-4px 4px 10px rgba(0, 0, 0, 0.30))'
            }"
          >
          <div
            :class="[
              'tw-text-sg-ma-dp3-mobile sgTablet:tw-text-sg-ma-dp3-tablet sgDesktop:tw-text-sg-ma-dp3-desktop',
              'tw-pb-1 tw-tracking-[0.01em]',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
            :style="{
              textShadow: '-4px 4px 10px rgba(0, 0, 0, 0.30)'
            }"
          >
            {{ $t('ma_home_title') }}
          </div>
        </div>
      </div>
    </div>
    <div
      :class="[
        'tw-bg-sg-ma24-orange-w60',
        'tw-py-5 sgTablet:tw-py-10',
        'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
      ]"
    >
      <div
        :class="[
          'tw-tracking-wider',

          'tw-max-w-[800px] tw-mx-auto tw-text-center',
          'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1'
        ]"
        v-html="wordpressData.description"
      />
    </div>

    <section
      id="ma_home_menu_through_the_years"
      :class="[
        'tw-bg-gradient-to-b tw-from-sg-ma24-orange-w60 tw-to-sg-ma24-pink-w60',
        'tw-shadow-t-bg-bug tw-shadow-sg-ma24-orange-w60',
      ]"
    >
      <div
        :class="[
          'tw-pt-5',
          'tw-pb-10 sgDesktop:tw-pb-[60px]',
          'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',

        ]"
      >
        <div
          :class="[
            { 'tw-font-akkuratbold' : checkLang() == 'en' },
            { 'tw-font-notosansbold' : checkLang() != 'en' },
            'tw-text-sg-ma-h2-mobile sgTablet:tw-text-sg-ma-h2-tablet sgDesktop:tw-text-sg-ma-h2-desktop',
            'tw-text-sg-ma24-purple-b40',
            'tw-max-w-[800px] tw-mx-auto tw-text-center',
          ]"
          v-html="$t('ma_home_section_1_title')"
        />
      </div>

      <ma-home-swiper
        v-model:image-source-open="imageSourceOpen"
        :data="sliderData"
      />
    </section>

    <section
      id="ma_home_menu_family_fun"
      :class="[
        'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
        'sgDesktop2:tw-pb-[72px]',
        'tw-bg-sg-ma24-pink-w60',
        'tw-shadow-t-bg-bug tw-shadow-sg-ma24-pink-w60',
      ]"
    >
      <div
        :class="[
          'tw-max-w-[800px] tw-mx-auto',
          'tw-flex tw-flex-col sgTablet2:tw-flex-row'
        ]"
      >
        <div
          ref="section2ImgRef"
          class="tw-flex tw-flex-shrink-0 tw-self-center tw-h-[300px] sgTablet:tw-h-[400px] sgTablet2:tw-h-auto sgTablet2:tw-w-[400px]"
          :style="{
            '--text-height':'0px'
          }"
        >
          <img
            :src="$cdn('images/ma2024/home/family-fun_2.png')"
            class="tw-w-full tw-object-contain sgTablet2:tw-max-h-[--text-height]"
          >
        </div>
        <div
          ref="section2TextRef"
          :class="[
            'tw-py-5 sgTablet2:tw-py-[60px]',
            'tw-pl-0 sgTablet2:tw-pl-10',
          ]"
        >
          <div
            :class="[
              'tw-text-sg-ma-h3-mobile sgTablet:tw-text-sg-ma-h3-tablet sgDesktop:tw-text-sg-ma-h3-desktop',
              'tw-text-sg-ma24-purple-b40',
              'tw-text-center sgTablet2:tw-text-left',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
            :style="{
              letterSpacing:'0.015em'
            }"
            v-html="$t('ma_home_section_2_title')"
          />
          <div
            :class="[
              'tw-mt-5',
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1',
              'tw-tracking-wider',
            ]"
          >
            {{ $t('ma_home_section_2_desc') }}
          </div>
          <div class="tw-flex tw-justify-center sgTablet2:tw-justify-start tw-mt-10">
            <a
              :href="$langRedirect('/festivals/mid-autumn/familyfun')"
              target="_blank"
              :class="[
                'tw-text-white tw-bg-sg-ma24-purple',
                'tw-px-6 tw-py-4',
                'tw-text-sg-ma-bt1',
                'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
                'hover:tw-bg-sg-ma24-purple-w20 active:tw-bg-sg-ma24-purple-b20',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              {{ $t('ma_home_discover_more') }}
            </a>
          </div>
        </div>
      </div>
    </section>

    <section

      id="ma_home_menu_origin_stories"
      :class="[
        'tw-px-4 sgTablet:tw-px-20 sgDesktop:tw-px-30',
        'tw-h-[600px] sgTablet:tw-h-[500px] sgDesktop:tw-h-[600px]',
        'tw-flex tw-items-center tw-justify-center',
        {'tw-hidden':wordpressData.canView?.includes('sidebar.ma2') === false}
      ]"
      :style="{
        '--ma-origin-stories-section-bg-image': `url(${$cdn('images/ma2024/home/origin-stories_2.png')})`,
        background: `
          linear-gradient(0deg, rgba(245, 196, 148, 0.80) 0%, rgba(245, 196, 148, 0.80) 100%),
          var(--ma-origin-stories-section-bg-image) lightgray 50% / cover no-repeat
        `
      }"
    >
      <div
        :class="[
          'tw-max-w-[800px] tw-mx-auto',
        ]"
      >
        <div
          :class="[
            'tw-pt-5 sgTablet2:tw-pt-[60px]',
            'tw-pb-10 sgTablet2:tw-pb-[60px]',
          ]"
        >
          <div
            :class="[
              'tw-text-sg-ma-h3-mobile sgTablet:tw-text-sg-ma-h3-tablet sgDesktop:tw-text-sg-ma-h3-desktop',
              'tw-text-sg-ma24-purple-b40',
              'tw-text-center sgTablet2:tw-text-left',
              { 'tw-font-akkuratbold' : checkLang() == 'en' },
              { 'tw-font-notosansbold' : checkLang() != 'en' },
            ]"
            :style="{
              letterSpacing:'0.015em'
            }"
          >
            {{ $t('ma_home_section_3_title') }}
          </div>
          <div
            :class="[
              'tw-mt-5',
              'tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet sgDesktop:tw-text-sg-body-1',
            ]"
          >
            {{ $t('ma_home_section_3_desc_1') }}
            <br>
            <br>
            {{ $t('ma_home_section_3_desc_2') }}
          </div>
          <div class="tw-flex tw-mt-10">
            <a
              :href="$langRedirect('/festivals/mid-autumn/origin-stories', checkLang())"
              target="_blank"
              :class="[
                'tw-text-white tw-bg-sg-ma24-purple',
                'tw-px-6 tw-py-4',
                'tw-text-sg-ma-bt1',
                'tw-rounded-[26px] !tw-leading-[20px] tw-h-[52px] tw-uppercase',
                'hover:tw-bg-sg-ma24-purple-w20 active:tw-bg-sg-ma24-purple-b20',
                { 'tw-font-akkuratbold' : checkLang() == 'en' },
                { 'tw-font-notosansbold' : checkLang() != 'en' },
              ]"
            >
              {{ $t('ma_home_discover_more') }}
            </a>
          </div>
        </div>
      </div>
    </section>


    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>
