<template>
  <div
    class="tw-flex tw-flex-row tw-justify-around tw-gap-5 tablet:tw-gap-10"
    :class="{ 'tw-flex-col tw-w-full tw-justify-center' : isColumn }"
  >
    <a
      v-for="(btn,index) in socialBtn "
      :key="index"
      :href="btn.url"
      target="_blank"
    >
      <div
        class="socialBtn tw-py-4 tablet:tw-py-3 tw-px-[25px] tablet:tw-px-7 tw-text-sg-remix-bt1 tw-rounded-[26px] tw-uppercase tw-whitespace-pre tablet:whitespace-normal
      tw-bg-[#8016B2] tw-text-white tw-flex
      hover:tw-bg-[#9945C1] hover:tw-text-white
      active:tw-bg-[#520E72] active:tw-text-white tw-flex tw-items-center tw-justify-center"
        :class="[
          { 'tw-font-akkuratbold' : checkLang() == 'en' },
          { 'tw-font-notosansbold' : checkLang() != 'en' },
        ]"
      >
        <div>
          {{ btn.label }}
        </div>
        <div class="tw-ml-2 tw-w-5 tw-h-5">
          <img :src="$cdn(btn.iconUrl)">
        </div>
      </div>
    </a>
  </div>
</template>
<script setup lang="ts">
// import { useI18n } from 'vue-i18n';
// const { t } = useI18n({ useScope: 'global' });
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { checkLang } from '../../../support/helper';
const socialBtn = [
  {
    id:1,
    label:'Facebook',
    iconUrl:'images/experience/ce/facebook_icon.png',
    url:'https://www.facebook.com/share/vdZ9SADx8npVLTZ6/?mibextid=LQQJ4d',
  },
  {
    id:2,
    label:'Instagram',
    iconUrl:'images/experience/ce/instagram_icon.png',
    url:'https://www.instagram.com/singaporechineseculturalcentre',
  }
];

const isColumn = ref(window.innerWidth < 375);
const updateWindowSize = () => {
  isColumn.value = window.innerWidth < 375;
};
onMounted(() => {
  window.addEventListener('resize', updateWindowSize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', updateWindowSize);
});

</script>
