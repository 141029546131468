<template>
  <div>
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
    />
    <!-- Header Title -->
    <div
      class="tw-flex tw-flex-col tw-px-4 tw-pt-8 tw-pb-5 tablet:tw-px-10 tablet:tw-pt-[60px] tablet:tw-pb-10 desktop:tw-px-20 desktop:tw-pb-10 desktop:tw-pt-[60px]"
    >
      <p class="tw-text-sg-sccc1 tw-text-sg-h1-mobile tablet:tw-text-sg-h1-tablet desktop:tw-text-sg-h1">
        {{ $t('arts_songs_and_poems_1') }}
      </p>
      <div class="tw-pb-[28px]" />
      <hr class="line1">
    </div>
    <div class="tw-px-4 tablet:tw-px-10 desktop:tw-px-20">
      <!-- Song Video -->
      <ap-videos
        video-url="https://www.youtube.com/watch?v=rjkj-gabB48"
        poster-url="/videos_thumbnails/Songs/The_Sparrow_with_a_Bamboo_Twig.jpg"
        :song-title="$t('the_sparrow_with_a_bamboo_twig')"
        :lyrics-by="$t('leong_wern_fook')"
        :music-by="$t('leong_wern_fook')"
        type="song"
      />

      <!-- Table Lyrics The Sparrow with a Bamboo Twig-->
      <div class="tw-flex tw-flex-col tablet:tw-flex-row desktop:tw-flex-row">
        <!-- Original Lyrics -->
        <div class="lyric desktop:tw-max-w-[302px] desktop:tw-min-w-[221px]">
          <h3>Original Lyrics</h3>
          <div
            v-for="(text, index) in theSparrowWithBambooTwigCN"
            :key="index"
          >
            <p>{{ text }}</p>
          </div>
        </div>
        <!-- English Translation Lyrics -->
        <div class="lyric tw-pt-10 tablet:tw-pt-0 tablet:tw-pl-5 desktop:tw-pt-0 desktop:tw-pl-5">
          <h3>English Translation Lyrics*</h3>
          <div
            v-for="(text, index) in theSparrowWithBambooTwigEN"
            :key="index"
          >
            <p>{{ text }}</p>
          </div>
          <p>*Translation adapted from <a
            class="tw-underline"
            href="https://www.youtube.com/watch?v=DAy5WgkhLdM"
            target="_blank"
          >here</a></p>
        </div>
      </div>
      <!-- Divider -->
      <hr class="line2">
      <div class="tw-pt-10" />
      <!-- Song Video -->
      <ap-videos
        video-url="https://www.youtube.com/watch?v=ObS2jsMWKow"
        poster-url="/videos_thumbnails/Songs/Friendship_Forever.jpg"
        :song-title="$t('friendship_forever')"
        :lyrics-by="$t('leong_wern_fook')"
        :music-by="$t('leong_wern_fook')"
        type="song"
      />
      <!-- Table Lyrics Friendship Forever-->
      <div class="tw-flex tw-flex-col tablet:tw-flex-row desktop:tw-flex-row">
        <!-- Original Lyrics -->
        <div class="lyric desktop:tw-max-w-[302px] desktop:tw-min-w-[221px]">
          <h3>Original Lyrics</h3>
          <div
            v-for="(text, index) in friendshipForeverCN"
            :key="index"
          >
            <p>{{ text }}</p>
          </div>
        </div>
        <!-- English Translation Lyrics -->
        <div class="lyric tw-pt-10 tablet:tw-pt-0 tablet:tw-pl-5 desktop:tw-pt-0 desktop:tw-pl-5">
          <h3>English Translation Lyrics*</h3>
          <div
            v-for="(text, index) in friendshipForeverEN"
            :key="index"
          >
            <p>{{ text }}</p>
          </div>
          <p>*Translation adapted from <a
            class="tw-underline"
            href="https://tben.wordpress.com/2015/08/09/lyrics-of-%E7%BB%86%E6%B0%B4%E9%95%BF%E6%B5%81/"
            target="_blank"
          >here</a></p>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <ap-footer />
  </div>
</template>

<script lang="ts">
export default {
  props: {
    wordpressData: {
      type: Object,
      default: null,
    },
  },
  data() {
    const theSparrowWithBambooTwigCN: string[] = [
      '我们这里是新加坡',
      '我阿爹系海山街住过',
      '1941年轰炸机经过',
      '一枚炸弹在街头降落',
      '我们这里是新加坡',
      '我阿妈系竹脚生我',
      '虽然那时候霸王车很多',
      '她却依然骑单车去工作',
      '我们这里是新加坡',
      '我的童年在女皇镇过',
      '一房半厅的组屋背后',
      '小小的林子里',
      '曾经是孩子们的窝',
      '我们这里是新加坡',
      '我的小学从前在小坡',
      '平时不读书考试之前',
      '跑到四马路的庙前',
      '说声哦阿弥陀佛',
      '我们这里是新加坡',
      '我们都曾一无所有过',
      '现在拥有的不算什么',
      '但是比别人珍惜得多',
      '我们这里是新加坡',
      '我表兄金山回来啰',
      '小小麻雀担竹枝',
      '都系担番屋企好得多',
      '麻雀仔担竹枝',
      '担上街头望阿姨',
      '阿姨梳只玲珑髻',
      '插朵红花伴髻围',
    ];

    const theSparrowWithBambooTwigEN: string[] = [
      'We live here in Singapore',
      'My father used to live along Hoi Shan Street (Upper Cross Street) (sung in Cantonese)',
      'In 1941 a Japanese bomber flew by here',
      'And a bomb was dropped on this street',
      'We live here in Singapore',
      'My mom gave birth to me at Tekka (sung in Cantonese)',
      'There were many unlicensed taxis back then',
      'And she still rode her bike to work',
      'We live here in Singapore',
      'My childhood was spent at Queenstown',
      'And behind the tiny HDB apartment block',
      'There’s a little forest',
      'Where I used to play with my childhood friends',
      'We live here in Singapore',
      'And my primary school used to be at Middle Road',
      'When I didn’t study hard enough,',
      'I would run to the temple at Waterloo street',
      'And pray at the Buddha’s feet',
      'We live here in Singapore',
      'And we used to have nothing',
      'Though we are much better off now',
      'But we treasure all that we have today',
      'We live here in Singapore',
      'And my cousin is returning from San Francisco (sung in Hokkien)',
      'Like a little sparrow carrying a twig (sung in Cantonese)',
      'Always returning to where home is (sung in Cantonese)',
      'Little sparrow carrying a twig (sung in Cantonese)',
      'Looking at the pretty lady at the head of the street (sung in Cantonese)',
      'Pretty lady has a beautiful knot in her hair (sung in Cantonese)',
      'And it is lined with flowers on both sides (sung in Cantonese)',
    ];

    const friendshipForeverCN: string[] = [
      '年少时候 谁没有梦',
      '无意之中 你将心愿透漏',
      '就在你生日的时候 我将小小口琴送',
      '最难忘记 你的笑容',
      '友情的细水慢慢流 ',
      '流进了你我的心中',
      '曾在球场边为你欢呼 ',
      '你跌伤我背负',
      '夜里流星飞渡 想象着他日的路途',
      '晚风听着我们壮志无数',
      '年少时候 谁没有愁',
      '满腔愤慨 唯有你能听得懂',
      '每当我失意的时候',
      '你将那首歌吹奏',
      '琴声悠悠 解我轻忧',
      '岁月的细水慢慢流 ',
      '流到了别离的时候',
      '轻拍你的肩',
      '听我说朋友不要太惆怅',
      '霓虹纵然再嚣张',
      '我们的步履有方向',
      '成败不论切莫将昔日遗忘',
      '多年以后 又再相逢',
      '我们都有了疲倦的笑容',
      '问一声我的朋友',
      '何时再为我吹奏',
      '是否依旧',
      '是否依旧',
      '人生的际遇千百种',
      '但有知心长相重',
      '人愿长久 水愿长流',
      '年少时候',
    ];

    const friendshipForeverEN: string[] = [
      'When we were young , we had our aspirations',
      'You once unknowingly divulged your aspirations to me',
      'So, on your birthday, I presented you with a little harmonica',
      'And how can I forget that smile on your face?',
      'As the stream of friendships trickle over time',
      'It flows into our hearts continuously',
      'As I cheered for you at the stadium',
      'When you fell, I carried you on my back',
      'As we watch the stars and thought of our future life',
      'The night breezes heard our countless aspirations',
      'When we were young, we had our worries',
      'Only you could understand my joys and sadness',
      'Whenever I feel disappointed',
      'You would play that tune on the harmonica',
      'That echoes through time, reliving my sadness',
      'As time trickles slowly like tiny streams,',
      'We reach the time to part our ways',
      'I patted your shoulder',
      'Saying “Friend, let’s not be sad”',
      'For the rainbow will continue to be there',
      'And we have our directions in life',
      'No matter success or failure, let’s not forget our time together',
      'After many years, we met once again',
      'We all had that same smile of weariness',
      'So, let me ask you my friend',
      'When will you play that tune again?',
      'Will it sound the same as before?',
      'Will itsound the same as before?',
      'There are thousands of ups and downs in life',
      'Only the best confidantes meet forever',
      'For their friendship is forever, like water flowing from its source',
      'When we were young',
    ];

    return {
      theSparrowWithBambooTwigCN,
      theSparrowWithBambooTwigEN,
      friendshipForeverCN,
      friendshipForeverEN,
    };
  },
};
</script>

<style scoped>
.lyric h3 {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 20px;
}

.lyric p {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 20px;
}

.lyric span {
  text-decoration: underline;
}
</style>
