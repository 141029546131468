<template>
  <div :class="checkLang() == 'zh-hans' ? 'tw-font-notosans' : 'tw-font-akkurat'">
    <ap-top-nav-bar :is-transparent="false" :is-hidden="false" :cny-navigation="true" />

    <secondary-navigation :menu-items-data="menuItems" :offset-y="70" />

    <!-- Header -->
    <section>
      <div class="tw-relative">
        <picture>
          <source media="(max-width: 375px)" :srcset="$cdn('images/cny2024/banner_375.png')" />
          <source media="(max-width: 680px)" :srcset="$cdn('images/cny2024/banner_680.png')" />
          <source media="(max-width: 800px)" :srcset="$cdn('images/cny2024/banner_800.png')" />
          <source media="(max-width: 1025px)" :srcset="$cdn('images/cny2024/banner_1025.png')" />
          <img :src="$cdn('images/cny2024/banner_1440.png')" class="tw-w-full tw-object-cover" />
        </picture>
        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center">
          <div class="tw-text-white tw-text-center">
            <div
              class="tw-text-sg-h1-mobile-cny sgTablet:tw-text-sg-h1-tablet-cny sgDesktop:tw-text-sg-h1-cny-title tw-pb-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_what_to_do') }}
            </div>
            <div
              class="tw-text-sg-sh1-mobile-cny sgTablet:tw-text-sg-sh1-tablet-cny sgDesktop:tw-text-sg-sh1-cny tw-mt-1"
              :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
            >
              {{ $t('cny_red_packet_subtitle') }}
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Content -->
    <div id="about" class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-px-4 tw-flex tw-flex-col tw-justify-center">
      <section class="tw-flex tw-flex-col tw-justify-center tw-content-center tw-items-center tw-pt-5 sgTablet:tw-pt-10 sgDesktop:tw-pt-[60px]">
        <div
          class="tablet:tw-max-w-[800px] tw-flex tw-flex-col tw-gap-y-4 tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-text-ap-black/70 tw-text-center"
        >
          <!-- <p>
            {{ $t('cny_red_packet_paragraph') }}
          </p>

          <p>
            {{ $t('cny_red_packet_paragraph1') }}
          </p> -->
          <div class="tw-flex tw-flex-col tw-break-words" v-html="wordpressData.description" />
        </div>

        <div class="tw-pt-10">
          <img class="tw-h-[160px] sgTablet:tw-h-[200px] tw-object-contain" :src="$cdn('images/cny2024/redPacket/cny_23_red_packet.png')" />
        </div>
      </section>

      <section
        class="tw-flex tw-flex-col tw-pt-10 tw-pb-5 sgTablet:tw-pt-[60px] sgTablet:tw-pb-10 sgDesktop:tw-pt-20 sgDesktop:tw-pb-[60px] tw-content-center tw-items-center"
      >
        <div
          class="tablet:tw-max-w-4xl tw-max-w-5xl tw-flex tw-flex-col tw-gap-y-4 tw-content-center tw-items-center tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-text-ap-black/70 tw-text-center"
        >
          <h3
            class="tw-text-sg-cny24-red tw-text-sg-h2-mobile-cny tablet:tw-text-sg-h2-tablet-cny desktop:tw-text-sg-h2-cny"
            :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
          >
            {{ $t('cny_red_packet_belongs_art_playgroud') }}
          </h3>

          <div class="tw-pt-10">
            <img class="tw-h-[300px] tw-object-contain" :src="$cdn('images/cny2024/redPacket/cny_longs.png')" />
          </div>

          <!-- <div class="tw-pt-10 tw-flex tw-flex-col tw-gap-y-4">
            <p>
              {{ $t('cny_red_packet_paragraph2') }}
            </p>

            <p v-if="checkLang() !== 'zh-hans'">
              Collect free red packets at SCCC Level 1 reception counter!
              <a
                href="https://festivefever.singaporeccc.org.sg/chinese-new-year/tu-gether/"
                class="tw-underline hover:tw-font-semibold"
                target="_blank"
              >here</a>!
            </p>
            <p v-else>
              <a
                href="https://festivefever.singaporeccc.org.sg/chinese-new-year/tu-gether/"
                class="tw-underline hover:tw-font-semibold"
                target="_blank"
              >点击这</a>
              前往中心一楼接待处领取免费红包封！
            </p>
          </div> -->

          <div class="tw-pt-10 tw-flex tw-flex-col tw-gap-y-4 tw-break-words" v-html="wordpressData.be_longs_description" />
        </div>
      </section>
    </div>

    <!-- Timeline Section -->
    <section class="tw-flex tw-flex-col tw-py-16 tw-content-center tw-items-center tw-bg-sg-cny24-light-blue-w60 tw-relative">
      <div class="tw-w-full tw-absolute tw-top-0 tw-left-0">
        <div class="tablet:tw-max-w-6xl tw-max-w-7xl tw-mx-auto tw-flex tw-flex-row tw-justify-between">
          <div>
            <img class="tw-h-[60px] tablet:tw-h-[100px]" :src="$cdn('images/cny2024/redPacket/cny_lantern_left.png')" />
          </div>
          <div>
            <img class="tw-h-[60px] tablet:tw-h-[100px]" :src="$cdn('images/cny2024/redPacket/cny_lantern_right.png')" />
          </div>
        </div>
      </div>

      <div class="tw-w-full tablet:tw-max-w-4xl tw-max-w-5xl tw-flex tw-flex-col">
        <div class="tw-pb-10">
          <h2
            class="tw-text-sg-cny24-red tw-text-sg-h2-mobile tablet:tw-text-sg-h2-tablet desktop:tw-text-sg-h2 tw-text-center tw-px-6 tablet:tw-px-12 desktop:tw-px-16"
          >
            {{ $t('cny_red_packet_check_past') }}
          </h2>
        </div>

        <div class="timeline">
          <!-- 2023 -->
          <div class="timeline-item">
            <div id="2023" class="tw-text-sg-cny24-red">
              <h2
                class="tw-absolute -tw-top-2 tablet:-tw-top-6 tw-text-sg-h2-mobile-cny tablet:tw-text-sg-h2-tablet-cny desktop:tw-text-sg-h2-cny"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2023
              </h2>
              <p
                class="tw-text-sg-sh2-mobile-cny tablet:tw-text-sg-sh2-tablet-cny desktop:tw-text-sg-sh2-cny tw-pt-8 tablet:tw-pt-10"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny_red_packet_2023_title') }}
              </p>
              <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-5">
                {{ $t('cny_red_packet_2023_desc') }}
              </p>
            </div>

            <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center tw-pt-10 tw-w-full">
              <img class="tw-h-[300px] tw-object-contain tw-w-full" :src="$cdn('images/cny2024/redPacket/cny_23_2_red_packet.png')" />
            </div>

            <!-- <div
              class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-[60px] tw-pb-[80px]"
            >
              <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
                <iframe
                  class="tw-mx-auto tw-w-[75vw] tw-
                  tablet:tw-w-[70vw] tablet:tw-aspect-video
                  desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                  src="https://www.youtube.com/embed/GhSv7mWanzc?si=gvhk8zKyZErAej84"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div> -->
          </div>

          <!-- 2022 -->
          <div class="timeline-item">
            <div id="2022" class="tw-text-sg-cny24-red">
              <h2
                class="tw-absolute -tw-top-2 tablet:-tw-top-6 tw-text-sg-h2-mobile-cny tablet:tw-text-sg-h2-tablet-cny desktop:tw-text-sg-h2-cny"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2022
              </h2>
              <p
                class="tw-text-sg-sh2-mobile-cny tablet:tw-text-sg-sh2-tablet-cny desktop:tw-text-sg-sh2-cny tw-pt-8 tablet:tw-pt-10"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny_red_packet_2022_title') }}
              </p>
              <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-5">
                {{ $t('cny_red_packet_2022_desc') }}
                <a href="https://www.instagram.com/tobyato/" class="tw-underline hover:tw-font-semibold" target="_blank">{{
                  $t('cny_red_packet_2022_desc2')
                }}</a>
                {{ $t('cny_red_packet_2022_desc3') }}
              </p>
              <div
                class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-5"
                v-html="$t('cny_red_packet_2022_desc4')"
              />
            </div>
            <!-- {{ $t('cny_red_packet_2022_desc4') }} -->

            <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center tw-pt-10">
              <img class="tw-h-[300px] tw-w-full tw-object-cover" :src="$cdn('images/cny2024/redPacket/cny_22_red_packet.png')" />
            </div>

            <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-[60px] tw-pb-[80px]">
              <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
                <iframe
                  class="tw-mx-auto tw-w-[75vw] tw- tablet:tw-w-[70vw] tablet:tw-aspect-video desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                  src="https://www.youtube.com/embed/5bTfkUhzLVg?si=aDw15QqJczNOgLkj"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>
          </div>

          <!-- 2021 -->
          <div class="timeline-item">
            <div id="2021" class="tw-text-sg-cny24-red">
              <h2
                class="tw-absolute -tw-top-2 tablet:-tw-top-6 tw-text-sg-h2-mobile-cny tablet:tw-text-sg-h2-tablet-cny desktop:tw-text-sg-h2-cny"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2021
              </h2>
              <p
                class="tw-text-sg-sh2-mobile-cny tablet:tw-text-sg-sh2-tablet-cny desktop:tw-text-sg-sh2-cny tw-pt-8 tablet:tw-pt-10"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny_red_packet_2021_title') }}
              </p>
              <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-5">
                {{ $t('cny_red_packet_2021_desc') }}
              </p>
              <p class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-5">
                {{ $t('cny_red_packet_2021_desc1') }}
              </p>
            </div>

            <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center tw-pt-10">
              <img class="tw-h-[300px] tw-w-full tw-object-cover" :src="$cdn('images/cny2024/redPacket/cny_21_red_packet.png')" />
            </div>

            <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-[60px] tw-pb-[80px]">
              <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
                <iframe
                  class="tw-mx-auto tw-w-[75vw] tw-aspect-video tablet:tw-w-[70vw] tablet:tw-aspect-video desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                  src="https://www.youtube.com/embed/wMx_kkh1nik?si=wlgmAbcg_w51ss7J"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>
          </div>

          <!-- 2020 -->
          <div class="timeline-item">
            <div id="2020" class="tw-text-sg-cny24-red">
              <h2
                class="tw-absolute -tw-top-2 tablet:-tw-top-6 tw-text-sg-h2-mobile-cny tablet:tw-text-sg-h2-tablet-cny desktop:tw-text-sg-h2-cny"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2020
              </h2>
              <p
                class="tw-text-sg-sh2-mobile-cny tablet:tw-text-sg-sh2-tablet-cny desktop:tw-text-sg-sh2-cny tw-pt-8 tablet:tw-pt-10"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny_red_packet_2020_title') }}
              </p>
              <div class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-5" v-html="$t('cny_red_packet_2020_desc')" />
            </div>
            <!-- {{ $t('cny_red_packet_2020_desc') }} -->

            <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center tw-pt-10">
              <img class="tw-h-[300px] tw-w-full tw-object-cover" :src="$cdn('images/cny2024/redPacket/cny_20_red_packet.png')" />
            </div>

            <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-[60px] tw-pb-[80px]">
              <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
                <iframe
                  class="tw-mx-auto tw-w-[75vw] tw- tablet:tw-w-[70vw] tablet:tw-aspect-video desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                  src="https://www.youtube.com/embed/yg2xfP8ncng?si=RsKJfLAnkl_xkQxO"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </div>
          </div>

          <!-- 2019 -->
          <div class="timeline-item">
            <div id="2019" class="tw-text-sg-cny24-red">
              <h2
                class="tw-absolute -tw-top-2 tablet:-tw-top-6 tw-text-sg-h2-mobile-cny tablet:tw-text-sg-h2-tablet-cny desktop:tw-text-sg-h2-cny"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                2019
              </h2>
              <p
                class="tw-text-sg-sh2-mobile-cny tablet:tw-text-sg-sh2-tablet-cny desktop:tw-text-sg-sh2-cny tw-pt-8 tablet:tw-pt-10"
                :class="checkLang() == 'zh-hans' ? 'tw-font-notosansbold' : 'tw-font-akkuratbold'"
              >
                {{ $t('cny_red_packet_2019_title') }}
              </p>
              <div class="tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-pt-5" v-html="$t('cny_red_packet_2019_desc')" />
            </div>

            <!-- {{ $t('cny_red_packet_2019_desc') }} -->

            <div class="tw-flex tw-flex-row tw-justify-start tablet:tw-justify-center tw-pt-10">
              <img class="tw-h-[300px] tw-w-full tw-object-cover" :src="$cdn('images/cny2024/redPacket/cny_19_red_packet.png')" />
            </div>

            <div class="tw-flex tw-flex-col tw-justify-start tw-items-start tw-pt-[60px] tw-pb-[80px]">
              <div class="tw-border-4 tw-rounded-lg tw-border-sg-cny24-white-b40">
                <!-- temp FIX -->

                <iframe
                  class="tw-flex tablet:tw-hidden"
                  src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fofficialcatch.sg%2Fvideos%2F2254866687877446%2F&width=260&show_text=false&height=208&appId"
                  width="260"
                  height="208"
                  style="border: none; overflow: hidden"
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
                <iframe
                  class="tw-hidden tablet:tw-flex desktop:tw-hidden"
                  src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fofficialcatch.sg%2Fvideos%2F2254866687877446%2F&width=360&show_text=false&height=288&appId"
                  width="360"
                  height="288"
                  style="border: none; overflow: hidden"
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
                <iframe
                  class="tw-hidden desktop:tw-flex"
                  src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fofficialcatch.sg%2Fvideos%2F2254866687877446%2F&width=534&show_text=false&height=427&appId"
                  width="534"
                  height="427"
                  style="border: none; overflow: hidden"
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
                <!-- <iframe
                  class="tw-mx-auto tw-w-[75vw] tw-
                  tablet:tw-w-[70vw] tablet:tw-aspect-video
                  desktop:tw-w-[534px] desktop:tw-h-[300px] tw-rounded-sm"
                  src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fofficialcatch.sg%2Fvideos%2F2254866687877446%2F&show_text=false&t=0"
                  style="border:none; overflow:hidden"
                  frameborder="0"
                  allowfullscreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
                <iframe
                  src="https://www.facebook.com/plugins/video.php?height=448&href=https%3A%2F%2Fwww.facebook.com%2Fofficialcatch.sg%2Fvideos%2F2254866687877446%2F&show_text=false&width=560&t=0"
                  width="560"
                  height="448"
                  style="border:none;overflow:hidden"
                  scrolling="no"
                  frameborder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  allowFullScreen="true"
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ap-footer />
  </div>
</template>

<script setup lang="ts">
import { checkLang } from '../../support/helper';

const props = defineProps<{
  wordpressData: any;
}>();

const menuItems = [
  {
    label: 'cny_about',
    id: 'about',
  },
  {
    label: '2023',
    id: '2023',
  },
  {
    label: '2022',
    id: '2022',
  },
  {
    label: '2021',
    id: '2021',
  },
  {
    label: '2020',
    id: '2020',
  },
  {
    label: '2019',
    id: '2019',
  },
];
</script>

<style style="scss">
.timeline {
  position: relative;
  padding: 20px;
  list-style: none;
  margin: 0;
}

.timeline-item {
  position: relative;
  padding: 0px 20px;
  border-left: 4px solid #c52600;

  @media screen and (min-width: 480px) {
    padding: 0px 40px;
  }
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -12px;
  width: 20px;
  height: 20px;
  background-color: #c52600;
  border-radius: 50%;
}
</style>
