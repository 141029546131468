<script lang="ts" setup>
import { nextTick, onBeforeMount, onBeforeUnmount, onMounted } from 'vue';
import { cdn } from '../../support/cdn';
import {useI18n} from 'vue-i18n';
import { h, ref} from 'vue';
import { checkLang } from '../../support/helper';
const props = defineProps<{
  wordpressData:any
}>();
const {t} = useI18n();

const imageSourceOpen = ref<boolean>(false);
const imageSourcesData = ref('');
const menuItems = [
  {
    label:'dw_home_menu_through_the_years',
    id:'through-the-years-section'
  },
  {
    label:'dw_home_menu_origin_stories',
    id:'origin-stories-section'
  }
];

const handleOpenImageSourceModal = () => {
  imageSourceOpen.value = !imageSourceOpen.value;
};


const timeline_post = ref([]);

onBeforeMount(()=>{
  if(props.wordpressData?.timeline){
    timeline_post.value = props.wordpressData.timeline;
  }

  if(props.wordpressData?.image_sources) {
    imageSourcesData.value = props.wordpressData.image_sources;
  }
});
onMounted(() => {


  nextTick(() => {

    // Add <sup> click listener
    const supElements = document.querySelectorAll('#sup-handler-container sup');
    if(supElements?.length > 0){
      const handleSupClick = () => {
        imageSourceOpen.value = !imageSourceOpen.value;
      };


      supElements.forEach((supElement) => {
        supElement.addEventListener('click', handleSupClick);
      });
    }
  });
});

onBeforeUnmount(() => {
// Remove <sup> click listener
  const supElements = document.querySelectorAll('#sup-handler-container sup');

  if(supElements?.length > 0){
    const handleSupClick = () => {
      imageSourceOpen.value = !imageSourceOpen.value;
    };

    supElements.forEach((supElement) => {
      supElement.removeEventListener('click', handleSupClick);
    });
  }

});
</script>

<template>
  <div :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'">
    <ap-top-nav-bar
      :is-transparent="false"
      :is-hidden="false"
      :dw-navigation="true"
    />
    <dw-secondary-navigation
      :menu-items-data="menuItems"
    />
    <div class="tw-relative">
      <picture>
        <img
          :src="$cdn('images/dw2024/banner.scaled.jpg')"
          class="tw-w-full tw-h-[93vh] tw-object-cover"
        >
      </picture>
      <div class="tw-absolute tw-inset-0 tw-top-[120px] sgTablet:tw-top-[160px] sgDesktop:tw-top-[200px]">
        <div class="tw-text-white tw-text-center">
          <img
            class="tw-pb-[10px] tw-h-[40px] sgTablet:tw-h-[60px] tw-mx-auto"
            :src="$cdn('images/dw2024/header.png')"
          >
          <div
            class="tw-text-sg-h1-mobile-dw-title sgTablet:tw-text-sg-h1-tablet-dw-title sgDesktop:tw-text-sg-h1-dw-title tw-pb-1"
            :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
          >
            {{ $t('dw_home_title') }}
          </div>
        </div>
      </div>
    </div>

    <section class="tw-px-4 tw-py-5 sgTablet:tw-p-10">
      <div class="tw-max-w-[800px] tw-w-full tw-mx-auto tw-text-center">
        <div
          id="sup-handler-container"
          class="tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider
          [&_sup]:tw-cursor-pointer [&_sup]:tw-text-[#0366D8]
        "
          

          v-html="wordpressData.description"
        />
      </div>
    </section>

    <section
      id="through-the-years-section"
      class="tw-bg-sg-dw24-blue-w60"
    >
      <div class="tw-px-4 tw-pt-5 tw-pb-8 sgTablet:tw-p-10 sgDesktop:tw-px-20 tw-text-sg-dw24-blue-b60">
        <div
          class="tw-text-sg-sh1-mobile sgTablet:tw-text-sg-sh1-tablet sgDesktop:tw-text-sg-sh1"
          :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"

          v-html="$t('dw_home_section_2_title')"
        />
        <p
          class="tw-mt-3
        tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider
        "
        >
          {{ $t('dw_home_section_2_desc') }}
        </p>
      </div>
      <dw-home-swiper
        v-model:image-source-open="imageSourceOpen"
        :data="timeline_post"
      />
    </section>

    <section
      id="origin-stories-section"
    >
      <div>
        <div class="tw-flex tw-flex-col sgTablet2:tw-flex-row">
          <div class="tw-flex">
            <img
              :src="cdn('images/dw2024/home/boat.png')"
              class="tw-w-full tw-h-[332px] sgTablet:tw-h-[440px] sgTablet2:tw-h-auto sgTablet2:tw-w-[min(800px,60vw)] tw-max-w-none tw-place-self-center tw-object-contain"
            >
          </div>
          <div class="tw-px-4 sgTablet:tw-px-10 sgTablet2:tw-pl-5 sgTablet2:tw-pr-10 sgDesktop:tw-pl-10 sgDesktop:tw-pr-20 tw-flex tw-py-5 sgTablet:tw-py-10">
            <div class="tw-place-self-center">
              <div
                class="tw-text-sg-h3-mobile sgTablet:tw-text-sg-h3-tablet sgDesktop:tw-text-sg-h3"
                :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'"
              >
                {{ $t('dw_home_section_3_title') }}
              </div>

              <p
                class="tw-mt-5 sgDesktop:tw-mt-10
            tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider
            "
              >
                {{ $t('dw_home_section_3_desc_1') }}
              </p>
              <p
                class="tw-mt-4
            tw-text-sg-body-1-mobile sgTablet:tw-text-sg-body-1-tablet desktop:tw-text-sg-body-1 tw-tracking-wider
            "
              >
                {{ $t('dw_home_section_3_desc_2') }}
              </p>
              <div class="tw-flex tw-mt-10 tw-mr-5">
                <a
                  :href="$langRedirect('/festivals/duanwu/origin-stories')"
                  target="_blank"
                  class="tw-rounded-[26px] tw-text-white tw-bg-sg-dw24-green-b40 tw-px-6 tw-py-4 tw-text-sg-button-mobile-1 tablet:tw-text-sg-button-1 !tw-leading-[20px] tw-h-[52px] tw-uppercase"
                  :class="checkLang() == 'zh-hans'? 'tw-font-notosansbold' :'tw-font-akkuratbold'" 
                >
                  {{ $t('dw_home_discover_more') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <ap-image-sources
      v-model="imageSourceOpen"
      :data="imageSourcesData"
    />

    <ap-footer />
  </div>
</template>
